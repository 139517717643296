import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '@services/data.service';
import { CommonService } from '@services/common.service';
import { MatDatepickerInputEvent } from '@angular/material';

@Component({
  selector: 'app-transaction-layout',
  templateUrl: './transaction-layout.component.html',
  styleUrls: ['./transaction-layout.component.scss']
})
export class TransactionLayoutComponent implements OnInit {
  public end: Date = new Date();
  element;
  transactions: any[] = [];
  endDate;
  startDate;
  date: Date;
  id: any;
  pagination: any;
  rangeEnd: any;
  next: any;
  previous: any;
  currentPage: any;
  totalPages: any;
  rangeStart: any;
  role: any;
  somedate: string = this.startDate;
  pagesSubscription: any;
  isMobile = false;
  @ViewChild('amount', { static: false }) amount;
  @ViewChild('transaction', { static: false }) transaction;
  // selected = '10';

  constructor(
    private _dataService: DataService,
    private commonService: CommonService
  ) { }



  ngOnInit() {
    this.getDates();
    this.role = localStorage.getItem('role');
    this.getId();
    this.loadTransactions();
    this.getIsMobile();
  }

  // user id used to find transactions, not agent 
  getId() {
    if (this.role === 'agent') {
      this.id = localStorage.getItem('id');
    } else {
      this.id = localStorage.getItem('id');
    }
  }

  // setting start dates 
  getDates() {
    this.endDate = new Date();
    this.startDate = new Date();
    const start = new Date(this.startDate.setDate(this.startDate.getDate() - 30));
  }

  // mobile view 
  getIsMobile() {
    this.isMobile = this.commonService.getIsMobile();
  }



  // fixing ISO string changing date due to timezone difference
  handleTimeZone(time) {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000;
    time = (new Date(time - tzoffset)).toISOString().slice(0, -1).split('T')[0];
    return time;
  }

  // seting new start date for search
  changeStartDate(event: MatDatepickerInputEvent<Date>) {
    const rangeStart = event.value;
    this.rangeStart = this.handleTimeZone(rangeStart);
  }

  // seting new end date for search
  changeEndDate(event: MatDatepickerInputEvent<Date>) {
    const rangeEnd = event.value;
    this.rangeEnd = this.handleTimeZone(rangeEnd);
  }


  // load transactions on page load
  loadTransactions( limit?,amount?) {
    this._dataService.getAllTransactionsByUser(
      this.startDate.toISOString().split('T')[0],
      this.endDate.toISOString().split('T')[0],
      this.id,
      this.role,
      limit,
      amount ? `&search=amount:${amount}` : '',
    ).subscribe(res => {
      this.somedate = this.startDate;
      this.transactions = res.data;
      this.pagination = res.meta.pagination;
      this.next = this.pagination.links.next;
      this.currentPage = res.meta.pagination.current_page;
      this.totalPages = res.meta.pagination.total_pages;
    });
  }

  // load transactions by setting date range
  loadByRange(amount?) {
    this._dataService.getAllTransactionsByUser(
      this.rangeStart ? this.rangeStart : this.startDate.toISOString().split('T')[0],
      this.rangeEnd ? this.rangeEnd : this.endDate.toISOString().split('T')[0],
      this.id,
      this.role,
      amount ? `&search=amount:${amount}` : '',
    ).subscribe(res => {
      this.transactions = res.data;
      this.getPagination(this.next, this.previous, this.totalPages, res);
      this.next = res.meta.pagination.links.next;
      this.previous = res.meta.pagination.links.previous;
      this.currentPage = res.meta.pagination.current_page;
      this.totalPages = res.meta.pagination.total_pages;


      // this.pagination = res.meta.pagination;
    });
  }


  getDataWithPageLimit(limit) {
      this.loadTransactions(limit);
  }

  // load transactions by paginated pages from BE 
  loadByUrl(direction) {
    if (direction === 'next') {
      this._dataService.getNextPage(this.next).subscribe(res => {
        this.transactions = res.data;
        this.next = res.meta.pagination.links.next;
        this.previous = res.meta.pagination.links.previous;
        this.currentPage = res.meta.pagination.current_page;
        this.totalPages = res.meta.pagination.total_pages;
        // this.getPagination(this.next, this.previous, this.totalPages, res);
      });
    } else if (direction === 'previous') {
      this._dataService.getNextPage(this.previous).subscribe(res => {
        this.transactions = res.data;
        this.next = res.meta.pagination.links.next;
        this.previous = res.meta.pagination.links.previous;
        this.currentPage = res.meta.pagination.current_page;
        this.totalPages = res.meta.pagination.total_pages;
        // this.getPagination(this.next, this.previous, this.totalPages, res);
      });
    }
  }


  // loadPreviousMonth() {
  //   const start = new Date(this.startDate.setDate(this.startDate.getDate() - 31));
  //   this.loadTransactions();
  // }


  getPagination(next, previous, total, response) {
    next = response.meta.pagination.links.next;
    previous = response.meta.pagination.links.previous;
    total = response.meta.pagination.total_pages;
  }

  getPages($event) {
    this.pagesSubscription = this._dataService.getData($event).subscribe(res => {
      this.transactions = res.data;
      this.pagination = res.meta.pagination;
    });
  }
  // reset controls for search and get new data
  reset() {
    this.amount.nativeElement.value = '';
    this.getDates();
    this.loadTransactions();
  }
  navigateTo(element: string) {
    this[element].nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
