import { AuthService } from '@pages/auth/auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ApprovedAgentGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService) {
  }

  canActivate(): any {
    if (this.auth.approvalStatus === 'approved') {
      return true;
    } else {
      this.router.navigate(['agent/agent-profile']);
      return false;
    }
  }
}
