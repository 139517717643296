import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-new-affiliate',
  templateUrl: './new-affiliate.component.html',
  styleUrls: ['./new-affiliate.component.scss']
})
export class NewAffiliateComponent implements OnInit {
  infoMessage: string;
  infoMessage2: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router) { }


  ngOnInit() {
    const confirmation_token = this.route.snapshot.queryParamMap.get('id');

    this.infoMessage = 'Welcome to The Play Pages, please register';
    setTimeout(() => {
      this.router.navigate(['/auth/signup'], { queryParams: { affiliate_token: confirmation_token } });
      this.infoMessage2 = 'Your registration was successfull, you can login now.';
    }, 1000);
  }
}
