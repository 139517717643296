import { Overlay } from '@angular/cdk/overlay';
import { ConfirmCallComponent } from './../../../layout/confirm-call/confirm-call.component';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { AuthService } from '@pages/auth/auth.service';
import { TwilioService } from '@services/twilio.service';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit, OnDestroy {

  @Input() agent;
  @Input() favoriteAgents;
  @Input() agentID = [];
  isAuth = false;
  activeBid: any;
  client = {
    twilioRoomName: '',
    twilioToken: '',
    roomSid: ''
  };
  infoText: string;
  buttonDisabled = false;
  @Output() timeRemaining: EventEmitter<any> = new EventEmitter();
  busyAgent: string;
  role: string;
  status: any;
  cam: any;
  phone: any;
  product: any;
  subscription: Subscription;


  constructor(
    private authService: AuthService,
    private twilio: TwilioService,
    private router: Router,
    public dialog: MatDialog,
    private overlay: Overlay
  ) { }

  ngOnInit() {
    this.role = localStorage.getItem('role');
    if (this.role) { this.isAuth = true; }
    const active = this.agent.bids.data;
    this.activeBid = active.find(bid => bid.status === 'active');
    this.listingTypesAvailability();
  }
  // set id of a card to SS in order to use it in home
  // component to scroll into view of last card visited
  onClickScroll() {
    sessionStorage.setItem('scrollID', JSON.stringify(this.agent.id));
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // check listing availability on load
  listingTypesAvailability() {
    const listingTypes = this.agent.listingTypes.data;
    this.cam = listingTypes.find(type => type.name === 'cam');
    this.phone = listingTypes.find(type => type.name === 'phone');
    this.product = listingTypes.find(type => type.name === 'product');
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find(key =>
      object[key] === value);
  }

  getTime() {
    return Date();
  }

  availabilityStatus() {
    let status = this.agent.availability_status;
    switch (status) {
      case 'cam':
        status = 'Online';
        break;
      case 'phone':
        status = 'Online';
        break;
      case 'product':
        status = 'Online';
        break;
      case 'busy':
        status = 'Busy';
        break;
      case 'offline':
        status = 'Offline';
        break;
      case 'online':
        status = 'Online';
        break;
    }
    return status;
  }

  getIsMobile(): boolean {
    const w = window.innerWidth;
    const breakpoint = 900;
    if (w > breakpoint) {
      return true;
    } else {
      return false;
    }
  }


  favouriteAgent(id, event: Event) {
    event.stopPropagation();
    event.preventDefault();
    const isFavorite = !this.favoriteAgents[id];
    if (isFavorite) {
      this.authService.addFavouriteAgent(id).subscribe(res => {
        if (res) {
          this.favoriteAgents[id] = true;
        }
      }, error => {
        this.authService.showSnackbar(error, null, 3000);
      });
    } else {
      this.authService.removeFavoriteAgent(id).subscribe(res => {
        delete this.favoriteAgents[id];
      }, error => {
        this.authService.showSnackbar(error, null, 3000);
      });
    }
  }


  callAgent(id, user_name, call_price, event: Event) {
    localStorage.setItem('caller', 'true');
    event.preventDefault();
    event.stopPropagation();
    const authUser = localStorage.getItem('id_token');
    if (!authUser) {
      localStorage.setItem('agToCall', id);
      this.router.navigate(['auth/login'], { queryParams: { wantsToCallCam: true } });
      this.twilio.showSnackbar('Please login to make call', null, 3000);
    } else {
      this.twilio.getTimeForCall(id).subscribe(res => {
        const max_call_duration = res.max_call_duration;
        this.twilio.sendMessage(max_call_duration);
        const time = new Date(max_call_duration * 1000).toISOString().substr(11, 8);
        if (max_call_duration > 0) {
          const data = {
            time,
            user_name,
            call_price,
            video: true
          };
          const dialogRef = this.dialog.open(ConfirmCallComponent, {
            data,
            panelClass: ['no-padding', 'no-scrolls'],
            scrollStrategy: this.overlay.scrollStrategies.noop()
          });
          dialogRef.afterClosed().subscribe((stream_call) => {
            if (typeof stream_call === "boolean") {
              // localStorage.setItem('type', type);
              this.twilio.callAgent(id, stream_call).subscribe(response => {
                this.buttonDisabled = true;
                this.client.twilioRoomName = response.roomName;
                this.client.twilioToken = response.clientToken;
                this.client.roomSid = response.roomSid;
                this.twilio.emit(this.client);
                this.timeRemaining.emit(max_call_duration);
                this.infoText = 'Got token and room name ' + this.client.twilioRoomName;
                this.twilio.clientCallTwilio(this.client, stream_call);
              }, error => {
                this.infoText = 'Error ' + error.message;
                this.twilio.showSnackbar(error.error.message, null, 3000);
                console.log(error);
              });
            }

          });
          // if (confirm('Do you want to call agent '
          //   + user_name
          //   + '? Video Call price is '
          //   + call_price
          //   + '$/min'
          //   + ' Available time is '
          //   + time
          // )) {

          // }
        } else {
          this.twilio.showSnackbar('You don\'t have inough money on your account', null, 3000);
        }
      }, error => {
        this.twilio.showSnackbar(error, null, 3000);
        console.log(error);
      });
    }
  }

  callAgentAudio(id, user_name, call_price, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.twilio.getTimeForAudioCall(id).subscribe(res => {
      const maxCallDuration = res.max_call_duration;
      const time = new Date(maxCallDuration * 1000).toISOString().substr(11, 8);
      if (maxCallDuration > 0) {
        const data = {
          time,
          user_name,
          call_price,
          audio: true
        };
        const dialogRef = this.dialog.open(ConfirmCallComponent, {
          data,
          panelClass: ['no-padding', 'no-scrolls'],
          scrollStrategy: this.overlay.scrollStrategies.noop()
        });
        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.twilio.callAgentAudio(id).subscribe(() => {
              localStorage.removeItem('agToCall');
              this.twilio.showSnackbar('Call initiated! You will receive a call to your phone shortly!', null, 3000);
            }, error => {
              this.infoText = 'Error ' + error.message;
              this.twilio.showSnackbar(error.error.message, null, 3000);
              console.log(error);
            });
          }
        });
      }
    }, error => {
      if (error === 'Insufficient balance to complete the operation successfully.') {
        this.twilio.showSnackbar(error, null, 3000);
      } else {
        localStorage.setItem('agToCall', id);
        this.router.navigate(['auth/login'], { queryParams: { wantsToCall: true } });
        this.twilio.showSnackbar('Please login or signup to make call', null, 3000);
      }
    });

  }
}

