
import { FreePicturesService } from './../../../shared/services/free-pictures.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-free-pics',
  templateUrl: './free-pics.component.html',
  styleUrls: ['./free-pics.component.scss']
})
export class FreePicsComponent implements OnInit {
  pictures = [];
  loading = false;
  pagination: any;
  constructor(
    private freePS: FreePicturesService
  ) { }

  ngOnInit() {
    this.getAllPictures();
  }

  getAllPictures($event?, next?) {
    this.loading = true;
    if (next) {
      this.freePS.setPageNumber($event);
      this.freePS.setSearchType(next);
      window.scroll(0, 0);
    } else if (next !== undefined) {
      this.freePS.setPageLimit($event);
      this.freePS.setSearchType(next);
    }
    this.freePS.getAllFreePictures($event, next).subscribe(res => {
      this.pictures = res.data;
      this.getPagination(res);
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  getPagination(res) {
    this.pagination = {
      current_page: res.current_page,
      total_pages: res.last_page,
      count: res.per_page,
      total: res.total,
      links: {
        previous: res.current_page - 1,
        next: res.current_page + 1
      }
    };
  }

}
