import { CommonService } from '@services/common.service';
import { Subscription } from 'rxjs';
import { UserCardComponent } from './../../pages/home/user-card/user-card.component';
import { TwilioService } from '@services/twilio.service';
import { GetData } from './../../shared/services/get-data.service';
import { AuthService } from '@pages/auth/auth.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MatDialog } from '@angular/material';
import { SendSingleMessageComponent } from '@layout/send-single-message/send-single-message.component';

@Component({
  selector: 'app-agent-header',
  templateUrl: './agent-header.component.html',
  styleUrls: ['./agent-header.component.scss'],
  providers: [UserCardComponent]
})
export class AgentHeaderComponent implements OnInit, OnDestroy {
  agent;
  agents: any;
  dialogRef: MatDialogRef<SendSingleMessageComponent>;
  product;
  authUser: string;
  subscribed = false;
  ownProfile: boolean;
  subscription: Subscription;
  subscription2: Subscription;
  cam: any;
  phone: any;
  isAuth: boolean;
  favorite: any;
  isFavorite = false;
  role: any;


  constructor(
    private getData: GetData,
    private route: ActivatedRoute,
    private twilio: TwilioService,
    private userCard: UserCardComponent,
    public dialog: MatDialog,
    private auth: AuthService,
    private router: Router,
    private commonService: CommonService
  ) {
  }

  ngOnInit() {
    this.role = localStorage.getItem('role');
    if (this.role) { this.isAuth = true; }
    this.getAgent();
    this.getUserInfo();
    // listen for availability status change for each agent card
    this.subscription = this.twilio.currentMessage.subscribe(message => {
      if (message) {
        const busyAgent = message.agentId;
        const status = message.status;
        if (this.agent && busyAgent === this.agent.id) {
          this.agent.availability_status = status;
         // this.iconAvailabilityCheck(this.agent);
        }
      }
    });
    // this.subscription2 = this.commonService.getMessage().subscribe(res => {
    //   const agent = this.agent;
    //   if (agent) {
    //     this.iconAvailabilityCheck(agent, res.text);
    //   }
    // });
  }

  // getFavoriteAgent(favorites, currentAgentId) {
  //   favorites.find(agent => {
  //     if (agent.id === currentAgentId) {
  //       this.favorite = agent;
  //       this.isFavorite = true;
  //     }
  //   });
  // }


  // favouriteAgent(id, event) {
  //   event.stopPropagation();
  //   event.preventDefault();
  //   if (!this.isFavorite) {
  //     this.auth.addFavouriteAgent(id).subscribe(res => {
  //       if (res) {
  //         this.isFavorite = true;
  //       }
  //     }, error => {
  //       this.auth.showSnackbar(error, null, 3000);
  //     });
  //   } else {
  //     this.auth.removeFavoriteAgent(id).subscribe(res => {
  //       this.isFavorite = false;
  //     }, error => {
  //       this.auth.showSnackbar(error, null, 3000);
  //     });
  //   }
  // }
  // iconAvailabilityCheck(agent, event?) {
  //   let cam;
  //   event ? cam = event.listingTypes.filter(t => t === 'cam') : cam = agent.listingTypes.data.filter(t => t.name === 'cam');
  //   if (cam.length > 0 && (agent.availability_status === 'cam' || agent.availability_status === 'online')) {
  //     agent.cam = true;
  //   } else {
  //     agent.cam = false;
  //   }
  //   let phone;
  //   event ? phone = event.listingTypes.filter(t => t === 'phone') : phone = agent.listingTypes.data.filter(t => t.name === 'phone');
  //   if (phone.length > 0 && (agent.availability_status === 'phone' || agent.availability_status === 'online')) {
  //     agent.phone = true;
  //   } else {
  //     agent.phone = false;
  //   }
  //   if (agent.availability_status === 'busy' || agent.availability_status === 'offline') {
  //     agent.cam = false;
  //     agent.phone = false;
  //   }
  // }

  ngOnDestroy() {
    // if (this.subscription) {
    //   this.subscription.unsubscribe();
    // }
    // if (this.subscription2) {
    //   this.subscription2.unsubscribe();
    // }
  }

  // logged in user info

  getUserInfo() {
    this.authUser = localStorage.getItem('id_token');
    const currentAgent = this.route.snapshot.paramMap.get('agentId');
    if (this.authUser) {
      this.auth.getLoggedInUser().subscribe(res => {
        const userSmsSubscribtions = res.data.smsSubscribers.data;
        const match = userSmsSubscribtions.find(item => item.id === currentAgent);
        if (match) {
          this.subscribed = true;
        }
        // if (this.role === 'agent') {
        //   this.getFavoriteAgent(res.data.agent.data.favorites.data, currentAgent);
        // } else if (this.role === 'client') {
        //   this.getFavoriteAgent(res.data.favorites.data, currentAgent);
        // }
        this.findIfOwnProfile(res, currentAgent);
      });
    }
  }


  // get agent by id thru query param

  getAgent() {
    this.route.paramMap.subscribe(params => {
      this.getData.getAgent(params.get('agentId')).subscribe(res => {
        this.agent = res.data;
        // this.getProductListing(this.agent);
        this.listingTypesAvailability();
        // this.videoIconAvailable();
        // this.audioIconAvailable();
      });
    });
  }

  findIfOwnProfile(res, user) {
    if (res.data.agent) {
      if (res.data.agent.data.id === user) {
        this.ownProfile = true;
      } else {
        this.ownProfile = false;
      }
    }
  }

  // subscribe to receive sms notification when agent is online
  // subscribeSMS(id) {
  //   const data = { agent_id: id };
  //   this.auth.userSmsSubscription(data).subscribe(res => {
  //     this.auth.showSnackbar(res.message, null, 3000);
  //     this.subscribed = !this.subscribed;
  //   }, err => this.auth.showSnackbar(err, null, 3000));
  // }

  // find if agent has products in listings
  // getProductListing(agent) {
  //   const data = agent.listingTypes.data;
  //   data.map(item => {
  //     this.product = item.name === 'product';
  //   });
  // }

  // callAgent(id, user_name, call_price, event: Event) {
  //   this.userCard.callAgent(id, user_name, call_price, event);
  // }

  // callAgentAudio(id, user_name, call_price, event: Event) {
  //   this.userCard.callAgentAudio(id, user_name, call_price, event);
  // }

  // handle status cases to show to users
  availabilityStatus() {
    let status = this.agent.availability_status;
    switch (status) {
      case 'cam':
        status = 'Online';
        break;
      case 'phone':
        status = 'Online';
        break;
      case 'busy':
        status = 'Busy';
        break;
      case 'offline':
        status = 'Offline';
        break;
      case 'online':
        status = 'Online';
        break;
    }
    return status;
  }
  listingTypesAvailability() {
    const listingTypes = this.agent.listingTypes.data;
    this.cam = listingTypes.find(type => type.name === 'cam');
    this.phone = listingTypes.find(type => type.name === 'phone');
    this.product = listingTypes.find(type => type.name === 'product');
  }
  // // icons for calls availability check
  // videoIconAvailable() {
  //   const cam = this.agent.listingTypes.data.filter(t => t.name === 'cam');
  //   if (cam.length > 0 && (this.agent.availability_status === 'cam' || this.agent.availability_status === 'online')) {
  //     this.agent.cam = true;
  //   } else {
  //     this.agent.cam = false;
  //   }
  // }
  // audioIconAvailable() {
  //   const phone = this.agent.listingTypes.data.filter(t => t.name === 'phone');
  //   if (phone.length > 0 && (this.agent.availability_status === 'phone' || this.agent.availability_status === 'online')) {
  //     this.agent.phone = true;
  //   } else {
  //     this.agent.phone = false;
  //   }
  // }

  // send email to offline users
  openDialog(user): void {
    this.auth.getAuthenticatedUser().subscribe(res => {
      this.dialogRef = this.dialog.open(
        SendSingleMessageComponent,
        {
          data: { user },
          panelClass: ['no-padding', 'no-scrolls'],
        }
      );
      localStorage.removeItem('agToCall');
    }, () => {
      localStorage.setItem('agToCall', user.id)
      this.router.navigate(['auth/login'], { queryParams: { wantsToSendMessage: true } });
      this.twilio.showSnackbar('Please login or signup to send message', null, 3000);
    });
  }
}
