import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class FreePicturesService {
  url = environment.baseUrl;
  pageNumber: number;
  pageLimit: number;
  searchType: boolean;
  constructor(
    public http: HttpClient
  ) { }

  getAllFreePictures(limit?, searchType?) {
    if (searchType && searchType !== undefined) {
      this.setPageNumber(limit);
    } else if (!searchType && searchType !== undefined){
      this.setPageLimit(limit);
    }
    // tslint:disable-next-line: max-line-length
    return this.http.get<any>(`${this.url}/agents/free-images?${this.pageNumber ? 'page=' + this.pageNumber : 'page=1'}${this.pageLimit ? '&limit=' + this.pageLimit : ''}`)
  }

  setPageNumber(value: number) {
    this.pageNumber = value;
  }
  setPageLimit(value: number) {
    this.pageLimit = value;
  }
  setSearchType(value: boolean) {
    this.searchType = value;
  }

  get Limit() {
    return this.pageLimit;
  }
}
