import { environment } from './../../../environments/environment.prod';
import { AuthService } from '@pages/auth/auth.service';
import {
  Component,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { Location } from '@angular/common';

declare const Stripe;

@Component({
  selector: 'app-stripe-card',
  templateUrl: './stripe-card.component.html',
  styleUrls: ['./stripe-card.component.scss']
})
export class StripeCard implements OnInit, AfterViewInit {
  private publishableKey: string = environment.stripeSecretKey;

  stripe;
  is_primary: boolean = false;
  @ViewChild('cardElement', { static: false }) cardElement: ElementRef;

  card;

  @Output() cardAdded: EventEmitter<any> = new EventEmitter();

  constructor(
    private location: Location,
    private auth: AuthService,
  ) {
    this.stripe = Stripe(this.publishableKey);
  }

  ngOnInit() {
    // this.card = this.stripe.elements().create('card');
  }

  ngAfterViewInit() {
    this.card.mount(this.cardElement.nativeElement);
  }

  // public addCard(){
  //   this.stripe.createToken(this.card)
  //   .then(this.onAddCardSuccess.bind(this));
  // }

  // private onAddCardSuccess(res) {
  //   const token = res.token;
  //   console.log("token 1", token)
  //   this.auth.createStripeAccount(
  //       token.card.id,
  //       token.card.funding,
  //       token.card.last4,
  //       token.id,
  //       token.card.brand,
  //       token.card.country,
  //       token.card.exp_month,
  //       token.card.exp_year,
  //       this.is_primary
  //   ).subscribe( res => {
  //     this.location.back();
  //     this.auth.showSnackbar('Credit card added succsessfuly', null, 3000);
  //     console.log('response on create', res);
  //   }, error => {
  //     this.auth.showSnackbar(error, null, 3000);
  //   });
  //   console.log('response', res.token)
  //   this.cardAdded.emit();
  // }
}
