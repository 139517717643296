import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-confirm-call',
  templateUrl: './confirm-call.component.html',
  styleUrls: ['./confirm-call.component.scss']
})
export class ConfirmCallComponent implements OnInit {

  type;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmCallComponent>,
  ) { }

  ngOnInit() {
  }
  choose(res) {
    this.dialogRef.close(res);
  }
  cancel() {
    this.dialogRef.close(null);
  }
  callAudio(res) {
    this.dialogRef.close(res);
  }
}
