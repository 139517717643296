import { AuthService } from '@pages/auth/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  canActivate() {

    if (this.auth.bankAccountStatus) {
      return true;
    } else {
      this.auth.showSnackbar('Add payment options first', null, 3000);
      this.router.navigate(['agent/payment-options']);
      return false;
    }
  }
}
