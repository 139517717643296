import { GetData } from './../../../shared/services/get-data.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export interface DialogData {
  animal: string;
  name: string;
}


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})


export class UsersComponent implements OnInit {
  agent: any;
  singleAgent;
  agentData: any;
  loading = true;
  images: any[];

  constructor(
    private getData: GetData,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const id: string = this.route.snapshot.params.agentId;
    this.getData.getAgent(id).subscribe(res => {
      this.agent = res;
      if (this.agent.data.galleryImages) {
        this.images = this.agent.data.galleryImages.data;
      }
      this.singleAgent = this.agent.data;
      this.agentData = this.singleAgent.tags.data;
      this.loading = false;
    });
  }
}

