import { Component, OnInit } from '@angular/core';
import { BlogService } from '@services/blog.service';
import { Router, ActivatedRoute, PRIMARY_OUTLET } from '@angular/router';
import { DataService } from '@services/data.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-blog-post-list',
  templateUrl: './blog-post-list.component.html',
  styleUrls: ['./blog-post-list.component.scss']
})
export class BlogPostListComponent implements OnInit {
  blogPost = [];
  singleBlog: any;
  single = false;
  loading = true;
  status: any;
  singlePost = false;
  pagination;
  responseStatus = [
    'approved',
    'pending',
    'rejected',
  ];
  searchForm = new FormGroup({
    byTitle: new FormControl(),
    byStatus: new FormControl()
  });
  constructor(
    private blogService: BlogService,
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService
  ) {
    route.queryParams.subscribe(p => {
      this.status = p.status;
      if (this.status === 'all') {
        this.getAllBlogPosts();
      } else if (this.status === 'agent') {
        this.getBlogPosts();
      } else {
        const url = this.router.parseUrl(this.router.url);
        const prim = url.root.children[PRIMARY_OUTLET];
        const segment = prim.segments[3].path;
        this.getSingleBlogPost(segment);
      }
    });
  }

  ngOnInit() {
  }
  resetSearch() {
    // this.searchForm.value.byTitle = null;
    // this.searchForm.value.byStatus = null;
    this.searchForm.setValue({
      byTitle: null,
      byStatus: null
    });
    this.blogService.setLimit(null);
    this.getBlogPosts();
  }
  getPages($event) {
    this.dataService.getData($event).subscribe(data => {
      this.blogPost = data.data;
      this.pagination = data.meta.pagination;
      if (this.status === 'all') {
        this.blogPost.map(blogPost => { blogPost.approved = true; });
      }
      window.scroll(0, 0);
    });
  }
  loadWithLimit(limit) {
    if (this.status === 'all') {
      this.getAllBlogPosts(limit);
    } else if (this.status === 'agent') {
      this.getBlogPosts(limit);
    }
    window.scroll(0, 0);
  }
  getSingleBlogPost(slug) {
    this.blogService.getSingleBlogPost(slug).subscribe((data) => {
      const blog = data.data;
      blog.approved = true;
      blog.single = true;
      this.blogEmited(blog);
      this.loading = false;
      this.singlePost = true;
    }, error => {
      this.loading = false;
      this.dataService.showSnackbar(error, null, 3000);
      this.router.navigate(['./home/blog-posts'], { queryParams: { status: 'all' } });
    });
  }
  getBlogPosts(limit?) {
    this.loading = true;
    if (limit) {
      this.blogService.setLimit(limit);
    }
    const searchData = {
      name: this.searchForm.value.byTitle,
      status: this.searchForm.value.byStatus
    };
    this.blogService.getAllBlogPosts(searchData).subscribe(data => {
      this.blogPost = data.data;
      this.loading = false;
      this.pagination = data.meta.pagination;
      window.scroll(0, 0);
      this.loading = false;
    }, () => this.loading = false);
  }

  getAllBlogPosts(limit?) {
    if (limit) {
      this.blogService.setLimit(limit);
    }
    this.blogService.getApprovedBlogPosts().subscribe(data => {
      this.blogPost = data.data;
      this.pagination = data.meta.pagination;
      this.blogPost.map(blogPost => { blogPost.approved = true; });
      this.loading = false;
    });
  }
  blogEmited(event) {
    this.singleBlog = [];
    this.singleBlog = new Array(event);
    this.single = true;
  }
  backEmitted(event) {
    if (event) {
      this.single = false;
    }
  }
  createNewBlog() {
    localStorage.setItem('blogPost', null);
    this.router.navigate(['../agent/create-new-blog']);
  }

  deletedBlog(event) {
    this.single = false;
    this.blogPost = this.blogPost.filter(blogPost => blogPost.id !== event);
  }
}
