import { DataService } from '@services/data.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-email-blocked-users',
  templateUrl: './email-blocked-users.component.html',
  styleUrls: ['./email-blocked-users.component.scss']
})
export class EmailBlockedUsersComponent implements OnInit {
  @Input() blockedUser;
  loading = false;
  constructor(
    private dataService: DataService
  ) { }

  ngOnInit() {
  }

  blockOrUnblockUser(status) {
    this.loading = true;
    const data = {
      id: this.blockedUser.id,
      is_sender_blocked: status
    };
    this.dataService.blockUnblockUser(data.id, data.is_sender_blocked).subscribe(res => {
      this.blockedUser = res.data;
      this.loading = false;
    }, err => {
      this.dataService.showSnackbar(err, null, 3000);
      this.loading = false;
    });
  }
}
