import { GetData } from '@services/get-data.service';
import { AuthService } from '@pages/auth/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '@services/data.service';
import { MatDialog } from '@angular/material';
import { ImageModalComponent } from '@layout/image-modal/image-modal.component';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {


  @ViewChild('radioInput', { static: false }) nameInputRef: ElementRef;
  product;
  previewFiles: any[] = [];
  filtered = <any>[];
  feedbacks: any;
  id: any;
  user: string;
  message: string = 'Feedback successfully added!';
  feedbackForm: any;
  FormGroup: FormGroup;
  purchased: boolean = false;
  agent_id: any;
  myOwnProduct: boolean = false;
  id_token: string;
  primaryFile: any[] = [];
  currentUser: any;
  boughtFile: any = [];
  fileBought: boolean;
  agentsId: any;
  pagination: any;
  role: string;
  previewVideo = false;
  primaryFileVideo: any;
  loading = false;
  videoProducts: any;
  audioProducts: any;
  imageProducts: any;
  zipProducts: any;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private authService: AuthService,
    private _formBuilder: FormBuilder,
    private router: Router,
    private getData: GetData,
    public dialog: MatDialog
  ) { }

  ngOnInit() {

    this.getDataForComponent();
    this.getProductDetails();
    this.getFeedbacks();
    this.buildForm();
  }

  buildForm() {
    this.FormGroup = this._formBuilder.group({
      comment: ['', Validators.required],
      rating: ['', Validators.required],
      product_id: [this.id]
    });
  }

  getDataForComponent() {
    this.id = this.route.snapshot.params.productId;
    this.agentsId = this.route.snapshot.params.agentId;
    this.user = localStorage.getItem('id');
    this.id_token = localStorage.getItem('id_token');
    this.role = localStorage.getItem('role');
  }

  getProductDetails() {
    this.dataService.getProductById(this.id).subscribe(res => {
      this.product = res.data;
      this.previewFiles = res.data.productFiles.data.filter(product => product.status === 'preview');
      this.videoProducts = res.data.productFiles.data.filter(product => product.status === 'primary' && product.type === 'video');
      this.audioProducts = res.data.productFiles.data.filter(product => product.status === 'primary' && product.type === 'audio');
      this.imageProducts = res.data.productFiles.data.filter(product => product.status === 'primary' && product.type === 'image');
      this.zipProducts = res.data.productFiles.data.filter(product => product.status === 'primary' && product.type === 'zip');
      this.primaryFileVideo = this.product.productFiles.data.find(product => product.type === 'video');
      if (this.primaryFileVideo && this.previewFiles.length === 0) {
        this.previewVideo = true;
      }
      this.feedbacks = res.data.feedbacks.data;
      this.agent_id = res.data.agent.data.id;
      if (this.agentsId === this.user && this.role !== 'client') {
        this.myOwnProduct = true;
      }

      const role = localStorage.getItem('role');
      if (role === 'client') {
        this.compareProductsClient();
      } else if (role === 'agent') {
        this.compareProductsAgent();
      }
    });
  }

  getFeedbacks() {
    // tslint:disable-next-line: variable-name
    const feedbackable_type = 'product';
    const include = '&include=product,author';
    this.getData.getAllFeedbacksByFeedbackableModel(this.id, feedbackable_type, include).subscribe(res => {
      this.feedbacks = res.data;
      this.pagination = res.meta.pagination;
    });
  }

  compareProductsClient() {
    this.authService.getClientOrders(this.user).subscribe(res => {
      this.currentUser = res.data;
      this.comparation();
    });
  }

  compareProductsAgent() {
    const id = localStorage.getItem('id');
    this.authService.getAgentOrders(id).subscribe(res => {
      this.currentUser = res.data;
      this.comparation();
    });
  }

  // check if product is bought to show buy or download btn
  comparation() {
    this.currentUser.find(item => {
      if (item.product.data.id === this.product.id) {
        this.boughtFile.push(item);
        this.fileBought = true;
        this.purchased = true;
      }
    });
  }

  onSubmit() {
    const comment = this.FormGroup.value.comment;
    const rating = this.FormGroup.value.rating;
    const product_id = this.FormGroup.value.product_id;
    this.authService.addProductFeedback(
      comment, rating, product_id
    ).subscribe(
      resData => {
        this.getFeedbacks();
        this.authService.showSnackbar(this.message, null, 3000);

      }, error => {
        this.authService.showSnackbar(error, null, 3000);
      }
    );
  }

  viewImage(src) {
    this.dialog.open(ImageModalComponent, { data: src, panelClass: 'image-modal' });
  }

  buyProduct(product_id) {
    // if (confirm('Are you sure you want to purchase this product?')) {
    if (this.agent_id && this.user) {
      this.dataService.createProductPurchase(product_id).subscribe(res => {
        this.purchased = true;
        this.fileBought = true;
        this.dataService.showSnackbar('Product successfully purchased', null, 5000);
      }, error => {
        this.dataService.showSnackbar(error, null, 3000);
      });
    } else {
      this.router.navigate(['auth/login']);
      this.dataService.showSnackbar('Please login to buy product', null, 3000);
    }
  }

  downloadProduct(id) {
    this.loading = true;
    this.dataService.getProductById(id).subscribe(res => {

      const file = res.data.productFiles.data.find(item => item.status === 'primary');
      const fileName = this.dataService.getFileName(file.path);
      this.loading = false;
      this.dataService.downloadProduct(res.data.id)
        .subscribe(
          this.dataService.downloadProductFile.bind(this, fileName)
        );
    });
  }

  // showing 10% of video if there is no preview uploaded
  settime() {
    const video: any = document.getElementById('myvideo');
    const limit = video.duration * 10 / 100;
    video.currentTime = 0;
    video.play();
    setInterval(() => {
      if (video.currentTime > limit) {
        video.pause();
      }
    }, 1000);
  }
}
