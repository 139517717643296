import { TwilioService } from '@services/twilio.service';
import { Directive, HostListener } from '@angular/core';



@Directive({
  selector: '[appBrowserLeave]'
})


export class BrowserLeaveDirective {


  constructor(private twilio: TwilioService) { }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    const sid = localStorage.getItem('sid');
    if (sid) {
      const role = localStorage.getItem('caller');
      let identity = '';

      if (role) {
        identity = 'unknown-caller';
      } else {
        identity = 'unknown';
      }

      // const status =  this.twilio.participantConnected();
      this.endCall(sid, identity);
      // return false;

    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event) {
    event.preventDefault();
    const sid = localStorage.getItem('sid');
    // const sid = this.twilio.getSid();
    if (sid) {
      // const status =  this.twilio.participantConnected();
      const role = localStorage.getItem('caller');
      let identity = '';
      if (role) {
        identity = 'unknown-caller';
      } else {
        identity = 'unknown';
      }
      this.endCall(sid, identity);
      localStorage.removeItem('sid');
      return false;
    }
  }


  endCall(sid, status) {
    this.twilio.endCall(sid, status).subscribe(res => {
     // console.log(res);
    });
  }
}
