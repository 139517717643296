import { BlogPostListComponent } from './../pages/home/blog-post-list/blog-post-list.component';
import { StripeCard } from './stripe-card/stripe-card.component';
import { HomeModule } from './../pages/home/home.module';
import { ProductThumbComponent } from '../layout/product-thumb/product-thumb.component';
import { CommonModule } from '@angular/common';
import { ShareModule } from '../shared/modules/share.module';
import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
// import { SearchBarComponent } from '@pages/home/search-bar/search-bar.component';
import { UserCardComponent } from '@pages/home/user-card/user-card.component';
import { AgentHeaderComponent } from './agent-header/agent-header.component';
import { AgentProfileHeaderComponent } from './agent-profile-header/agent-profile-header.component';
import { ProductDetailsComponent } from './product-thumb/product-details/product-details.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { InboxMessagesComponent } from './inbox-messages/inbox-messages.component';
import { ComposeMailComponent } from './compose-mail/compose-mail.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { ViewPortComponent, RemoveUnderscorePipe } from './view-port/view-port.component';
import { OrderLayoutComponent } from './order-layout/order-layout.component';
import { CreditCardFormComponent } from './credit-card-form/credit-card-form.component';
import { NoContentComponent } from './no-content/no-content.component';
import { OutboxMessagesComponent } from './outbox-messages/outbox-messages.component';
import { ViewSingleMessageComponent } from './view-single-message/view-single-message.component';
import { SendSingleMessageComponent } from './send-single-message/send-single-message.component';
import { PaginationComponent } from './pagination/pagination.component';
import { PublicFeedbackComponent } from '@pages/home/users/users-detail/public-feedback/public-feedback.component';
import { FooterModalComponent } from './footer-modal/footer-modal.component';
import { TransactionLayoutComponent } from './transaction-layout/transaction-layout.component';
import { ImageModalComponent } from './image-modal/image-modal.component';
import { EmailBlockedUsersComponent } from './email-blocked-users/email-blocked-users.component';
import { CookieAcceptComponent } from './cookie-accept/cookie-accept.component';
import { UploadProgressComponent } from './upload-progress/upload-progress.component';
import { TrainingVideosComponent } from './training-videos/training-videos.component';
import { ConfirmCallComponent } from './confirm-call/confirm-call.component';
import { ConfirmRejectComponent } from './confirm-reject/confirm-reject.component';
import { ChatRoomComponent } from './chat-room/chat-room.component';
import { ReportDeleteChatComponent } from './chat-room/report-delete-chat/report-delete-chat.component';
import { BlogPostSingleComponent } from '@pages/home/blog-post-single/blog-post-single.component';

const PARTIALS = [
  HeaderComponent,
  FooterComponent,
  // SearchBarComponent,
  UserCardComponent,
  AgentHeaderComponent,
  AgentProfileHeaderComponent,
  ProductThumbComponent,
  ProductDetailsComponent,
  StripeCard,
  RemoveUnderscorePipe,
  OrderLayoutComponent,
  SpinnerComponent,
  FeedbackComponent,
  InboxMessagesComponent,
  ComposeMailComponent,
  PasswordChangeComponent,
  ViewPortComponent,
  OrderLayoutComponent,
  CreditCardFormComponent,
  NoContentComponent,
  OutboxMessagesComponent,
  ViewSingleMessageComponent,
  PaginationComponent,
  SendSingleMessageComponent,
  PublicFeedbackComponent,
  FooterModalComponent,
  TransactionLayoutComponent,
  ImageModalComponent,
  BlogPostListComponent,
  BlogPostSingleComponent 
];

@NgModule({
  declarations: [...PARTIALS, EmailBlockedUsersComponent, CookieAcceptComponent, UploadProgressComponent, TrainingVideosComponent, ConfirmCallComponent, ConfirmRejectComponent, ChatRoomComponent, ReportDeleteChatComponent],
  imports: [ShareModule, CommonModule],
  exports: [
    ShareModule,
    ...PARTIALS,
    SpinnerComponent,
    FeedbackComponent,
    InboxMessagesComponent,
    ComposeMailComponent,
    PasswordChangeComponent,
    ViewPortComponent,
    EmailBlockedUsersComponent],
  entryComponents: [
    ViewSingleMessageComponent,
    SendSingleMessageComponent,
    FooterModalComponent,
    ImageModalComponent,
    CookieAcceptComponent,
    UploadProgressComponent,
    ConfirmCallComponent,
    ConfirmRejectComponent,
    ReportDeleteChatComponent
  ]
})
export class LayoutModule { }
