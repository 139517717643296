import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@pages/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class EditProfileGuard implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


    if (this.auth.editProfileStatus) {
      return true;
    } else {
      this.auth.showSnackbar('Your previos changes are waiting for approval', null, 4000);
      // this.router.navigate(['agent/edit-profile']);
      return false;
    }
  }
}
