import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-view-single-message',
  templateUrl: './view-single-message.component.html',
  styleUrls: ['./view-single-message.component.scss']
})
export class ViewSingleMessageComponent implements OnInit {
  unreadMessageRes = true;
  type: string;
  role: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ViewSingleMessageComponent>,
  ) { }

  ngOnInit() {
    // determine if should have reply button
    this.type = this.data.type;
    // reasign data due to last change dialog data received as object
    this.data = this.data.message;
    this.role = localStorage.getItem('role'); 
  }

  onClose() {
    this.dialogRef.close();
  }
  // reply directly from view dialog
  onReply() {
    this.dialogRef.close(this.data);
  }
}
