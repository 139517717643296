export class User {
  constructor(
    public access_token,
    public role_name,
    public refresh_token,
    public token_type,
    public expires_in,
    ) {
      this.access_token = access_token;
      this.role_name = role_name;
      this.refresh_token = refresh_token;
      this.token_type = token_type;
      this.expires_in = expires_in

    }

  get token() {
    if (!this.expires_in || new Date() > this.expires_in) {
      return null;
    }
    return this.access_token;
  }
}
