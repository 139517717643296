import { Subscription } from 'rxjs';
import { DataService } from './../../shared/services/data.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ViewSingleMessageComponent } from '@layout/view-single-message/view-single-message.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-outbox-messages',
  templateUrl: './outbox-messages.component.html',
  styleUrls: ['./outbox-messages.component.scss']
})
export class OutboxMessagesComponent implements OnInit, OnDestroy {
  messages: any[] = [];
  mesagesSubscription: Subscription;
  pagination: any;
  pagesSubscription: Subscription;

  constructor(
    private dataService: DataService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getUserSentMessages();
    this.getSentMessages();
  }

  getUserSentMessages(limit?) {
    const msgDirection = 'sender';
    const include = 'receiver';
    this.dataService.GetAllMessagesByUser(msgDirection, include, limit).subscribe(res => {
      this.messages = res.data;
      this.pagination = res.meta.pagination;
    });
  }

  getSentMessages() {
    this.mesagesSubscription = this.dataService.getMessage().subscribe(res => {
      this.messages.push(...res.text.data);
      this.getUserSentMessages();
    });
  }

  ngOnDestroy(): void {
    if (this.mesagesSubscription) {
      this.mesagesSubscription.unsubscribe();
    }
  }

  openDialog(id): void {
    this.dialog.open(
      ViewSingleMessageComponent,
      {
        data: {
          message: this.messages.find(msg => msg.id === id),
          type: 'outbox'
        },
        panelClass: ['no-padding', 'no-scrolls'],
      }
    );
  }
  getPages($event) {
    this.pagesSubscription = this.dataService.getData($event).subscribe(res => {
      this.messages = res.data;
      this.pagination = res.meta.pagination;
    });
  }

  deleteMessage(id) {
    this.dataService.deleteMessage(id).subscribe(res => {
      this.getUserSentMessages();
      this.dataService.showSnackbar('Success', null, 3000);
    }, error => {
      console.error(error);
    });
  }
  loadWithLimit(limit) {
    this.getUserSentMessages(limit);
  }
}
