import { FreePicturesService } from './../../shared/services/free-pictures.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@services/common.service';
import { TwilioService } from './../../shared/services/twilio.service';
import { DataService } from '@services/data.service';
import { AuthService } from './../auth/auth.service';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { GetData } from '@services/get-data.service';
import { environment } from 'src/environments/environment.prod';
import { WebSocketService } from '@services/websocket.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {

  // agents to be shown on home page
  agent: any[] = [];

  // need fix for search to work, changes made on favorites feature addition
  agents: any;

  loading = true;
  url = `${environment.baseUrl}/agents/status/approved?include=user,favorites`;
  noAgent = false;
  filterName;
  keyword: string;
  timer = null;
  favoriteAgentsByIds: any = {};
  gender: any = '';
  sort: any = 'featured';
  agent2: any;
  bids: any[] = [];
  isMobile = false;
  show = false;
  agentID: string;
  busyAgent: any;
  pagination: any;
  pagesSubscription: any;
  subscription: any;
  featured;
  sorted;
  spt: any;
  user_name_for_search: string;
  constructor(
    private data: GetData,
    private auth: AuthService,
    private dataService: DataService,
    private twilio: TwilioService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private freePicService: FreePicturesService
  ) {
  }


  ngOnInit() {
    this.featured = this.auth.GenderFiled;
    this.keyword = this.auth.Keyword;
    this.sorted = this.auth.Input;
    this.user_name_for_search = this.auth.UserName;
    this.homePageNavigationDetect();
    // all agents for main page
    this.getAllAgents();
    const role = localStorage.getItem('role');
    if (role === 'client') {

      this.auth.getAuthenticatedUser().subscribe(res => {
        if (res) {
          const userData = res.data;
          const result = userData.favorites.data;
          result.forEach(agent => {
            this.favoriteAgentsByIds[agent.id] = true;
          });
        }
      });
    } else if (role === 'agent') {
      this.auth.getAgentInfo().subscribe(res => {
        if (res) {
          const userData = res.data.agent.data;
          const result = userData.favorites.data;
          result.forEach(agent => {
            this.favoriteAgentsByIds[agent.id] = true;
          });
        }
      });
    }

    // listen for availability status change for each agent card
    this.subscription = this.twilio.currentMessage.subscribe(message => {
      if (message) {
        const busyAgent = message.agentId;
        const status = message.status;
        this.agent.forEach(agent => {
          if (agent.id === busyAgent) {
            agent.availability_status = status;
          }
        });
        this.iconAvailabilityCheck();
      }
    });

    this.agentListingTypesChangedEvent();


  }

  // scroll into view of a single card last visited
  ngAfterViewInit() {
    if (sessionStorage.getItem('scrollID')) {
      // take id from SS to find card with matching div
      const sp = JSON.parse(sessionStorage.getItem('scrollID'));
      // timeot in order to prevent sudden movement of elements
      setTimeout(() => {
        const elmnt = document.getElementById(sp);
        if (elmnt) {
          elmnt.scrollIntoView();
        }
      }, 1500);
      // remove to prevent jump to card if other page visited after user card
      //  sessionStorage.setItem('scrollID', '');
    }

  }
  homePageNavigationDetect() {
    this.route
      .queryParams
      .subscribe(queryParams => {
        if (queryParams.page) {
          this.auth.setGenderFiled('');
          this.auth.setPageNumber(queryParams.page);
          this.auth.setInput(null);
          this.auth.setKeyword('');
          this.auth.setUserName('');
          this.auth.setLimit(null)
          this.getAllAgents();
          this.featured = 'All';
          this.sorted = '';
          this.keyword = undefined;
          this.user_name_for_search = '';
          this.agent2 = [];
          this.freePicService.setPageLimit(50);
          this.freePicService.setPageNumber(1);
          // remove to prevent jump to card if other page visited  
          sessionStorage.setItem('scrollID', '');
          this.router.navigate(['.'], { relativeTo: this.route, queryParams: {} });

        } else if (queryParams.sort) {
          this.auth.setInput(queryParams.sort);
        }
      });

  }

  agentListingTypesChangedEvent() {
    this.commonService.getMessage().subscribe(res => {
      const agent = this.agent.find(agent => agent.id === res.text.agentId);
      // console.log('admin approved', res.text);
      if (agent) {
        this.iconAvailabilityCheckForEvent(agent, res.text);
      }
    });
  }


  // all agents for main page
  getAllAgents(limit?) {
    if (limit) {
      this.auth.setLimit(limit);
    }
    this.auth.getAllAgents().subscribe(res => {
      this.agent = res.data;
      this.pagination = res.meta.pagination;
      this.loading = false;
      this.sortingAgents();
    });
  }


  iconAvailabilityCheckForEvent(agent, event) {
    const cam = event.listingTypes.filter(t => t === 'cam');
    if (cam.length > 0 && (agent.availability_status === 'cam' || agent.availability_status === 'online')) {
      agent.cam = true;
    } else {
      agent.cam = false;
    }
    const phone = event.listingTypes.filter(t => t === 'phone');
    if (phone.length > 0 && (agent.availability_status === 'phone' || agent.availability_status === 'online')) {
      agent.phone = true;
    } else {
      agent.phone = false;
    }
    if (agent.availability_status === 'busy' || agent.availability_status === 'offline') {
      agent.cam = false;
      agent.phone = false;
    }
    return agent;
  }

  iconAvailabilityCheck() {
    this.agent.forEach(agent => {
      const cam = agent.listingTypes.data.filter(t => t.name === 'cam');
      if (cam.length > 0 && (agent.availability_status === 'cam' || agent.availability_status === 'online')) {
        agent.cam = true;
      } else {
        agent.cam = false;
      }
      const phone = agent.listingTypes.data.filter(t => t.name === 'phone');
      if (phone.length > 0 && (agent.availability_status === 'phone' || agent.availability_status === 'online')) {
        agent.phone = true;
      } else {
        agent.phone = false;
      }
      if (agent.availability_status === 'busy' || agent.availability_status === 'offline') {
        agent.cam = false;
        agent.phone = false;
      }
    });
  }

  // getting pages from pagination links
  getPages($event) {
    this.pagesSubscription = this.dataService.getData($event).subscribe(res => {
      this.agent = res.data;
      this.pagination = res.meta.pagination;
      this.auth.setPageNumber(res.meta.pagination.current_page);
      window.scroll(0, 0);
      this.router.navigate(['.'], { relativeTo: this.route, queryParams: {} });
      this.iconAvailabilityCheck();
    });
  }

  loadWithLimit(limit) {
    this.getAllAgents(limit);
    window.scroll(0, 0);
  }


  toogleShow() {
    this.show = !this.show;
  }

  getIsMobile(): boolean {
    const w = window.innerWidth;
    const breakpoint = 600;
    if (w > breakpoint) {
      return true;
    } else {
      return false;
    }
  }


  // search by username, search result shown in div below input
  searchByUserName(text) {
    // if input empty div hidden
    if (text === '') {
      this.auth.setUserName('');
      this.agent2 = [];
      this.getAllAgents();
      // if input search in BE base
    } else {
      this.auth.setUserName(text);
      this.auth.setPageNumber(1);
      this.auth.getAllAgents().subscribe(res => {
        this.agent = res.data;
        setTimeout(() => {
          this.getAllAgents();
          this.agent2 = this.agent;
          this.loading = false;
        }, 1000);
      });
    }
  }



  searchByKeyword(keyword) {
    if (keyword.length > 2) {
      this.auth.setKeyword(keyword);
      this.auth.setPageNumber(1);
      this.getAllAgents();
    } else if (keyword === '') {
      this.auth.setKeyword('');
      this.getAllAgents();
    }
    // this.data.searchByKeyword(keyword).subscribe(res => {
    //   this.agents = Object.keys(res).map(i => res[i]);
    //   this.agent = this.agents[0];
    //   this.iconAvailabilityCheck();
    //   this.loading = false;
    // });
  }

  searchByGender(input) {
    this.gender = input;
    this.loading = true;
    this.auth.setGenderFiled(input);
    this.auth.setPageNumber(1);
    this.getAllAgents();
    this.iconAvailabilityCheck();
  }

  // sorting by featured or top rated
  sortBy(input) {
    this.sort = input;
    this.loading = true;
    this.auth.setInput(input);
    // if (input === '') {
    this.getAllAgents();
    this.router.navigate(['.'], { relativeTo: this.route, queryParams: { sort: input } });
  }


  // sort agents and handle loading
  sortingAgents() {
    if (this.agent.length) {
      this.noAgent = false;
      this.loading = false;
    } else {
      // this.loading = true;
      this.noAgent = true;
    }
    this.iconAvailabilityCheck();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
