import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { catchError, tap } from 'rxjs/operators';
import { throwError, Observable, BehaviorSubject, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material';

export interface ResponseData {
  subject: string;
  ticket_category_id: string;
  body: string;
}


export interface ProductData {
  name: string;
  description: string;
  price: number;
  gender_availability: string;
  keywords: string;
  primary_file: any;
  preview_files;
  data: any;
  id: any;
}

@Injectable({
  providedIn: 'root'
})

export class DataService {


  url = environment.baseUrl;
  agentInfo: any;
  private messageSource = new BehaviorSubject(null);
  currentMessage = this.messageSource.asObservable();

  private subject = new Subject<any>();
  private unread = new Subject<any>();

  constructor(public http: HttpClient, private snackbar: MatSnackBar) { }

  sendMessage(message: any) {
    this.subject.next({ text: message });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }


  changeMessage(message: any) {
    this.messageSource.next(message)
  }

  getUnread(): Observable<any> {
    return this.unread.asObservable();
  }
  sendUnread(message: any) {
    this.unread.next({ text: message });
  }

  // create ticket for admin support
  createTicket(
    subject: string,
    ticket_category_id: string,
    body: string,
  ) {
    return this.http.post<ResponseData>(`${this.url}/tickets?include=ticketResponses,ticketCategory`, {
      subject,
      ticket_category_id,
      body
    }).pipe(
      catchError(this.handleError)
    );
  }

  // show individual ticket on view ticket component
  getTicketById(id) {
    return this.http.get<any>(`${this.url}/tickets/${id}?include=user,ticketCategory,ticketResponses`).pipe(
      catchError(this.handleError)
    );
  }


  // give tip to agent
  createTip(
    amount: number,
    agent_id: string
  ) {
    return this.http.post(`${this.url}/tips`, { amount, agent_id }).pipe(catchError(this.handleError));
  }


  // upload images to gallery
  uploadImages(files) {
    return this.http.post(`${this.url}/gallery-images?include=galleryImages`, files, {
      reportProgress: true,
      observe: 'events'
    }).pipe(catchError(this.handleError));
  }

  getAdultProof(id) {
    return this.http.get<any>(`${this.url}/users/${id}?include=adultProof`).pipe(
      catchError(this.handleError)
    );
  }
  updateAdultProof(id, file) {
    return this.http.post(`${this.url}/adult-proofs/${id}?_method=PATCH`, file, {
    }).pipe(catchError(this.handleError));
  }

  // get information for logged in agent
  getAgentInfo(id): Observable<any> {
    return this.http.get(
      `${this.url}/agents/${id}?include=products.productFiles,products.productViews.viewer,products.productViews.product,feedbacks,galleryImages`
    ).pipe(catchError(this.handleError));
  }

  // delete feedback 

  deleteSingleFeedback(id) {
    return this.http.delete<any>(`${this.url}/feedbacks/${id}`).pipe(catchError(this.handleError));
  }

  // get products for single agent
  getAllProducts(): Observable<any> {
    return this.http.get(`${this.url}/user/profile?include=agent.products`).pipe(
      catchError(this.handleError), tap(res => {
        this.agentInfo = res;
      })
    );
  }

  // show agents products
  getAllProductsByAgent(id, limit?): Observable<any> {
    return this.http.get(`${this.url}/products?agent_id=${id}${limit ? '&limit=' + limit : '&limit=10'}`).pipe(
      catchError(this.handleError), tap(res => {
        this.agentInfo = res;
      })
    );
  }

  // get single product for product details component
  getProductById(id) {
    return this.http.get<any>(`${this.url}/products/${id}?include=productFiles,tags,feedbacks.author,agent,productViews`).pipe(
      catchError(this.handleError)
    );
  }

  // viewed products by client
  getViewedProducts() {
    return this.http.get<any>(`${this.url}/user/profile?include=productViews.product.agent`).pipe(
      catchError(this.handleError)
    );
  }


  createProductPurchase(product_id) {
    return this.http.post(`${this.url}/product-purchases`, { product_id }).pipe(
      catchError(this.handleError)
    );
  }

  sendChatMessage(message: string) {
    return this.http.post(`${this.url}/chats?include=sender`, { message }).pipe(
      catchError(this.handleError)
    );
  }

  getAllChats() {
    return this.http.get<any>(`${this.url}/chats?include=sender`).pipe(
      catchError(this.handleError)
    );
  }

  reportChatMessage(data) {
    return this.http.post<any>(`${this.url}/chat-reports?include=chat.sender,reporter`,data).pipe(
      catchError(this.handleError)
    );
  }

  downloadProduct(id) {
    return this.http.get(`${this.url}/products/${id}/download`, { responseType: 'arraybuffer' }).pipe(
      catchError(this.handleError)
    );
  }
  getFileName(path) {
    const lastDotIndex = path.lastIndexOf('/');
    return path.slice(lastDotIndex + 1);
  }

  downloadProductFile(fileName, res) {
    const reader = new FileReader();
    const blob = new Blob([res], {
      type: 'application/zip'
    });
    reader.readAsArrayBuffer(blob);
    reader.onloadend = () => {
      const fileURL = URL.createObjectURL(blob);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.download = 'PlayPages.zip';
      fileLink.click();
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(fileURL);
        fileLink.remove();
      }, 100);
    };
  }

  downloadFile(id) {
    return this.http.get(`${this.url}/product-files/${id}/download`).pipe(
      catchError(this.handleError)
    );
  }

  // delete single product
  deleteProduct(id) {
    return this.http.delete<any>(`${this.url}/products/${id}`).pipe(
      catchError(this.handleError)
    );
  }

  // get viewers of agent product
  getproductViewes(user, sort, url?) {
    const url1 = `/product-views?search=product.agent.user_name:${user}&orderBy=created_at&sortedBy=${sort}&include=viewer,product`
    return this.http.get<any>(`${this.url}${url1}`).pipe(
      catchError(this.handleError)
    );
  }

  // get views from url
  getProductViewsFromUrl(url) {
    return this.http.get<any>(`${url}`).pipe(
      catchError(this.handleError)
    );
  }

  changeAvailabilityStatus(availability_status) {
    return this.http.post(`${this.url}/agents/availability-status?_method=PATCH`, { 'availability_status': availability_status }).pipe(
      catchError(this.handleError)
    );
  }

  setAllAliasProfilesAvailable() {
    return this.http.post<any>(`${this.url}/agents/alias-profiles/availability-status?_method=PATCH`, {}).pipe(
      catchError(this.handleError)
    );
  }

  // affiliate

  joinAffiliateProgram() {
    return this.http.post<any>(`${this.url}/agents/affiliate`, {}).pipe(
      catchError(this.handleError)
    );
  }

  getAllBanners() {
    return this.http.get<any>(`${this.url}/banners?search=type:affiliate`).pipe(catchError(this.handleError));
  }

  downloadBanner(id) {
    return this.http.get(`${this.url}/banners/${id}/download`, { responseType: 'arraybuffer' });
  }
  // transactions


  getAllTransactionsByUser(
    start_date, end_date, id, role, limit?, amount?
  ) {
    return this.http.get<any>(`
    ${this.url}/transactions/users/${id}?start_date=${start_date}&end_date=${end_date}&type=${role}&include=order.report.client,order.report.agent${amount ? amount : ''}${limit ? '&limit=' + limit : ''}`
    ).pipe(catchError(this.handleError));
  }


  getNextPage(link) {
    return this.http.get<any>(`${link}`).pipe(catchError(this.handleError));
  }


  getClientTransactions() {
    return this.http.get<any>(`${this.url}/user/profile?include=transactions.order.report`).pipe(
      catchError(this.handleError)
    );
  }

  // weekly payout

  createWeeklyPayout(
    amount_to_be_paid,
    amount_to_remain,
    payoutId?
  ) {
    const formData = new FormData();
    if (amount_to_remain) {
      formData.append('amount_to_remain', amount_to_remain);
    }
    if (amount_to_be_paid) {
      formData.append('amount_to_be_paid', amount_to_be_paid)
    }
    return this.http.post<any>(`${this.url}/weekly-payout-applications${payoutId}`, formData).pipe(
      catchError(this.handleError)
    );
  }

  // find bank account by id

  findBankAccountById(id) {
    return this.http.get<any>(`${id}`).pipe(
      catchError(this.handleError)
    );
  }

  // add credit card

  addCreditCard(
    name_on_card: string,
    card_number: string,
    card_type: string,
    exp_month: string,
    exp_year: string,
    cvc: string,
    is_primary: string,
    address: string,
    city_id: string,
    postal_code: string,
    city: string,
    state_id: string,
  ) {
    const formData = new FormData();
    formData.append('name_on_card', name_on_card);
    formData.append('card_number', card_number);
    formData.append('card_type', card_type);
    formData.append('exp_month', exp_month);
    formData.append('exp_year', exp_year);
    formData.append('cvc', cvc);
    formData.append('is_primary', is_primary);
    formData.append('address', address);
    formData.append('postal_code', postal_code);
    if (city) {
      formData.append('city', city);
      formData.append('state_id', state_id);
    } else {
      formData.append('city_id', city_id);
    }
    return this.http.post<any>(`${this.url}/credit-cards?include=addresses`, formData).pipe(
      catchError(this.handleError)
    );
  }


  // single payout request
  createpayoutRequest(
    amount
  ) {
    return this.http.post<any>(`${this.url}/payout-requests`, { amount }).pipe(
      catchError(this.paymentError)
    );
  }
  getMinBalanceForPaymantRequest() {
    return this.http.get<any>(`${this.url}/global-variables?search=key:min_balance_for_payment_request`).pipe(
      catchError(this.handleError)
    );
  }

  // create bank account

  createBankAccount(
    // bank_name,
    payment_mode,
    account_number,
    routing_number,
    name_on_account,
    paxum_id,
    us_citizen,
    social_security_number,
  ) {
    const formData = new FormData();

    // formData.append('bank_name', bank_name);
    formData.append('payment_mode', payment_mode);
    if (payment_mode === 'ACH') {
      formData.append('account_number', account_number);
      formData.append('routing_number', routing_number);
      formData.append('name_on_account', name_on_account);
    }
    if (payment_mode === 'PAXUM') {
      formData.append('paxum_id', paxum_id);
    }
    formData.append('us_citizen', us_citizen);
    formData.append('social_security_number', social_security_number);

    return this.http.post<any>(`${this.url}/bank-accounts?include=user`, formData).pipe(
      catchError(this.handleError)
    );
  }

  // update bank account

  updateBankAccount(
    // bank_name,
    payment_mode,
    account_number,
    routing_number,
    name_on_account,
    paxum_id,
    us_citizen,
    social_security_number,
    id
  ) {
    const formData = new FormData();

    // if (bank_name) {
    //   formData.append('bank_name', bank_name);
    // }
    if (payment_mode) {
      formData.append('payment_mode', payment_mode)
    }
    if (payment_mode === 'ACH') {
      if (account_number) {
        formData.append('account_number', account_number)
      }
      if (routing_number) {
        formData.append('routing_number', routing_number)
      }
      if (name_on_account) {
        formData.append('name_on_account', name_on_account)
      }
    }
    if (payment_mode === 'PAXUM') {
      formData.append('paxum_id', paxum_id);
    }
    formData.append('us_citizen', us_citizen)
    // if (us_citizen) {
    // }
    if (social_security_number) {
      formData.append('social_security_number', social_security_number)
    }
    return this.http.post<any>(`${this.url}/bank-accounts/${id}?_method=PATCH`, formData).pipe(
      catchError(this.handleError)
    );
  }

  // delete weekly payment request

  deleteWeeklyPayoutApplication(id) {
    return this.http.delete<any>(`${this.url}/weekly-payout-applications/${id}`).pipe(
      catchError(this.handleError)
    );
  }


  // check users bank account

  checkBankAccount() {
    return this.http.get<any>(`${this.url}/user/profile?include=bankAccounts`).pipe(
      catchError(this.handleError)
    );
  }

  getData(_link) {
    return this.http.get<any>(_link);
  }

  getSafeImages(_link) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(_link, {headers,  responseType: 'text/plain' as 'json'});
  }

  // messages

  //get user sent messages

  GetAllMessagesByUser(msgDirection, include, limit?) {
    return this.http.get<any>(`${this.url}/messages/users?type=${msgDirection}&include=${include}&orderBy=status&sortedBy=desc${limit ? '&limit=' + limit : ''}`).pipe(
      catchError(this.handleError)
    );
  }

  findMessageById(id) {
    return this.http.get<any>(`${this.url}/messages/${id}`).pipe(catchError(this.handleError));
  }

  getAllAgentsForMessages() {
    return this.http.get<any>(`${this.url}/agents?filter_type=message&limit=0`).pipe(
      catchError(this.handleError), tap(res => {
        this.agentInfo = res;
      })
    );
  }

  deleteMessage(id) {
    return this.http.delete<any>(`${this.url}/messages/${id}`).pipe(
      catchError(this.handleError)
    );
  }

  getBlockedUsers() {
    return this.http.get<any>(`${this.url}/messages/users/block`).pipe(
      catchError(this.handleError));
  }

  blockUnblockUser(id, is_sender_blocked): any {
    return this.http.patch(`${this.url}/messages/users/block/${id}`, { is_sender_blocked })
      .pipe(catchError(this.handleError));
  }


  // BIDS

  setBid(amount: number) {
    return this.http.post(`${this.url}/bids?include=agent`, { amount })
      .pipe(catchError(this.handleError));
  }

  stopBid(bidId) {
    return this.http.patch(`${this.url}/bids/${bidId}/close`, {})
      .pipe(catchError(this.handleError));
  }

  getHighestBid() {
    return this.http.get<any>(`${this.url}/bids?search=status:active&orderBy=amount&sortedBy=desc&limit=1`).pipe(
      catchError(this.handleError));
  }

  // create bid charge

  createBidCharge(
    agent_id: string,
    user_id?: any
  ) {
    const formData = new FormData();
    formData.append('agent_id', agent_id);
    if (user_id) {
      formData.append('user_id', user_id);
    }
    return this.http.post(`${this.url}/bid-charges`, formData)
      .pipe(
        catchError(this.handleError)
      );
  }


  // error handling
  handleError(error: HttpErrorResponse) {
    switch (error.status) {
      case 400:
      case 401:
      case 403:
      case 404:
      case 417:
        return throwError(error.error.message);
      case 429:
        return throwError(error.error || error.message);
      case 422:
        if (error.error.errors) {
          if (error.error.errors.name_on_card) {
            return throwError(error.error.errors.name_on_card[0]);
          }
          if (error.error.errors.card_number) {
            return throwError(error.error.errors.card_number[0]);
          }
          if (error.error.errors.card_type) {
            return throwError(error.error.errors.card_type[0]);
          }
          if (error.error.errors.is_primary) {
            return throwError(error.error.errors.is_primary[0]);
          }
          if (error.error.errors.exp_month) {
            return throwError(error.error.errors.exp_month[0]);
          }
          if (error.error.errors.message) {
            return throwError(error.error.errors.message[0]);
          }
          if (error.error.errors.exp_year) {
            return throwError(error.error.errors.exp_year[0]);
          }
          if (error.error.errors.cvc) {
            return throwError(error.error.errors.cvc[0]);
          }
          if (error.error.errors.amount_to_remain) {
            return throwError(error.error.errors.amount_to_remain[0]);
          }
          if (error.error.errors.files) {
            return throwError(error.error.errors.files[0]);
          }
          if (error.error.errors['files.0']) {
            return throwError('Failed to upload files');
          }
          if (error.error.errors.address) {
            return throwError(error.error.errors.address[0]);
          }
          if (error.error.errors.city_id) {
            return throwError(error.error.errors.city_id[0]);
          }
          if (error.error.errors.postal_code) {
            return throwError(error.error.errors.postal_code[0]);
          }
          if (error.error.errors.amount) {
            return throwError(error.error.errors.amount[0]);
          }
          if (error.error.errors.comment) {
            return throwError(error.error.errors.comment[0]);
          }
          if (error.error.errors.bank_name) {
            return throwError(error.error.errors.bank_name[0]);
          }
          if (error.error.errors.account_number) {
            return throwError(error.error.errors.account_number[0]);
          }
          if (error.error.errors.name_on_account) {
            return throwError(error.error.errors.name_on_account[0]);
          }
          if (error.error.errors.payment_mode) {
            return throwError(error.error.errors.payment_mode[0]);
          }
          if (error.error.errors.routing_number) {
            return throwError(error.error.errors.routing_number[0]);
          }
          if (error.error.errors.social_security_number) {
            return throwError(error.error.errors.social_security_number[0]);
          }
        } else {
          if (error.error.status_code === 422) {
            return throwError(error.error.message);
          }
        }
        break;
      case 500:
        return throwError('Internal Server error, please try again later');
    }
  }

  paymentError(error: HttpErrorResponse) {
    if (error.error.errors.amount) {
      return throwError(error.error.errors.amount[0]);
    } else if (error.error.status_code === 422) {
      return throwError(error.error.message);
    }

  }
  // show message to user when error occures
  showSnackbar(message, action, duration) {

    duration = 1000000;
    let snackRef = this.snackbar.open(message, 'Close',
      {
        duration,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['custom-snackbar']
      });
  }
}
