import { FreePicturesService } from './../../shared/services/free-pictures.service';
import { AuthService } from '@pages/auth/auth.service';
import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  limit;
  storedLimit;
  @Input() pagination;
  @Input() hideLimit;
  @Output() nextPage = new EventEmitter<string>();
  @Output() selected = new EventEmitter<any>();
  @Output() Navigate = new EventEmitter();
  constructor(
    private auth: AuthService,
    private freePicS: FreePicturesService
  ) { }

  ngOnInit() {
    if (this.hideLimit) {
      this.storedLimit = this.freePicS.Limit;
    } else {
      this.storedLimit = this.auth.Limit;
    }
  }

  nextPageEmit(page) {
    this.nextPage.emit(page);
  }
  getDataWithPageLimit() {
    this.selected.emit(this.limit);
  }
  navigateTo(element: string) {
    this.Navigate.emit(element);
  }
}
