import { GetData } from './../../shared/services/get-data.service';
import { DataService } from '@services/data.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@pages/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-compose-mail',
  templateUrl: './compose-mail.component.html',
  styleUrls: ['./compose-mail.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate('0ms 300ms ease-in')),
    ]),
    trigger('EnterLeave', [
      state('flyIn', style({ transform: 'translateX(0)' })),
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.3s 300ms ease-in')
      ]),
      transition(':leave', [
        animate('0.3s ease-out', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class ComposeMailComponent implements OnInit {
  agents: any;
  FormGroup: FormGroup;
  clients: any;
  role: string;
 // filteredList: any;
  loading: boolean;
  spinner = false;
  check = false;
  noMatch = false;
  timer: NodeJS.Timer;
  receiver: any;
  constructor(
    private auth: AuthService,
    private _formBuilder: FormBuilder,
    private data: DataService,
    private getData: GetData
  ) { }

  ngOnInit() {
    this.role = localStorage.getItem('role');
    // creating form
    this.createForm();
  }

  createForm() {
    this.FormGroup = this._formBuilder.group({
      receiver_id: ['', Validators.required],
      subject: ['', Validators.required],
      body: ['', Validators.required],
    });
  }

  searchAgents(text) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.loading = true;
      if (text.length) {
        this.getData.searchUsersByUserName(text, this.role).subscribe(res => {
          if (res.length) {
            this.receiver = res;
            this.check = true;
            this.noMatch = false;
          } else {
            this.receiver = [];
            this.check = false;
            this.noMatch = true;
          }
          this.loading = false;
        });
      } else {
        this.check = false;
        this.receiver = [];
        this.noMatch = false;
        this.loading = false;
      }
    }, 500);
  }

  onSubmit() {
    this.spinner = true;
    const receivers = this.receiver.map(agent => ({ receiver_id: agent.id, is_client: agent.is_client }));
    const subject = this.FormGroup.value.subject;
    const body = this.FormGroup.value.body;

    let messageObs: Observable<any>;
    messageObs = this.auth.createMessageByAgent(
      receivers,
      subject,
      body
    );
    messageObs.subscribe(response => {
      this.auth.showSnackbar('Message has been sent successfully', null, 3000);
      this.FormGroup.reset();
      this.receiver = [];
      this.check = false;
      this.data.sendMessage(response);
      this.spinner = false;
    }, error => {
      this.auth.showSnackbar(error, null, 3000);
      this.spinner = false;
    });
  }
}
