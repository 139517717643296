import { DataService } from '@services/data.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from '@pages/auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-send-single-message',
  templateUrl: './send-single-message.component.html',
  styleUrls: ['./send-single-message.component.scss']
})
export class SendSingleMessageComponent implements OnInit {

  sendMessageForm: FormGroup;
  is_client: boolean;
  role: any;
  receivers: { receiver_id: any; is_client: boolean; }[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SendSingleMessageComponent>,
    private auth: AuthService,
    private _formBuilder: FormBuilder,
    private dataService: DataService
  ) { }

  ngOnInit() {
    // if reply fill subject
    if (this.data.subject) {
      this.sendMessageForm = this._formBuilder.group({
        subject: ['re: ' + this.data.subject, Validators.required],
        body: ['', Validators.required],
      });
    } else {
      // creating form
      this.sendMessageForm = this._formBuilder.group({
        subject: ['', Validators.required],
        body: ['', Validators.required],
      });
    }
    this.getRole();
    localStorage.removeItem('agToCall');
  }

  getRole() {
    if (this.data.sender) {
      this.role = this.data.sender.data.object.toLowerCase();
    } else if (this.data.user.object) {
      this.role = this.data.user.object.toLowerCase();
    } else if (this.data.user) {
      this.role = this.data.user.data.object.toLowerCase();
    }

    switch (this.role) {
      case 'user':
        this.is_client = true;
        break;
      case 'agent':
        this.is_client = false;
        break;
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.data.sender) {
      this.receivers = [{ receiver_id: this.data.sender.data.id, is_client: this.is_client }]
    } else if (this.data.user.object) {
      this.receivers = [{ receiver_id: this.data.user.id, is_client: this.is_client }]
    } else if (this.data.user) {
      this.receivers = [{ receiver_id: this.data.user.data.id, is_client: this.is_client }]
    }
    const subject = this.sendMessageForm.value.subject;
    const body = this.sendMessageForm.value.body;
    let messageObs: Observable<any>;
    messageObs = this.auth.createMessageByAgent(
      this.receivers,
      subject,
      body
    );
    messageObs.subscribe(response => {
      this.dataService.sendMessage(response);
      this.auth.showSnackbar('Message has been sent successfully', null, 3000);
      this.sendMessageForm.reset();
      this.dialogRef.close();
    }, error => {
      this.auth.showSnackbar(error, null, 300000);
    });
  }
}
