import { CookieAcceptComponent } from './layout/cookie-accept/cookie-accept.component';
import { CommonService } from './shared/services/common.service';
import { DataService } from '@services/data.service';
import { AuthService } from '@pages/auth/auth.service';
import { UserCardComponent } from './pages/home/user-card/user-card.component';
import { BrowserLeaveDirective } from './shared/browser-leave.directive';
import { WebSocketService } from './shared/services/websocket.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, UrlTree, UrlSegmentGroup, PRIMARY_OUTLET, UrlSegment } from '@angular/router';
import { TwilioService } from '@services/twilio.service';
import { MatDialogRef, MatDialog } from '@angular/material';
import { SendSingleMessageComponent } from '@layout/send-single-message/send-single-message.component';
import { CookieService } from 'ngx-cookie-service';


// tslint:disable-next-line: ban-types
declare let ga: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [UserCardComponent]
})
export class AppComponent implements OnInit, OnDestroy {
  subscription1: any;

  @ViewChild(BrowserLeaveDirective, { static: false }) bld: BrowserLeaveDirective;
  @ViewChild('timer', { read: UserCardComponent, static: false })

  title = 'anythingxxx-fe';
  loading = false;
  mobile = false;
  accept = false;
  full: boolean;
  sid: string;
  callStarted = true;
  clientStarted: any;
  agentId: string;
  role: string;
  interval = null;
  client = {
    twilioRoomName: '',
    twilioToken: ''
  };

  agentCall = {
    twilioToken: '',
    twilioRoomName: ''
  };
  timeLeft: number = null;
  time: number;
  caller: any;
  audio: HTMLAudioElement;
  videoScreen: HTMLCollectionOf<HTMLVideoElement>;
  changeBTN: boolean = true;
  callerIdentity: any;
  subscription: any;
  agentAnswered = false;
  cancelBTN: boolean = true;
  max_call_duration: any;
  durationTimer: NodeJS.Timer;
  agentToCallRemote: any;
  dialogRef: MatDialogRef<SendSingleMessageComponent>;
  agent: any;
  isMobile = false;
  subscription2: any;
  cancelCallTimer: NodeJS.Timer;
  cookie: string;
  stream_call: boolean;
  missedCallEvent = false;
  missedCall: boolean;
  pageStatus = false;
  constructor(
    private router: Router,
    private twilio: TwilioService,
    private socketService: WebSocketService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private userCard: UserCardComponent,
    private data: DataService,
    public dialog: MatDialog,
    private agentConnectionService: CommonService,
    private cookieService: CookieService,
    private commonService: CommonService
  ) {
    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
        if (event.urlAfterRedirects == '/landing') {
          this.pageStatus = false;
        } else {
          this.pageStatus = true;
        }
      }

    });


  }



  getTime() {
    return Date();
  }
  onNotify(message: boolean): void {
    this.mobile = message;
  }

  switchFull() {
    this.full = !this.full;
  }

  getIsMobile(): boolean {
    const w = window.innerWidth;
    const breakpoint = 600;
    if (w > breakpoint) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.cookie = this.cookieService.get('acceptTerms');
    if (!this.cookie) {
      this.dialog.open(CookieAcceptComponent,
        {
          disableClose: true,
          panelClass: ['scroll'],
        });
    }
    this.agentProductListingCheck();
    // establish public channel listen for agent availability
    this.socketService.establishPublicConnection();
    // get status changes for agents in real time
    this.publicChanelStatusChange();
    // get listingtyoes and status when admin approves agent edit profile
    this.agentListingTypesChangedEvent();
    // create private connection to accept calls from clients
    this.makePrivateConnection();
    this.handleVideoCallEvents();

    // make private connection if app reloads
    this.connect();

    this.subscription2 = this.twilio.emitChange.subscribe(e => {
      this.caller = localStorage.getItem('caller');
      this.cancelBTN = false;
      if (e === null) {
        this.callStarted = false;
      } else {
        this.callStarted = true;
        // cancel call if agent not answering after 60 sec
        this.cancelCallTimer = setTimeout(() => {
          this.endCall('no-answer');
        }, 60000);
      }
      this.clientStarted = e;
    });
    this.check();
    // remote call setup, if client from other page
    this.getRemoteCaller();
    this.etsablishAliasConnection();
  }

  agentListingTypesChangedEvent() {
    this.socketService.subscribePublic(
      'public-channel',
      `\\App\\Containers\\Agent\\Events\\Events\\AgentListingTypesChangedEvent`,
      (event: any) => {
        this.agentConnectionService.sendMessage(event);
      }
    );
  }



  publicChanelStatusChange() {
    this.socketService.subscribePublic(
      'public-channel',
      `\\App\\Containers\\Agent\\Events\\Events\\AgentAvailabilityStatusChangedEvent`,
      (event: any) => {
        this.twilio.changeMessage(event),
          // this.agentAnswered = !this.agentAnswered;
          this.caller = localStorage.getItem('caller');
        // handle change availability
      });
  }

  // private connection
  makePrivateConnection() {
    // make private connection when user logs in
    this.subscription = this.twilio.getMessage().subscribe(res => {
      if (res.text === 'participantHere') {
        clearTimeout(this.cancelCallTimer);
      }
      if (res.text === 'loggedIn') {
        // this.agentAnswered = false;
        this.connect();
      }
      if (res.text === 'noVideo') {
        this.endCall('unknown');
      }
    });
  }

  handleVideoCallEvents() {
    this.twilio.getMessage().subscribe(res => {
      if (res.text === 'no-answer') {
        this.handleCallEnd();
      }
      // getting message when participant joins room
      if (res.text === 'participantHere') {
        this.caller = localStorage.getItem('caller');
        this.agentAnswered = true;
        this.cancelBTN = !this.cancelBTN;
        // call starts and we are setting time to autocomplete call wnen client runs
        // out of money
        if (this.max_call_duration > 0) {
          this.durationTimer = setTimeout(() => {
            this.endCall(status = 'completed');
            this.twilio.showSnackbar('You don\'t have time remaining, please add balance.', null, 5000);
          }, (this.max_call_duration - 16) * 1000);
          // clearTimeout(this.noAnswer);
        }
      } else {
        this.agentAnswered = false;
      }
      // getting message from user card component with max call dur info
      if (typeof res.text === 'number') {
        this.max_call_duration = res.text;
      }
    });
  }


  // find data for agent to call and make call automaticaly
  getRemoteCaller() {
    this.route.queryParams.subscribe(param => {

      const agentToCallRemote = param.id;
      const actionType = param.action;

      if (actionType === 'click2call') {

        if (agentToCallRemote) {
          // if client is not authenticated
          // set id to local storage in order to call after login
          localStorage.setItem('agToCall', param.id);
          // if caller doesn't call after half hour remove id from local storage
          setTimeout(() => {
            localStorage.removeItem('agToCall');
          }, 1800000);
          // get info about agent that client wants to call
          this.getAgentInfo(agentToCallRemote, actionType);
        }
      } else if (actionType === 'click2mail') {
        localStorage.setItem('agToCall', param.id);
        this.getAgentInfo(agentToCallRemote, actionType);
      } else if (actionType === "click2callCam") {
        localStorage.setItem('agToCall', param.id);
        this.getAgentInfo(agentToCallRemote, actionType);
      }
    });
  }

  openDialog(user: any): void {
    this.dialogRef = this.dialog.open(
      SendSingleMessageComponent,
      {
        data: { user },
        panelClass: ['no-padding', 'no-scrolls'],
      }
    );
  }

  private getAgentInfo(id: any, action: string) {
    this.data.getAgentInfo(id).subscribe(res => {
      this.agent = res.data;
      // initiate call if data is correct
      // error handled in user card component
      if (action === 'click2call') {
        this.userCard.callAgentAudio(
          this.agent.id,
          this.agent.user_name,
          this.agent.audio_call_price,
          event
        );
      } else if (action === 'click2mail') {
        this.auth.getAuthenticatedUser().subscribe(() => {
          this.openDialog(this.agent);
        }, error => {
          this.router.navigate(['auth/login'], { queryParams: { wantsToSendMessage: true } });
          this.twilio.showSnackbar('Please login or signup to send message', null, 3000);
        });
      } else if (action === 'click2callCam') {
        this.userCard.callAgent(
          this.agent.id,
          this.agent.user_name,
          this.agent.audio_call_price,
          event
        );
      }
    });
  }


  agentProductListingCheck() {
    const role = localStorage.getItem('role');
    if (role === 'agent') {
      this.auth.getAgentInfo().subscribe(res => {
        this.auth.setApprovalStatus(res.data.agent.data.approval_status);
        this.auth.getProductAsListingType(res);
        this.auth.getBankAccountStatus(res);
        this.auth.getEditProfileStatus(res);
      });
    }
  }

  // controls for video

  toogleControls() {
    const video: any = document.getElementsByTagName('video')[0];
    if (video.hasAttribute('controls')) {
      video.removeAttribute('controls');
    } else {
      video.setAttribute('controls', 'controls');
    }
  }

  // CONNECTIONS

  // method for establishing connections on private channels
  connect() {
    this.caller = localStorage.getItem('caller');
    this.role = localStorage.getItem('role');
    // establishing connection with socket service, private channels for client and agent
    if (this.role === 'client') {
      this.socketService.establishConnection();
      if (this.socketService.isConnected) {
        this.subscribeToClientChannel();
      } else {
        this.socketService.connectionEstablished.subscribe(this.subscribeToClientChannel.bind(this));
      }
    }

    if (this.role === 'agent') {
      // establish connection if user is agent
      this.socketService.establishConnection();
      if (this.socketService.isConnected) {
        this.subscribeToAgentChannel();
      } else {
        this.socketService.connectionEstablished.subscribe(this.subscribeToAgentChannel.bind(this));
      }
    }
  }


  check() {

    this.twilio.connectionEstablished.subscribe((res: any) => {
      if (res) {
        const id = localStorage.getItem('id');
        this.twilio.getTimeForCall(id).subscribe(response => {
          this.timeLeft = response.max_call_duration;
        });
        this.changeBTN = false;
        this.connect();
      }
    });
  }



  // CALLS

  // agent accepting call
  acceptCall() {
    this.twilio.agentCallTwilio(this.agentCall, this.stream_call);
    this.accept = false;
    this.callStarted = true;
    this.twilio.getTimeForCall(this.agentId).subscribe(res => {
      const time = res.max_call_duration;
    });
  }

  // ending call

  endCall(status?: string) {
    this.getTime();
    const sid = this.twilio.roomSid;
    this.twilio.endCall(sid || this.sid, status).subscribe(res => {
    });
    this.handleCallEnd();
    if (this.cancelCallTimer) {
      clearTimeout(this.cancelCallTimer);
    }
  }

  handleCallEnd() {
    this.accept = false;
    this.callStarted = false;
    localStorage.removeItem('sid');
    localStorage.removeItem('caller');
    clearInterval(this.interval);
    this.interval = 0;
    // clearInterval(this.timerForAgent);
    clearInterval(this.durationTimer);
    // this.agentTimer = 0;
  }

  notifyNewMessage() {
    const audio2 = new Audio;
    audio2.src = '../assets/eventually.mp3';
    audio2.load();
    audio2.play();
  }


  etsablishAliasConnection() {
    this.subscription1 = this.agentConnectionService.getMessage().subscribe(res => {
      this.subscribeToAgentChannel();
    });
  }


  subscribeToAgentChannel() {
    this.agentId = localStorage.getItem('id');
    const channel = `agent-channel.${this.agentId}`;
    localStorage.setItem('ch', channel);
    const socket = this.socketService.subscribe(channel, '\\App\\Containers\\VideoCall\\Events\\Events\\RoomCreatedEvent',
      (e: { token: string; roomName: string; clientIdentity: any; roomSid: string; streamCall: boolean }) => {
        const caller = localStorage.getItem('caller');

        // console.log('agent room created', this.getTime());
        this.agentCall.twilioToken = e.token;
        this.agentCall.twilioRoomName = e.roomName;
        this.stream_call = e.streamCall;
        this.callerIdentity = e.clientIdentity;
        this.accept = true;
        this.sid = e.roomSid;
        localStorage.setItem('sid', this.sid);
      });

    this.missedCall = true;
    this.socketService.subscribe(channel, '\\App\\Containers\\VideoCall\\Events\\Events\\RoomCompletedEvent',
      (e: { callStatus: string; }) => {
        if (e.callStatus === 'canceled' || e.callStatus === 'no-answer'  && this.missedCall) {
          this.missedCall = false;
          // this.missedCallEvent = !this.missedCallEvent;
          // this.auth.logoutReq().subscribe();
          // this.auth.logout();
          // this.router.navigate(['../home']);
          this.twilio.showSnackbar('You have a missed call, please check your inbox', null, 3000);
        } else if (e.callStatus !== 'canceled') {
          this.twilio.showSnackbar('Call ' + e.callStatus, null, 3000);
          this.showStatus(e);
        }
        this.accept = false;
        this.callStarted = false;
        localStorage.removeItem('sid');
        localStorage.removeItem('caller');

        // console.log('AGENT EVENT ROOM CLOSED', e);
      });



    // EMAIL LISTENER

    this.socketService.subscribe(channel, `\\App\\Containers\\Message\\Events\\Events\\MessageReceivedEvent`,
      (event: { unreadMessages: any; }) => {
        this.notifyNewMessage();
        this.auth.sendUnread(event.unreadMessages);
      });

    /////////////////////////////////////////////////////////////////////
    // this.missedCall = true;
    // this.socketService.subscribe(channel, `\\App\\Containers\\Agent\\Events\\Events\\LogoutAgentAfterMissedCallEvent`,
    //   (event: any) => {
    //     if (event && this.missedCall) {
    //       this.missedCallEvent = !this.missedCallEvent;
    //       this.auth.logoutReq().subscribe();
    //       this.auth.logout();
    //       this.router.navigate(['../home']);
    //       this.twilio.showSnackbar('You have a missed call, please check your inbox', null, 3000);
    //     }
    //   });
  }



  subscribeToClientChannel() {
    const clientId = localStorage.getItem('id');
    const channel = `client-channel.${clientId}`;
    localStorage.setItem('ch', channel);

    this.socketService.subscribe(channel, '\\App\\Containers\\VideoCall\\Events\\Events\\RoomCompletedEvent', (e: any) => {
      clearTimeout(this.durationTimer);
      // console.log('client room completed ', this.getTime());
      clearTimeout(this.cancelCallTimer);
      // console.log('CLIENT EVENT ROOM CLOSED', e);
      this.showStatus(e);
      this.accept = false;
      localStorage.removeItem('sid');
      localStorage.removeItem('caller');
      this.callStarted = false;
    });


    // email listener
    this.socketService.subscribe(channel, `\\App\\Containers\\Message\\Events\\Events\\MessageReceivedEvent`,
      (event: { unreadMessages: any; }) => {
        this.notifyNewMessage();
        this.auth.sendUnread(event.unreadMessages);
      });
  }

  // show status when call ends

  showStatus(event: { callStatus: string; }) {
    const status = event.callStatus;
    switch (status) {
      case 'no-answer':
        this.twilio.showSnackbar('No answer', null, 3000);
        break;
      case 'rejected':
      case 'completed':
        this.twilio.showSnackbar('Call ' + event.callStatus, null, 3000);
        break;
    }
  }

  // scroll to top on every page load
  onActivate(event: any) {
    window.scroll(0, 0);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
  }
}
