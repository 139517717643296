import { DataService } from './../../shared/services/data.service';
import { AuthService } from '@pages/auth/auth.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  SimpleChanges,
  OnChanges
} from '@angular/core';

@Component({
  selector: 'app-agent-profile-header',
  templateUrl: './agent-profile-header.component.html',
  styleUrls: ['./agent-profile-header.component.scss']
})
export class AgentProfileHeaderComponent implements OnInit, OnChanges {
  @Input() selected;
  @Input() agentData: any;
  @Input() changeAvailability: any;
  @Input() profileImageChange;
  @Output() emitAgent = new EventEmitter<any>();
  @Input() numberDeleted;
  @ViewChild('select', { static: false }) statusSelect: ElementRef;
  phoneNumbers: any;
  preselected: any;
  balance: any;
  status: any;
  listings: any;

  constructor(
    private authService: AuthService,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.agentInfoAction();
  }

  // get new agent data to change phopne numbers in list
  ngOnChanges(): void {
    if (this.numberDeleted) {
      this.agentInfoAction();
    }
    if (this.profileImageChange) {
      this.agentData.agent.data.profile_image = this.profileImageChange;
    }
  }

  // get aget data
  agentInfoAction() {
    this.authService.getAgentInfo().subscribe(res => {
      if (res) {
        this.agentData = res.data;
        this.phoneNumbers = res.data.phoneNumbers.data;
        this.preselected = this.phoneNumbers.find(n => n.is_primary === 1);
        if (this.preselected) {
          this.selected = this.preselected.phone_number;
        }
        this.balance = this.agentData.balance;
        this.emitAgent.emit(this.agentData);
      }
      this.listings = this.agentData.agent.data.listingTypes.data;
    });
  }


  changePrimaryNumber(id) {
    this.authService.setAsPrimaryNumber(id).subscribe(res => {
      this.authService.showSnackbar('Success', null, 3000);
      this.emitAgent.emit(res);
    }, err => console.error(err));
  }


  changeAvailabilityStatus(value) {
    this.dataService.changeAvailabilityStatus(value).subscribe(res => {
      this.dataService.showSnackbar('Success', null, 3000);
    }, error => {
      this.dataService.showSnackbar(error, null, 3000);
    });
  }

  setAllAliasProfilesAvailable() {
    this.dataService.setAllAliasProfilesAvailable().subscribe(res => {
      const res1 = res.data[0];
      const res2 = res.data[1];
      const message1 = res1.user_name + ' ' + res1.message;
      let message2;
      if (res2 === undefined) {
        message2 = '';
      } else {
        message2 = res2.user_name + ' ' + res2.message;
      }
      this.dataService.showSnackbar(message1 + ' ' + message2, null, 3000);
    }, error => {
      console.error(error);
    });
  }
}
