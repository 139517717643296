import { AgentsService } from './shared/services/agents.service';
import { EditProfileGuard } from './shared/guards/edit-profile.guard';
import { PaymentGuard } from './shared/guards/payment.guard';
import { ProductGuard } from './shared/guards/product.guard';
import { WebSocketService } from './shared/services/websocket.service';
import { AgentGuard } from './shared/guards/agent.guard';
import { ShareModule } from './shared/modules/share.module';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from '@layout/layout.module';
import { CoreModule } from '@modules/core.module';
import { HomeModule } from './pages/home/home.module';

import { AppComponent } from './app.component';
import { HelpPageComponent } from './pages/help-page/help-page.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RegisterConfirmComponent } from './register-confirm/register-confirm.component';
import { AuthInterceptor } from '@services/auth.interceptor';
import { AgentSetupGuard } from './shared/guards/agent-setup.guard';
import { ClientGuard } from './shared/guards/client.guard';
import { BrowserLeaveDirective } from './shared/browser-leave.directive';
import { NewAffiliateComponent } from './new-affiliate/new-affiliate.component';
import { DatePipe } from '@angular/common';
import { MoveNextByMaxLengthDirective } from './shared/move-next-by-max-length.directive';
import { CookieService } from 'ngx-cookie-service';
import { BlogService } from '@services/blog.service';
import { ApprovedAgentGuard } from './shared/guards/approved-agent.guard';
@NgModule({
  declarations: [
    AppComponent,
    HelpPageComponent,
    RegisterConfirmComponent,
    BrowserLeaveDirective,
    NewAffiliateComponent,
    MoveNextByMaxLengthDirective

  ],
  imports: [
    CoreModule,
    AppRoutingModule,
    LayoutModule,
    HomeModule,
    HttpClientModule,
    ShareModule,
  
  ],
  providers: [
    ClientGuard,
    AgentGuard,
    AgentSetupGuard,
    DatePipe,
    ProductGuard,
    PaymentGuard,
    EditProfileGuard,
    AgentsService,
    ApprovedAgentGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    WebSocketService,
    CookieService,
    BlogService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
