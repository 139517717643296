import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material';


export interface Responsedata {
  object: string;
  id: string;
  name: string;
  nicename: string;
  phonecode: number;
}
@Injectable({
  providedIn: 'root'
})


export class GetData {

  url = environment.baseUrl;
  countries: any;

  constructor(
    public http: HttpClient,
    private snackbar: MatSnackBar
  ) { }

  getData(_link) {
    return this.http.get(_link);
  }


  postImage(url, data) {
    return this.http.post(url, data)
  }

  getCountryCodes() {
    return this.http.get<any>(`${this.url}/country-codes`).pipe(
      catchError(this.handleError)
    )
  }

  getCountryStates(countryId: string) {
    return this.http.get(`${this.url}/countries/${countryId}?include=states`).pipe(
      catchError(this.handleError)
    );
  }

  getCities(stateId: string) {
    return this.http.get(`${this.url}/states/${stateId}?include=cities`).pipe(
      catchError(this.handleError)
    );
  }

  getAllCategories() {
    return this.http.get<any>(`${this.url}/categories?include=tags.subtags`).pipe(
      catchError(this.handleError)
    );
  }


  getSubCategory(categoryId: string) {
    return this.http.get(`${this.url}/categories/${categoryId}?include=tags`).pipe(
      catchError(this.handleError)
    );
  }

  getVideoCallFee() {
    return this.http.get<any>(`${this.url}/global-variables?search=key:video_call_commission`).pipe(
      catchError(this.handleError)
    );
  }
  getAudioCallFee() {
    return this.http.get<any>(`${this.url}/global-variables?search=key:voice_call_commission`).pipe(
      catchError(this.handleError)
    );
  }
  getProductFee() {
    return this.http.get<any>(`${this.url}/global-variables?search=key:product_commission`).pipe(
      catchError(this.handleError)
    );
  }
  getAgents() {
    return this.http.get(`${this.url}/agents?include=user`).pipe(
      catchError(this.handleError)
    );
  }
  getAgent(agentId): any {
    return this.http.get(`
    ${this.url}/agents/${agentId}?include=tags,user,galleryImages,products.productFiles.files,feedbacks.author,bids,listingTypes`).pipe(
      catchError(this.handleError)
    );
  }
  getAllAgentFeedbacks(id, sort) {
    const url1 = `/feedbacks/agents/${id}?orderBy=created_at&sortedBy=${sort}&include=author`
    return this.http.get<any>(`${this.url}${url1}`).pipe(
      catchError(this.handleError)
    );
  }
  getAllProductFeedbacks(id, sort) {
    const url1 = `/feedbacks/products/${id}?orderBy=created_at&sortedBy=${sort}&include=author`
    return this.http.get<any>(`${this.url}${url1}`).pipe(
      catchError(this.handleError)
    );
  }
  getFeedbacksFromUrl(url) {
    return this.http.get<any>(`${url}`).pipe(
      catchError(this.handleError)
    );
  }

  getTrainingVideos() {
    return this.http.get<any>(`${this.url}/training-videos`).pipe(
      catchError(this.handleError)
    );
  }

  // delete if not used
  getProductFeedbacks(agentId): any {
    return this.http.get(`${this.url}/agents/${agentId}?include=products.feedbacks.author.products`).pipe(
      catchError(this.handleError)
    );
  }
  getClientsFeedbacksToAgent(id, author_type, feedbackable_type, include) {
    return this.http.get<any>(`
    ${this.url}/feedbacks/authors/${id}?author_type=${author_type}&feedbackable_type=${feedbackable_type}${include}`
    ).pipe(
      catchError(this.handleError)
    );
  }

  gatAllFeedbacksByAgent(id, feedbackable_type, include): any {
    return this.http.get(`${this.url}/feedbacks/agents/${id}?feedbackable_type=${feedbackable_type}${include}`).pipe(
      catchError(this.handleError)
    );
  }


  getAllFeedbacksByFeedbackableModel(feedbackable_id, feedbackable_type, include?, limit?): any {
    return this.http.get(`${this.url}/feedbacks?feedbackable_id=${feedbackable_id}&feedbackable_type=${feedbackable_type}&include=author${include}${limit ? '&limit=' + limit : '&limit=10'}`).pipe(
      catchError(this.handleError)
    );
  }


  searchUsersByUserName(name, type) {
    return this.http.get<any>(`${this.url}/users/messages?user_name=${name}&type=${type}`).pipe(
      catchError(this.handleError)
    );
  }

  searchOnClick(gender, sort) {
    return this.http.get<any>(`${this.url}/agents/status/approved?include=bids,listingTypes&search=gender:${gender}&sortBy=${sort}&limit=50`).pipe(
      catchError(this.handleError)
    );
  }
  sortBy(input, gender) {
    return this.http.get<any>(`${this.url}/agents/status/approved?include=bids,listingTypes&search=gender:${gender}&sortBy=${input}&limit=50`).pipe(
      catchError(this.handleError)
    );
  }
  sortNoGender(input) {
    return this.http.get(`${this.url}/agents/status/approved?include=bids,listingTypes&sortBy=${input}&limit=50`).pipe(
      catchError(this.handleError)
    );
  }


  // getProductId(agentId) {
  //   return this.http.get(`${this.url}/products/${agentId}?include=feedbacks.author`).pipe(
  //     catchError(this.handleError)
  //   );
  // }
  getCategoryTicket() {
    return this.http.get(`${this.url}/ticket-categories`).pipe(
      catchError(this.handleError)
    );
  }

  // remote call setup

  agentRemoteCallSetup(): any {
    return this.http.get(`${this.url}/agents/remote-calls`).pipe(
      catchError(this.handleError)
    );
  }

  downloadPlugin() {
    return this.http.get(`${this.url}/agents/remote-calls/plugins/download`, { responseType: 'arraybuffer' }).pipe(
      catchError(this.handleError)
    );
  }

  // footer data

  getAllContents(): any {
    return this.http.get(`${this.url}/page-contents?filter=id;title`).pipe(
      catchError(this.handleError)
    );
  }

  getFooterBanner(): any {
    return this.http.get(`${this.url}/banners?search=type:advertising`).pipe(
      catchError(this.handleError)
    );
  }

  getContentById(id): any {
    return this.http.get(`${this.url}/page-contents/${id}?include=trainingVideos`).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    switch (error.status) {
      case 500:
        return throwError(error.status);
    }

    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    return throwError('Something bad happened. Please try again later.');
  }

  // show message to user when error occures
  showSnackbar(message, action, duration) {
    const actionButtonLabel = 'Close';
    action = true;
    duration = 1000000;
    this.snackbar.open(message, action ? actionButtonLabel : undefined,
      {
        duration,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['custom-snackbar']
      });
  }

}




