import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@pages/auth/auth.service';


@Injectable()
export class AgentSetupGuard implements CanActivate {
  constructor(private authService: AuthService,
    private router: Router) {
  }

  canActivate(): any {
    const agentInfo = localStorage.getItem('status');
    if (agentInfo === 'registered') {
      return true;
    } else {
      this.router.navigate(['agent/agent-profile']);
      return false;
    }
  }
}
