import { MatSnackBar } from '@angular/material';
import { environment } from 'src/environments/environment.prod';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, BehaviorSubject, Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TwilioService {

  url = environment.baseUrl;
  roomSid: string;
  agentName: string;
  clientName: any;
  window: boolean;
  callEnded: Subject<any> = new BehaviorSubject<any>(null);
  connectionEstablished: EventEmitter<any> = new EventEmitter();
  private messageSource = new BehaviorSubject(null);
  currentMessage = this.messageSource.asObservable();
  private subject = new Subject<any>();
  sid: any;
  timer: NodeJS.Timer;

  constructor(
    private http: HttpClient,
    private snackbar: MatSnackBar
  ) { }


  sendMessage(message: string) {
    this.subject.next({ text: message });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  changeMessage(message: any) {
    this.messageSource.next(message);
  }

  emit(value: any) {
    this.callEnded.next(value);
  }
  get emitChange(): BehaviorSubject<any> {
    return (this.callEnded as BehaviorSubject<any>);
  }

  callAgent(agent_id, stream_call) {
    return this.http.post<any>(`${this.url}/video-calls`, { agent_id, stream_call })
  }

  clientCallTwilio(client, type) { 
    const { connect, createLocalTracks } = require('twilio-video');
    this.roomSid = client.roomSid;
    localStorage.setItem('sid', this.roomSid);

    navigator.mediaDevices.enumerateDevices().then(devices => {
      const videoInput = devices.find(device => device.kind === 'videoinput');
      // if (!type) {
      //   console.log('true')
      return createLocalTracks(
        {
          audio: true,
          video: { deviceId: videoInput.deviceId }
        });
      // } else {
      // console.log('false')
      // return createLocalTracks(
      //   {
      //     audio: true,
      //     video: false
      //   });
      // }
    }).then(localTracks => {
      return connect(
        client.twilioToken,
        {
          name: client.twilioRoomName,
          tracks: localTracks,
          preferredVideoCodecs: ['H264', 'VP8']
        });
    }).then(room => {
      const sid = room.sid;
      this.sid = sid;
      this.getSid(sid);
      //   console.log('seed ' + this.roomSid);

      //    console.log(`A CLIENT connected to room ${room}`);

      //    console.log('room', room);
      const agentId = localStorage.getItem('id');
      room.on('participantConnected', participant => {
        //  console.log(`Agent that is connected: ${participant}`);
        clearTimeout(this.timer);
        this.sendMessage('participantHere');
 
        participant.tracks.forEach(publication => {
          if (publication.isSubscribed) {
            const track = publication.track;
            const mediaDiv = document.getElementById('remote-media-div');
            mediaDiv.appendChild(track.attach());
          }
        });

        participant.on('trackSubscribed', track => {
          document.getElementById('remote-media-div').appendChild(track.attach());
        });
        // if (!type) {
        //   this.agentPreview();
        // }
        participant.on('trackUnsubscribed', this.trackUnsubscribed);
        // participant.on('trackUnsubscribed', this.endCall(room.sid));

        room.on('participantDisconnected', this.participantDisconnected);

        room.on('trackPublicationFailed', (error, localTrack) => {
          //   console.log('Failed to publish LocalTrack %s:', localTrack.id)
          //   console.log(error.message)
          // => Failed to publish LocalTrack XXX:
          // => No supported codec
        });
        // room.on('participantDisconnected', this.endCall(room.sid));
        room.once('disconnected', error => room.participants.forEach(this.participantDisconnected));


      });
    }, error => {
      this.endCall(this.roomSid, 'canceled');
      console.error(`Unable to connect to Room: ${error.message}`);
    });
  }

  agentPreview() {
    const { createLocalVideoTrack } = require('twilio-video');
    createLocalVideoTrack().then(track => {
      const localMediaContainer = document.getElementById('local-media');
      localMediaContainer.appendChild(track.attach());
    });
  }
  agentCallTwilio(agent, stream_call) {

    const { connect, createLocalTracks } = require('twilio-video');

    navigator.mediaDevices.enumerateDevices().then(devices => {
      const videoInput = devices.find(device => device.kind === 'videoinput');
      if (videoInput) {
        return createLocalTracks({ audio: true, video: { deviceId: videoInput.deviceId } });
      } else {
        this.sendMessage('noVideo');
        this.showSnackbar('No video device provided', null, 3000);
      }
    }).then(localTracks => {
      return connect(agent.twilioToken,
        {
          name: agent.twilioRoomName,
          tracks: localTracks,
          preferredVideoCodecs: ['H264', 'VP8']
        });
    }).then(room => {
      //  console.log('Connected to room ' + room.name);
      //  console.log('room ' + room);
      room.participants.forEach(participant => { 
        participant.tracks.forEach(publication => {

          if (publication.isSubscribed) {
            const track = publication.track;
            document.getElementById('remote-media-div').appendChild(track.attach());
          }
        });
        // if (stream_call) {
        // this.agentPreview();
        // } else {
        participant.on('trackSubscribed', track => {
          if (!stream_call) {
            document.getElementById('remote-media-div').appendChild(track.attach());
          } else if (stream_call && track.kind === 'audio') {
            this.agentPreview();
            document.getElementById('remote-media-div').appendChild(track.attach());
          }
        });
        // }

        participant.on('trackUnsubscribed', this.trackUnsubscribed);

        room.on('participantDisconnected', this.participantDisconnected);

        room.once('disconnected', error => room.participants.forEach(this.participantDisconnected));
      });
    });
  }

  endCall(sid, status?) {
    return this.http.post<any>(`${this.url}/video-calls/rooms/${sid}`, { status });
  }

  participantDisconnected(participant, event: Event) {
    //   console.log('Participant "%s" disconnected', participant.identity);
    //  console.log(22222222222222, event);

  }


  trackUnsubscribed(track) {
    track.detach();
    track.detach().forEach(element => element.remove());
  }

  getSid(sid?) {
    return sid;
  }

  getCallEnded() {
    return this.callEnded;
  }

  getTimeForCall(id) {
    return this.http.get<any>(`${this.url}/agents/${id}/calls/duration?type=video`, {}).pipe(
      catchError(this.handleError),
    );
  }

  getTimeForAudioCall(id) {
    return this.http.get<any>(`${this.url}/agents/${id}/calls/duration?type=voice`, {}).pipe(
      catchError(this.handleError),
    );
  }

  showSnackbar(message, action, duration) {
    const actionButtonLabel = 'Close';
    action = true;
    duration = 1000000;
    this.snackbar.open(message, action ? actionButtonLabel : undefined,
      {
        duration,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['custom-snackbar']
      });
  }

  getStart(message) {
    return message;
  }

  callAgentAudio(agent_id) {
    return this.http.post<any>(`${this.url}/voice-calls`, { agent_id });
  }

  handleError(error: HttpErrorResponse) {
    switch (error.status) {
      case 400:
      case 401:
      case 403:
      case 404:
      case 417:
        return throwError(error.error.message);
    }

    switch (error.error.status_code) {
      case 401:
      case 403:
      case 404:
      case 409:
        return throwError(error.error.message || 'Server error');
      case 422:
        if (error.error.errors) {
          if (error.error.errors.email) {
            return throwError(error.error.errors.email[0]);
          }
          if (error.error.errors.password) {
            return throwError(error.error.errors.password[0]);
          }
          if (error.error.errors.user_name) {
            return throwError(error.error.errors.user_name[0]);
          }
          if (error.error.errors.description) {
            return throwError(error.error.errors.description[0]);
          }
          if (error.error.errors.service_title) {
            return throwError(error.error.errors.service_title[0]);
          }
          if (error.error.errors.listing_types) {
            return throwError(error.error.errors.listing_types[0]);
          }
          if (error.error.errors.birth_date) {
            return throwError(error.error.errors.birth_date[0]);
          }
          if (error.error.errors.rating) {
            return throwError(error.error.errors.rating[0]);
          }
          if (error.error.errors.comment) {
            return throwError(error.error.errors.comment[0]);
          }
          if (error.error.errors.amount) {
            return throwError(error.error.errors.amount[0]);
          }
          if (error.error.errors.gender_availability) {
            return throwError('Gender field is required!');
          }
          if (error.error.errors.keywords) {
            return throwError(error.error.errors.keywords[0]);
          }
          if (error.error.errors.price) {
            return throwError(error.error.errors.price[0]);
          }
          if (error.error.errors.primary_file) {
            return throwError('Product field is required!');
          }
        } else {
          if (error.error.status_code === 422) {
            return throwError(error.error.message);
          }
          // return throwError(error.error || error.error.message)
        }
    }
  }

}


