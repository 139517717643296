import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objFilter'
})
export class ObjectFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, filters: any[]): any[] {
    if (!items) { return []; }
    if (!searchText) { return items; }

    searchText = searchText.toLowerCase();

    const filteredItems = [];

    items.forEach(item => {
      filters.forEach(filter => {
        if (item[filter] !== null && item[filter].toLowerCase().includes(searchText)) {
          filteredItems.push(item);
        }
        if (filteredItems.length === 0) {

        }
      });
    });
    return filteredItems;
  }
}
