import { AuthService } from '@pages/auth/auth.service';
import { Subscription } from 'rxjs';
import { SendSingleMessageComponent } from './../send-single-message/send-single-message.component';
import { ViewSingleMessageComponent } from './../view-single-message/view-single-message.component';
import { DataService } from '@services/data.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Overlay } from '@angular/cdk/overlay';

@Component({
  selector: 'app-inbox-messages',
  templateUrl: './inbox-messages.component.html',
  styleUrls: ['./inbox-messages.component.scss']
})
export class InboxMessagesComponent implements OnInit, OnDestroy {
  messages: any = [];
  dialogRef: MatDialogRef<ViewSingleMessageComponent>;
  dialogRefSend: MatDialogRef<SendSingleMessageComponent>;
  // unreadMessageRes: boolean;
  msg: any;
  condition: boolean = true;
  pagination: any;
  pagesSubscription: Subscription;
  msgNotifySub: Subscription;
  role: string;

  constructor(
    private dataService: DataService,
    public dialog: MatDialog,
    private auth: AuthService,
    private overlay: Overlay
  ) { }

  ngOnInit() {
    this.getUserReceivedMessages();
    this.getMSGonNotify();
    this.role = localStorage.getItem('role');
  }

  // getting all mesages from user
  getUserReceivedMessages(limit?) {
    const msgDirection = 'receiver';
    const include = 'sender';
    this.dataService.GetAllMessagesByUser(msgDirection, include, limit).subscribe(res => {
      this.messages = res.data.filter(msg => msg.is_blocked === 0);
      this.pagination = res.meta.pagination;
    }, error => {
      console.error(error);
    });
  }

  getMSGonNotify() {
    this.msgNotifySub = this.auth.getUnread().subscribe(res => {
      if (res) {
        this.getUserReceivedMessages();
      }
    });
  }

  // getting pages from pagination links
  getPages($event) {
    this.pagesSubscription = this.dataService.getData($event).subscribe(res => {
      this.messages = res.data;
      this.pagination = res.meta.pagination;
    });
  }


  // opening dialog to view message
  openDialog(id): void {
    this.dialogRef = this.dialog.open(
      ViewSingleMessageComponent, 
      {
        data: {
          message: this.messages.find(msg => msg.id === id),
          type: 'inbox'
        },
        panelClass: ['no-padding', 'no-scrolls'],
        scrollStrategy: this.overlay.scrollStrategies.noop()
      }
    );
    this.dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.sendMessage(res.id)
      }
    })
    this.openMessage(id);
  }



  // opening message
  openMessage(id) {
    this.msg = this.messages.find(msg => {
      return msg.id === id;
    });


    // if message is unread from BE send request to mark it opened

    const unreadMessage = this.msg.status === 'unread';
    if (unreadMessage) {
      this.dataService.findMessageById(this.msg.id).subscribe(res => {
        this.condition = false;
        this.msg.status = 'read';
      });
    }
  }

  sendMessage(id): void {
    this.dialogRefSend = this.dialog.open(
      SendSingleMessageComponent,
      {
        data: this.messages.find(msg => msg.id === id),
        panelClass: ['message', 'no-scrolls'],
        scrollStrategy: this.overlay.scrollStrategies.noop()
      }
    );
  }

  deleteMessage(id) {
    this.dataService.deleteMessage(id).subscribe(res => {
      this.getUserReceivedMessages();
      this.dataService.showSnackbar('Success', null, 3000);
    }, error => {
      console.error(error);
    });
  }

  ngOnDestroy(): void {
    if (this.pagesSubscription) {
      this.pagesSubscription.unsubscribe();
    }
    if (this.msgNotifySub) {
      this.msgNotifySub.unsubscribe();
    }
  }
  loadWithLimit(limit) {
    this.getUserReceivedMessages(limit);
  }
}
