import { Subscription } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DataService } from '@services/data.service';

@Component({
  selector: 'app-public-feedback',
  templateUrl: './public-feedback.component.html',
  styleUrls: ['./public-feedback.component.scss']
})
export class PublicFeedbackComponent implements OnInit, OnDestroy {

  @Input() feedbacks;
  @Input() pagination;
  pagesSubscription: Subscription;
  constructor(
    private dataService: DataService
  ) { }

  ngOnInit() {
  }

  getPages($event) {
    this.pagesSubscription = this.dataService.getData($event).subscribe(res => {
      this.feedbacks = res.data;
      this.pagination = res.meta.pagination;
    });
  }
  ngOnDestroy() {
    if (this.pagesSubscription) {
      this.pagesSubscription.unsubscribe();
    }
  }
}
