import { map } from 'rxjs/operators';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'select'
})
export class SelectPipe implements PipeTransform {

  transform(items: any, args?: any): any {
    if (items === undefined) return items;

    return items.map((item) => {
      return item.user.data.gender.includes(args);

    })
    // console.log(11111, items[0].user.data.gender)
    // return items;

  }
}
