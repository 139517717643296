import { Router } from '@angular/router';
import { AuthService } from '@pages/auth/auth.service';
import { Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const idToken = localStorage.getItem('id_token');
    if (idToken) {
      const headers = new HttpHeaders({
        Authorization: 'Bearer ' + idToken,
        'X-Timezone-Offset': this.getTimezoneOffset()
      });
      const cloned = req.clone({
        // headers: req.headers.set('Authorization', 'Bearer ' + idToken)
        headers
      });
      return next.handle(cloned).pipe(tap(() => { },
        (err: any) => {
          if (err.status !== 401) {
            return;
          }
          this.auth.logout();
          localStorage.setItem('expired', 'expired');
          this.router.navigate(['../login']);
        }
      ))
    } else {
      return next.handle(req);
    }
  }
  getTimezoneOffset() {
    return( String( new Date().getTimezoneOffset() ) );
  }
}
