import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DataService } from '@services/data.service';

@Component({
  selector: 'app-report-delete-chat',
  templateUrl: './report-delete-chat.component.html',
  styleUrls: ['./report-delete-chat.component.scss']
})
export class ReportDeleteChatComponent implements OnInit {
  comment: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReportDeleteChatComponent>,
    private dataService: DataService
  ) { }

  ngOnInit() {
    console.log(this.data)
  }
  submitReport() {
    const data = {
      chat_id: this.data.message.id,
      comment: this.comment
    };
    this.dataService.reportChatMessage(data).subscribe(res => {
      if (res) {
        this.dialogRef.close();
        this.dataService.showSnackbar('Thank you for reporting, report sent successfully to admin.', null, 5000);
      }
    }, err => {
      this.dataService.showSnackbar(err, null, 3000);
    });
  }
}
