import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SendSingleMessageComponent } from '@layout/send-single-message/send-single-message.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-cookie-accept',
  templateUrl: './cookie-accept.component.html',
  styleUrls: ['./cookie-accept.component.scss']
})
export class CookieAcceptComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SendSingleMessageComponent>,
    private cookieService: CookieService,
    private location: Location
  ) { }

  ngOnInit() {
  }
  reject() {
    this.location.back();
  }

  acceptTerms() {
    let date = new Date();
    let newDate = new Date(date.setMonth(date.getMonth()+6));
    this.cookieService.set('acceptTerms', 'true', newDate);
    this.dialogRef.close();
  }

}
