import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { AuthService } from '@pages/auth/auth.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Location } from '@angular/common';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit {

  updatePassword: FormGroup;
  confirmation: boolean = false;
  @ViewChild('password', { static: false }) input: ElementRef;
  @ViewChild('password_confirmation', { static: false }) input2: ElementRef;
  @Input() agentInfo;

  constructor(
    private auth: AuthService,
    private location: Location
  ) { }

  ngOnInit() {
    this.updatePassword = new FormGroup({
      'password': new FormControl('', [Validators.min(6), Validators.required]),
      'password_confirmation': new FormControl('', [Validators.min(6), Validators.required])
    });
  }

  onFocusOut() {
    if (this.input.nativeElement.value !== this.input2.nativeElement.value) {
      this.confirmation = true;
    } else {
      this.confirmation = false;
    }
  }

  idSelector() {
    if (this.agentInfo) {
      return this.agentInfo.id;
    } else {
      return localStorage.getItem('id');
    }
  }

  onSubmit() {
    const password = this.updatePassword.value.password;
    const password_confirmation = this.updatePassword.value.password_confirmation;
    const id = this.idSelector();
    this.auth.userChangePassword(
      password, password_confirmation, id
    ).subscribe(res => {
      this.location.back();
      this.auth.showSnackbar('Password changed', null, 3000);
    }, error => {
      this.auth.showSnackbar(error, null, 3000);
    });
  }
}
