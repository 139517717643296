import { AuthService } from '@pages/auth/auth.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-product-thumb',
  templateUrl: './product-thumb.component.html',
  styleUrls: ['./product-thumb.component.scss']
})
export class ProductThumbComponent implements OnInit {

  @Input() product;
  previewFiles: any;
  filtered: any;
  previewFile: any;
  constructor(
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.getPreviewFile();
  }

  productView(id) {
    const role = localStorage.getItem('role');
    if (role) {
      this.auth.onProductView(id).subscribe(() => {
        // console.log(res);
      });
    }
  }
  getPreviewFile() {
    this.previewFile = this.product.productFiles.data.find(file => file.status === 'preview');
  }
}
