import { DataService } from '@services/data.service';
import { SendSingleMessageComponent } from './../send-single-message/send-single-message.component';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';


@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit, OnDestroy {
  @Input() feedbacks;
  @Input() pagination;
  @Input() user;
  dialogRef: MatDialogRef<SendSingleMessageComponent>;
  pagesSubscription: any;
  role: string;

  constructor(
    public dialog: MatDialog,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.role = localStorage.getItem('role');
  }

  openDialog(user): void {
    this.dialogRef = this.dialog.open(
      SendSingleMessageComponent,
      {
        data: { user },
        panelClass: ['no-padding', 'no-scrolls'],
      }
    );
  }

  getPages($event) {
    this.pagesSubscription = this.dataService.getData($event).subscribe(res => {
      this.feedbacks = res.data;
      this.pagination = res.meta.pagination;
    });
  }

  deleteFeedback(id) {
    this.dataService.deleteSingleFeedback(id).subscribe(res => {
      this.dataService.showSnackbar('Success', null, 3000);
    }, err => {
      console.error(err);
    });
  }

  ngOnDestroy() {
    if (this.pagesSubscription) {
      this.pagesSubscription.unsubscribe();
    }
  }
}
