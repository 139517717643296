import { GetData } from '@services/get-data.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-footer-modal',
  templateUrl: './footer-modal.component.html',
  styleUrls: ['./footer-modal.component.scss']
})
export class FooterModalComponent implements OnInit {
  content: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FooterModalComponent>,
    private getData: GetData
  ) { }

  ngOnInit() {
    this.getContentForModal();
  }

  getContentForModal() {
    this.getData.getContentById(this.data.id).subscribe(res => {
      this.content = res.data;
    });
  }

  onClose() {
    this.dialogRef.close();
  }
}
