import { AuthService } from '@pages/auth/auth.service';
import { MatDialog } from '@angular/material';
import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { DataService } from '@services/data.service';
import { ReportDeleteChatComponent } from './report-delete-chat/report-delete-chat.component';
import { Overlay } from '@angular/cdk/overlay';
import { WebSocketService } from '@services/websocket.service';

@Component({
  selector: 'app-chat-room',
  templateUrl: './chat-room.component.html',
  styleUrls: ['./chat-room.component.scss']
})
export class ChatRoomComponent implements OnInit {
  message = '';
  chatMessages = [];
  @ViewChildren('commentDiv') commentDivs: QueryList<ElementRef>;
  @ViewChild('textareaEl', { static: false }) textareaEl: ElementRef;
  pagination: any;
  loading = false;
  user;
  lastIndex: number;
  role: string;
  constructor(
    private dataService: DataService,
    private dialog: MatDialog,
    private overlay: Overlay,
    private socketService: WebSocketService,
    private authService: AuthService
  ) {

  }

  ngOnInit() {
    this.getAllChatMessages();
    this.subscribeToChannel();
    this.authService.getAuthenticatedUser().subscribe(res => {
      if (res.data.roles.data[0].name === 'client') {
        this.user = res.data;
      } else {
        this.user = res.data.agent.data;
      }
    });
  }

  subscribeToChannel() {
    this.role = localStorage.getItem('role');
    const user_name = localStorage.getItem('user_name');
    let clientId;
    if (this.role === 'agent') {
      clientId = localStorage.getItem('userId');
    } else {
      clientId = localStorage.getItem('id');
    }
    this.socketService.subscribePublic(
      'chat-channel',
      `\\App\\Containers\\Chat\\Events\\Events\\ChatMessageReceivedEvent`,
      (e: any) => {
        this.chatMessages = this.chatMessages.filter(message => message.id !== null);
        const newMessage = {
          id: e.id,
          message: e.message,
          created_at: {
            date: e.created_at.date
          },
          sender: {
            data: {
              profile_image: e.profile_image,
              user_name: e.user_name
            }
          },
          sent_by_current_user: e.user_name === user_name ? true : false
        };
        this.chatMessages.push(newMessage);
        if (!this.lastIndex) {
          this.lastIndex = this.chatMessages.length - 1;
        }
        setTimeout(() => {
          if (this.commentDivs.last) {
            this.commentDivs.last.nativeElement.focus();
          }
          this.textareaEl.nativeElement.focus();
        }, 1000);
      });

    this.socketService.subscribePublic(
      'chat-channel',
      '\\App\\Containers\\Chat\\Events\\Events\\ChatMessageDeletedEvent',
      (event) => {
        if (event) {
          const deletedMessages = event.chat_ids;
          console.log(deletedMessages)
          console.log(this.chatMessages)
          deletedMessages.forEach(message => {
            this.chatMessages = this.chatMessages.filter(msg => msg.id !== message);
          });
          // this.getAllChatMessages();
        }
      }
    );
  }

  loadMore() {
    if (this.pagination.links.next) {
      this.loading = true;
      this.dataService.getData(this.pagination.links.next).subscribe((res: any) => {
        const messages = res.data;
        const last = messages[messages.length - 1];
        this.chatMessages.unshift(...messages);
        this.loading = false;
        this.pagination = res.meta.pagination;
        setTimeout(() => {
          const elmnt = document.getElementById(last.id);
          if (elmnt) {
            elmnt.scrollIntoView();
          }
        }, 1000);
      });
    } else {
      this.dataService.showSnackbar("No more messages", null, 5000);
    }
  }

  sendChatMessage() {
    const newMessage = {
      id: null,
      message: this.message,
      created_at: {
        date: null
      },
      sender: {
        data: {
          profile_image: this.user.profile_image,
          user_name: this.user.user_name
        }
      },
      sent_by_current_user: true
    };
    this.chatMessages.push(newMessage);
    setTimeout(() => {
      if (this.commentDivs.last) {
        this.commentDivs.last.nativeElement.focus();
      }
    }, 1000);
    this.dataService.sendChatMessage(this.message).subscribe(res => {
      this.message = '';
      this.textareaEl.nativeElement.focus();
    }, error => {
      this.dataService.showSnackbar(error, null, 5000);
      this.getAllChatMessages();
    });
  }

  getAllChatMessages() {
    this.dataService.getAllChats().subscribe((res) => {
      this.chatMessages = res.data.reverse();
      this.pagination = res.meta.pagination;
      if (this.commentDivs) {
        setTimeout(() => {
          if (this.commentDivs.last) {
            this.commentDivs.last.nativeElement.focus();
          }
        }, 1000);
      }
    });
  }

  openModal(message, type) {
    const data = { message, type };
    this.dialog.open(ReportDeleteChatComponent, {
      data,
      panelClass: ['report-chat'],
      scrollStrategy: this.overlay.scrollStrategies.noop()
    });
  }
}
