import { NewAffiliateComponent } from './new-affiliate/new-affiliate.component';
import { ClientGuard } from './shared/guards/client.guard';
import { AgentGuard } from './shared/guards/agent.guard';
import { HelpPageComponent } from './pages/help-page/help-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterConfirmComponent } from './register-confirm/register-confirm.component';


const routes: Routes = [
  { path: 'home', loadChildren: './pages/home/home.module#HomeModule' },
  { path: 'landing', loadChildren: './pages/landing/landing.module#LandingModule' },
  // { path: 'admin', loadChildren: './pages/admin/admin.module#AdminModule' },
  {
    path: 'agent', loadChildren: './pages/agent/agent.module#AgentModule', canActivate: [AgentGuard]
  },
  { path: 'auth', loadChildren: './pages/auth/auth.module#AuthModule' },
  {
    path: 'client', loadChildren: './pages/client/client.module#ClientModule', canActivate: [ClientGuard]
  },
  { path: 'help', component: HelpPageComponent },
  { path: 'register/confirm', component: RegisterConfirmComponent },
  { path: 'site/newaffiliate', component: NewAffiliateComponent },
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  { path: '**', redirectTo: 'landing' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
