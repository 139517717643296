import { GetData } from './../../shared/services/get-data.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-training-videos',
  templateUrl: './training-videos.component.html',
  styleUrls: ['./training-videos.component.scss']
})
export class TrainingVideosComponent implements OnInit {
  trainingVideos = [];

  constructor(
    private getDataService: GetData
  ) { }

  ngOnInit() {
    this.getTrainingVideos();
  }

  getTrainingVideos() {
    this.getDataService.getTrainingVideos().subscribe(res => {
      this.trainingVideos = res.data.filter((item:any) => item.type === 'agent');
    });
  }
}
