import { AuthService } from './../pages/auth/auth.service';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-register-confirm',
  templateUrl: './register-confirm.component.html',
  styleUrls: ['./register-confirm.component.scss']
})
export class RegisterConfirmComponent implements OnInit {
  infoMessage: string;
  @Input() infoMessage2: string;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router) { }

  ngOnInit() {
    this.tokenValidation();
  }

  tokenValidation() {
    const confirmation_token = this.route.snapshot.queryParamMap.get('token');
    const token = { token: confirmation_token };

    this.authService.validateToken(token, { observe: 'response' }).subscribe((response: any) => {
      if (response.status === 200) {
        this.route.queryParams.subscribe((param) => {
          if (param.token) {
            this.infoMessage = 'Registration Successfull! Please wait...';
            setTimeout(() => {
              this.router.navigate(['/auth/login']);
              this.infoMessage2 = 'Your registration was successfull, you can login now.';
            }, 1000);
          }
        });
      }
    }, error => {
      this.authService.showSnackbar(error, null, 3000);
      this.router.navigate(['/home']);
    });
  }
}
