import { element } from 'protractor';
import { map } from 'rxjs/operators';
import { AuthService } from '@pages/auth/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ProductGuard implements CanActivate {
  product;

  constructor( 
    private router: Router,
    private auth: AuthService
  ) { }



  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // debugger
    if (this.auth.listingStatus) {
      return true;
    } else {
      this.auth.showSnackbar('In order to use this feature please add product as listing type in edit profile page', null, 4000);
      this.router.navigate(['agent/edit-profile']);
      return false;
    }
  }
}
