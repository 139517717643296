import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

const CORE_MODULES = [
  BrowserModule,
  BrowserAnimationsModule
];

@NgModule({
  imports: [ ...CORE_MODULES ],
  exports: [ ...CORE_MODULES ]
})
export class CoreModule { }
