import { Location } from '@angular/common';
import { DataService } from '@services/data.service';
import { Component, OnInit, ɵConsole } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GetData } from '@services/get-data.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-credit-card-form',
  templateUrl: './credit-card-form.component.html',
  styleUrls: ['./credit-card-form.component.scss']
})
export class CreditCardFormComponent implements OnInit {
  cards = [
    { value: 'American Express', viewValue: 'American Express' },
    { value: 'Discover', viewValue: 'Discover' },
    { value: 'MasterCard', viewValue: 'MasterCard' },
    { value: 'Visa', viewValue: 'Visa' }
  ];
  months = [
    { value: '1', viewValue: 'January' },
    { value: '2', viewValue: 'February' },
    { value: '3', viewValue: 'March' },
    { value: '4', viewValue: 'April' },
    { value: '5', viewValue: 'May' },
    { value: '6', viewValue: 'June' },
    { value: '7', viewValue: 'July' },
    { value: '8', viewValue: 'August' },
    { value: '9', viewValue: 'September' },
    { value: '10', viewValue: 'October' },
    { value: '11', viewValue: 'November' },
    { value: '12', viewValue: 'December' }
  ];
  range = [];
  loading = false;

  addCreditCardForm: FormGroup;
  countries: any;
  usaCountry: any;
  canadaCountry: any;
  states: any;
  cities: any;
  urlCountry = `${environment.baseUrl}/countries`;
  is_primary: any = true;
  noCities = false;

  constructor(
    private _formBuilder: FormBuilder,
    private getData: GetData,
    private dataservice: DataService,
    private location: Location
  ) { }

  ngOnInit() {
    this.createForm();
    this.getCanadaAndUsa();
    this.yearRange();
  }

  createForm() {
    this.addCreditCardForm = this._formBuilder.group({
      name_on_card: ['', Validators.required],
      card_number: ['', Validators.required],
      card_type: ['', Validators.required],
      exp_month: ['', Validators.required],
      exp_year: ['', Validators.required],
      cvc: ['', Validators.required],
      is_primary: [true, Validators.required],
      address: ['', Validators.required],
      country_code_id: ['', Validators.required],
      state_id: ['', Validators.required],
      city_id: [''],
      city: [''],
      postal_code: ['', Validators.required],
    });
  }

  // get canada and USA to put on top of select
  getCanadaAndUsa() {
    this.getData.getData(this.urlCountry).subscribe(response => {
      const countriesObj = Object.keys(response).map(i => response[i]);
      this.countries = countriesObj[0];
      this.usaCountry = this.countries.find(c => c.name === "United States")
      this.canadaCountry = this.countries.find(c => c.name === "Canada")
    });
  }

  // credit card year expiration, 10 years from current year
  yearRange() {
    const year = new Date().getFullYear();
    this.range.push(year);
    for (let i = 1; i < 10; i++) {
      this.range.push(year + i);
    }
  }

  onChangeCountry(id: any) {
    this.loading = true;
    if (id) {
      this.getData.getCountryStates(id).subscribe(
        (data: any) => {
          this.states = data.data.states.data;
          this.cities = null;
          this.loading = false;
        }
      );
    } else {
      this.loading = false;
      this.states = null;
      this.cities = null;
    }
  }

  onChangeState(stateId) {
    this.loading = true;
    if (stateId) {
      this.getData.getCities(stateId).subscribe(
        (data: any) => {
          this.cities = data.data.cities.data;
          this.loading = false;
          if (!this.cities.length) {
            this.noCities = true;
          } else {
            this.noCities = false;
          }
        }
      );
    } else {
      this.loading = false;
      this.cities = null;
    }
  }



  addCreditCard() {
    const name_on_card = this.addCreditCardForm.controls.name_on_card.value;
    const card_number = this.addCreditCardForm.controls.card_number.value;
    const card_type = this.addCreditCardForm.controls.card_type.value;
    const exp_month = this.addCreditCardForm.controls.exp_month.value;
    const exp_year = this.addCreditCardForm.controls.exp_year.value;
    const cvc = this.addCreditCardForm.controls.cvc.value;
    let is_primary;
    this.is_primary === true ? is_primary = 1 : is_primary = 0;
    const address = this.addCreditCardForm.controls.address.value;
    const city_id = this.addCreditCardForm.controls.city_id.value;
    const postal_code = this.addCreditCardForm.controls.postal_code.value;
    const city = this.addCreditCardForm.controls.city.value;
    const state_id = this.addCreditCardForm.controls.state_id.value;
    this.loading = true;
    this.dataservice.addCreditCard(
      name_on_card,
      card_number,
      card_type,
      exp_month,
      exp_year,
      cvc,
      is_primary,
      address,
      city_id,
      postal_code,
      city,
      state_id
    ).subscribe(() => {
      this.location.back();
      this.dataservice.showSnackbar('Credit Card successfully added', null, 3000);
      this.loading = false;
    }, error => {
      this.dataservice.showSnackbar(error, null, 3000);
      this.loading = false;
      console.error(error);
    });
  }
}
