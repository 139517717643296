import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-view-port',
  templateUrl: './view-port.component.html',
  styleUrls: ['./view-port.component.scss']
})
export class ViewPortComponent implements OnInit {

  @Input() transactions;
  affiliateUser: any;
  clientName: any;
  client: boolean = false;
  userName: string;
  payout: boolean = false;
  bidTransaction = false;
  bidCreator: any;
  constructor() { }

  ngOnInit() {
    const role = localStorage.getItem('role');
    if (role === 'client') {
      this.client = true;
    }

    this.userName = localStorage.getItem('user_name');
    if (this.transactions.description === 'Payout request'
      // || this.transactions.description === 'Bid'
      || this.transactions.description === 'Sms subscription'
    ) {
      this.payout = true;
    }
    if (this.transactions.description === 'Bid') {
        this.bidTransaction = true;
        if (this.transactions.order.data.report.data.client) {
          this.bidCreator = this.transactions.order.data.report.data.client.data.user_name;
        } else {
          this.bidCreator = '--';
        }
    }

    if (this.transactions.order
      && !this.payout
      && this.transactions.order.data.report.data.client
    ) {
      this.clientName = this.transactions.order.data.report.data.client.data.user_name;
    }
    if (!this.transactions.order
      && role !== 'client') {
      this.payout = true;
    }
    if (this.transactions.order
      && this.transactions.order.data.report
      && this.transactions.order.data.report.data.affiliate) {
      this.affiliateUser = this.transactions.order.data.report.data.affiliate.data.client.data.user_name;
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeUnderscore' })
export class RemoveUnderscorePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.replace(/_/g, ' ');
  }
}
