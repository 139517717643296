import { FreePicturesService } from './../services/free-pictures.service';
import { GetData } from './../services/get-data.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectFilterModule } from 'mat-select-filter';
const MODULES = [
  MaterialModule,
  RouterModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MatSelectFilterModule
];

@NgModule({
  declarations: [],
  imports: [ ...MODULES ],
  exports: [ ...MODULES ],
  providers: [ GetData, FreePicturesService ]
})
export class ShareModule {}
