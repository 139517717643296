import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '@services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-tips-popup',
  templateUrl: './tips-popup.component.html',
  styleUrls: ['./tips-popup.component.scss']
})
export class TipsPopupComponent implements OnInit {

  tipForm: FormGroup;
  agent_id: any;
  success = false;
  error: any;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TipsPopupComponent>,
    private dataService: DataService,
    public _formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private router: Router
  ) { }


  ngOnInit() {
    // create tip form
    this.tipForm = this._formBuilder.group({
      amount: ['', Validators.required],
      comment: ['', Validators.required]
    });
    this.agent_id = this.route.snapshot.params.agentId;

  }


  // submiting form, agent id from user details component

  onSubmit() {
    const amount = this.tipForm.value.amount;
    // const comment = this.tipForm.value.comment;
    const agent_id = this.data;
    this.dataService.createTip(amount, agent_id).subscribe(res => {
      // show message to user when success
      this.success = true;
    },
      error => {
        if (error === 'An Exception occurred when trying to authenticate the User.') {
          localStorage.setItem('agToTip', agent_id);
          this.dataService.showSnackbar('Please login to give tip', null, 5000);
          this.router.navigate(['auth/login'], { queryParams: { wantsToTip: true } });
          this.dialogRef.close();
        }
      });
  }

  // close dialog

  onClose() {
    this.dialogRef.close(this.success);
  }
}
