import { ReactiveFormsModule } from '@angular/forms';
import { SelectPipe } from './../../shared/pipes/select.pipe';
import { ShareModule } from './../../shared/modules/share.module';
import { CommonModule } from '@angular/common';
import { LayoutModule } from './../../layout/layout.module';
import { NgModule } from '@angular/core';
import { HomeRoutingModule } from './home-routing.module';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { UsersComponent } from '@pages/home/users/users.component';
import { HomeComponent } from './home.component';
import { UsersDetailComponent } from './users/users-detail/users-detail.component';
import { ObjectFilterPipe } from 'src/app/shared/pipes/filter.pipe';
import { TipsPopupComponent } from './users/users-detail/tips-popup/tips-popup.component';
import { ViewSingleMessageComponent } from '@layout/view-single-message/view-single-message.component';
import { ErrorComponent } from './error/error.component';
import { FreePicsComponent } from './free-pics/free-pics.component'; 


@NgModule({
  declarations: [
    HomeComponent,
    UsersComponent,
    UsersDetailComponent,
    ObjectFilterPipe,
    SelectPipe,
    TipsPopupComponent,
    ErrorComponent,
    FreePicsComponent, 
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    LayoutModule,
    FilterPipeModule,
    ShareModule,
    ReactiveFormsModule
  ],
  exports: [
    UsersDetailComponent,
    TipsPopupComponent
  ],
  entryComponents: [
    TipsPopupComponent,
    ViewSingleMessageComponent
  ]
})
export class HomeModule { }
