import { FooterModalComponent } from './../footer-modal/footer-modal.component';
import { GetData } from './../../shared/services/get-data.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  links: any[] = [];
  dialogRef: MatDialogRef<FooterModalComponent>;
  user_agreement: any;
  agent_agreement: any;
  affiliate_program: any;
  about_us: any;
  help: any;
  banner: any;
  randomBanner: number;


  constructor(
    private getData: GetData,
    public dialog: MatDialog,
    private router: Router
  ) {
    // get route changes to change footer banner
    router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          if (this.banner) {
            this.randomBanner = this.randomFooterBanner(0, this.banner.length - 1);
          }
        }
      });
  }

  ngOnInit() {
    this.getFooterLinksData();
    this.getFooterImage();
  }


  // generate random number for banner change
  randomFooterBanner(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  // get links to show data in footer
  getFooterLinksData() {
    this.getData.getAllContents().subscribe(res => {
      this.user_agreement = res.data.find(el => el.title === 'User Agreement');
      this.agent_agreement = res.data.find(el => el.title === 'Agent Agreement');
      this.affiliate_program = res.data.find(el => el.title === 'Affiliate Intro');
      this.about_us = res.data.find(el => el.title === 'About us');
      this.help = res.data.find(el => el.title === 'Help');
    }, error => {
      if (error === 500) {
        this.router.navigate(['./error']);
      }
      this.getData.showSnackbar(error, null, 3000);
    });
  }

  // get banners
  getFooterImage() {
    this.getData.getFooterBanner().subscribe(res => {
      // banners must have status active
      this.banner = res.data.filter(banner => banner.status === 'active');
      // show random banner
      this.randomBanner = this.randomFooterBanner(0, this.banner.length - 1);
    }, err => {
      this.getData.showSnackbar(err, null, 3000);
      console.error(err);
    });
  }

  openDialog(id): void {

    this.dialogRef = this.dialog.open(
      FooterModalComponent,
      {
        data: { id },
        panelClass: ['footer-panel'],
      }
    );
  }
}
