import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material';
@Injectable({
  providedIn: 'root'
})
export class BlogService {
  url = environment.baseUrl;
  limit: string;
  title: string;
  status: string;
  constructor(
    public http: HttpClient,
    private snackbar: MatSnackBar,
  ) { }

  setLimit(value: string) {
    this.limit = value;
  }
  get Limit() {
    return this.limit;
  }
  setTitle(value: string) {
    this.title = value;
  }
  get Title() {
    return this.title;
  }
  setStatus(value: string) {
    this.status = value;
  }
  get Status() {
    return this.status;
  }

  
  createBlogPost(
    data, edit
  ) {
    const formData = new FormData();
    if (data.title) {
      formData.append('title', data.title);
    }
    if (data.body) {
      formData.append('body', data.body);
    }
    if (data.image1) {
      formData.append('image1', data.image1);
    }
    if (data.image2) {
      formData.append('image2', data.image2);
    }
    if (edit) {
      return this.http.post(`${this.url}/blogs/${data.id}?_method=PUT&include=author`, formData).pipe(catchError(this.handleError));
    } else {
      return this.http.post(`${this.url}/blogs`, formData).pipe(catchError(this.handleError));
    }
  }

  getAllBlogPosts(searchData) {
    const filters = this.getFiltersForAdmin(searchData);
    let url = `${environment.baseUrl}/blogs`;
    if (filters.length) {
      url = `${url}?search=${filters}&include=author${this.limit ? '&limit=' + this.limit : ''}`;
    } else {
      url = `${url}?include=author${this.limit ? '&limit=' + this.limit : ''}`;
    }
    return this.http.get<any>(url).pipe(
      // catchError(this.errorService.handleError),
    );

    // return this.http.get<any>(`${this.url}/blogs?include=author${this.limit ? '&limit=' + this.limit : ''}${this.title ? '&search=title:' + this.title + ';' : ''}${this.status ? 'status:' + this.status : ''}&searchJoin=and`).pipe(
    //   catchError(this.handleError)
    // );
  }


  getFiltersForAdmin(searchData) {
    let filters = '';
    const {name, status} = searchData;
    let count = 0;

    if (name) {
      filters += `title:${searchData.name}`;
      count++;
    }
 
    if (status) {
      if (filters.length) {
        filters += ';';
      }
      filters += `status:${searchData.status}`;
      count++;
    }
    if (count > 1) {
      filters += '&searchJoin=and';
    }

    return filters;
  }

  getSingleBlogPost(slug) {
    return this.http.get<any>(`${this.url}/blogs/${slug}/approved?include=author`).pipe(
      catchError(this.handleError)
    );
  }

  getApprovedBlogPosts() {

    return this.http.get<any>(`${this.url}/blogs/approved?include=author${this.limit ? '&limit=' + this.limit : ''}`).pipe(
      catchError(this.handleError)
    );
  }

  deleteBlogPost(id) {
    return this.http.delete<any>(`${this.url}/blogs/${id}`).pipe(
      catchError(this.handleError)
    );
  }

  handleError(error: HttpErrorResponse) {
    switch (error.status) {
      case 400:
      case 401:
      case 403:
      case 404:
      case 417:
        return throwError(error.error.message);
      case 429:
        return throwError(error.error || error.message);
      case 422:
        if (error.error.errors) {
          if (error.error.errors.title) {
            return throwError(error.error.errors.title[0]);
          }
          if (error.error.errors.body) {
            return throwError(error.error.errors.body[0]);
          }
          if (error.error.errors.image1) {
            return throwError(error.error.errors.image1[0]);
          }
          if (error.error.errors.slug) {
            return throwError(error.error.errors.slug[0]);
          }
          if (error.error.errors.image2) {
            return throwError(error.error.errors.image2[0]);
          }
        } else {
          if (error.error.status_code === 422) {
            return throwError(error.error.message);
          }
        }
        break;
      case 500:
        return throwError('Internal Server error, please try again later');
    }
  }
  showSnackbar(message, action, duration) {

    duration = 1000000;
    let snackRef = this.snackbar.open(message, 'Close',
      {
        duration,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['custom-snackbar']
      });
  }
}
