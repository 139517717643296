import { FreePicsComponent } from './free-pics/free-pics.component';
import { AgentGuard } from './../../shared/guards/agent.guard';
import { TrainingVideosComponent } from './../../layout/training-videos/training-videos.component';
import { ProductDetailsComponent } from './../../layout/product-thumb/product-details/product-details.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UsersComponent } from './../home/users/users.component';
import { HomeComponent } from './home.component';
import { ErrorComponent } from './error/error.component';
import { BlogPostListComponent } from './blog-post-list/blog-post-list.component';

const routes: Routes = [
  { path: 'agents/:agentId', component: UsersComponent},
  { path: 'free-pics', component: FreePicsComponent},
  { path: 'blog-posts', component: BlogPostListComponent},
  { path: 'agents/:agentId/products/:productId', component: ProductDetailsComponent},
  { path: 'blog-posts/single/:slug', component: BlogPostListComponent},
  { path: 'error', component: ErrorComponent},
  { path: 'training-videos', component: TrainingVideosComponent, canActivate: [AgentGuard]},
  { path: '', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
