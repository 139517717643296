import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Overlay } from '@angular/cdk/overlay';
import { ConfirmRejectComponent } from '@layout/confirm-reject/confirm-reject.component';
import { BlogService } from '@services/blog.service';

@Component({
  selector: 'app-blog-post-single',
  templateUrl: './blog-post-single.component.html',
  styleUrls: ['./blog-post-single.component.scss']
})
export class BlogPostSingleComponent implements OnInit, AfterViewInit {
  smallDiv = false;
  toolTipLabel = 'Copy link'
  @ViewChild('cardDiv', { static: false }) cardDiv: ElementRef;
  @Input() blog;
  @Input() singlePost;
  @Output() emitPost = new EventEmitter<any>();
  @Output() emitBackAction = new EventEmitter<any>();
  @Output() emitDeleted = new EventEmitter<any>();
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private overlay: Overlay,
    private blogService: BlogService
  ) { }

  ngOnInit() {
    if (!this.blog.single && this.blog.body.length > 50) {
      this.blog.body.slice(0, 50)
    } else {
      this.smallDiv = true;
    }
  }

  ngAfterViewInit() {
    const contentHeight = this.cardDiv.nativeElement.offsetHeight;
    if (contentHeight > 400 && !this.blog.single) {
      setTimeout(() => {
        this.smallDiv = true;
      }, 0);
    }
  }

  emitPostBlog() {
    window.scroll(0, 0);
    this.blog.single = true;
    this.emitPost.emit(this.blog);
  }

  emitBack(scrollingId) {
    setTimeout(() => {
      const elmnt = document.getElementById(scrollingId);
      if (elmnt) {
        elmnt.scrollIntoView();
      }
    }, 10);
    this.blog.single = false;
    this.emitBackAction.emit(true);
  }

  editBlogPost() {
    this.router.navigate(['../agent/create-new-blog']);
    localStorage.setItem('blogPost', JSON.stringify(this.blog));
  }

  deleteBlogPost() {
    const dialogRef = this.dialog.open(ConfirmRejectComponent, {
      data: { deleteBlogPost: true },
      panelClass: ['confirm-reject'],
      scrollStrategy: this.overlay.scrollStrategies.noop()
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.blogService.deleteBlogPost(this.blog.id).subscribe(res => {

          dialogRef.close();
          this.blogService.showSnackbar('Successfully deleted', null, 5000);
          this.emitDeleted.emit(this.blog.id);

        });
      } else {
        dialogRef.close();
      }
    });
  }

  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }
}
