import { CommonService } from './../../shared/services/common.service';
import { Subscription } from 'rxjs';
import { DataService } from '@services/data.service';
import { AppComponent } from './../../app.component';
import { TwilioService } from '@services/twilio.service';
import { Component, OnInit, Output, EventEmitter, OnChanges, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { AuthService } from './../../pages/auth/auth.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [AppComponent]
})
export class HeaderComponent implements OnInit, OnChanges, OnDestroy {

  isAuthenticated = false;
  isClient = false;
  isAgent = false;
  error: any;
  roleBE: any;
  checked: boolean = false;
  isMobile = false;
  @Input() missedCall: boolean;

  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();
  channel: any;
  unread: number;
  unreadSubscription: Subscription;
  status: string;
  statusSubscription: Subscription;
  winWidth: number;
  pageStatus: boolean;
  constructor(
    private authService: AuthService,
    private twilio: TwilioService,
    private dataService: DataService,
    private commonService: CommonService,
    private metaService: Meta
  ) { 
    this.winWidth = window.innerWidth;

  }


  onClick() {
    this.notify.emit(!this.checked);
    this.checked = !this.checked;
  }
  getMSG() {
    this.dataService.getUnread().subscribe(res => {
      if (res) {
        this.getUnreadMessages();
      }
    });
  }

  getIsMobile(): boolean {
    const w = window.innerWidth;
    const breakpoint = 600;
    if (w > breakpoint) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.getUnreadMessages();
    this.getNewUnreadCount();
    this.getMSG();
    this.getStatus();
    this.authService.user.subscribe(res => {
      if (res) {
        this.roleBE = res.role_name;
        if (this.roleBE) {
          this.isAuthenticated = true;
          switch (this.roleBE) {
            case 'agent': {
              this.isAgent = true;
              break;
            }
            case 'client': {
              this.isClient = true;
              break;
            }
          }
        }
      }
    });

    const role = localStorage.getItem('role');
    if (role) {
      this.isAuthenticated = true;
      switch (role) {
        case 'agent': {
          this.isAgent = true;
          break;
        }
        case 'client': {
          this.isClient = true;
          break;
        }
      }
    }

    this.isMobile = this.getIsMobile();
    window.onresize = () => {
      this.isMobile = this.getIsMobile();
    };

    this.metaService.addTags([
      {name: 'keywords', content: 'Sexy, Girls, Chat, Cam, Video, Phone, Sex'},
      {name: 'description',
      content: 'Live Hot Sexy Girls. If you are searching for genuine site which offers online adult chat platforms for Live Sex Chat and XXX with Live Hot Sexy Girl to enjoy real pleasure sessions, then Anything XXX is the best choice for you.'}
    ]);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getNewUnreadCount();
    if (changes.missedCall.currentValue !== changes.missedCall.previousValue) {
      this.isAgent = false;
      this.isClient = false;
      this.isAuthenticated = false;
      this.missedCall = false;
    }
  }

  getStatus() {
   this.statusSubscription = this.commonService.getMessage2().subscribe(res => {
      this.status = res.text;
    //  console.log(this.status)
    });
  }

  getUnreadMessages() {
    const token = localStorage.getItem('id_token');
    if (token) {
      setTimeout(() => {
        this.authService.countUnreadMessages().subscribe(res => {
          this.unread = res.unread_messages;
        });
      }, 5000);
    }
  }

  getNewUnreadCount() {

    this.unreadSubscription = this.authService.getUnread().subscribe(res => {
      this.unread = res.text.unread_messages;
    });
  }


  logout() { 
    const sid = localStorage.getItem('sid');
    this.twilio.endCall(sid, 'unknown');
    this.authService.logoutReq().subscribe(res => {
    });
    this.authService.logout();
    this.isAgent = false;
    this.isClient = false;
    this.isAuthenticated = false;
  }
  ngOnDestroy(): void {
    if (this.unreadSubscription) {
      this.unreadSubscription.unsubscribe();
    }
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
  }
}
