import { environment } from './../../../environments/environment.prod';
import { Injectable, EventEmitter } from '@angular/core';
import Echo from 'laravel-echo';

(window as any).io = (window as any).io || require('socket.io-client');
(window as any).Echo = (window as any).Echo || {};

@Injectable()
export class WebSocketService {

  url = environment.baseUrl;
  isConnected = false;

  connectionEstablished: EventEmitter<void> = new EventEmitter();
  socket: any;
  socketId: any;


  establishConnection() {
    const token = 'Bearer ' + localStorage.getItem('id_token');
    this.getWindow().Echo = new Echo({
      debug: true,
      broadcaster: 'socket.io',
      // host: `https://api.anythingxxx.quantox.dev:5411`,
      // host: `http://api.anythingxxx.local:6001`,
      host: `https://api.anythingxxx.com:6001`,
      auth:
      {
        headers:
        {
          Authorization: token
        }
      }
    });
    this.socket = this.getWindow().Echo.connector.socket;
    this.socket.on('connect', this.handleConnect.bind(this));
    this.socket.on('connect_error', this.logEvent.bind(this, 'connect_error'));
    this.socket.on('connect_timeout', this.logEvent.bind(this, 'connect_timeout'));
    this.socket.on('error', this.logEvent.bind(this, 'error'));
    this.socket.on('disconnect', this.logEvent.bind(this, 'disconnect'));
    this.socket.on('reconnect', this.logEvent.bind(this, 'reconnect'));
    this.socket.on('reconnect_attempt', this.logEvent.bind(this, 'reconnect_attempt'));
    this.socket.on('reconnecting', this.logEvent.bind(this, 'reconnecting'));
    this.socket.on('reconnect_error', this.logEvent.bind(this, 'reconnect_error'));
    this.socket.on('reconnect_failed', this.logEvent.bind(this, 'reconnect_failed'));
    this.socket.on('ping', this.logEvent.bind(this, 'ping'));
    this.socket.on('pong', this.logEvent.bind(this, 'pong'));
  }

  getWindow(): any {
    return (window as any);
  }

  handleConnect() {
    this.socketId = this.getWindow().Echo.socketId();
    this.getWindow().Echo.connector.options.auth.headers['X-Socket-ID'] = 'Bearer ' + this.socketId;
    this.isConnected = true;
    this.connectionEstablished.emit();
  }

  logEvent(event) {
    //  console.log('websocket-event', event);
    this.isConnected = false;
  }

  broadcast(channel, event, message) {
    this.getWindow().Echo.connector.socket.emit(event, channel, { message });
  }

  subscribe(channel, event, callback) {
    this.getWindow().Echo.private(channel).listen(event, callback);
  }
  disconnect(chn) {
    // console.log(`private-${chn}`)
    this.getWindow().Echo.leave(`private-${chn}`);
  }

  establishPublicConnection() {
    this.getWindow().Echo = new Echo({
      broadcaster: 'socket.io',
      // host: 'https://api.anythingxxx.quantox.dev:5411',
      // host: `http://api.anythingxxx.local:6001`,
      host: `https://api.anythingxxx.com:6001`
    });
  }
  subscribePublic(channel, event, callback) {
    this.getWindow().Echo.channel(channel).listen(event, callback);
  }
}
