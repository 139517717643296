import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-confirm-reject',
  templateUrl: './confirm-reject.component.html',
  styleUrls: ['./confirm-reject.component.scss']
})
export class ConfirmRejectComponent implements OnInit {
  delete = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmRejectComponent>,
  ) { }

  ngOnInit() {
    if (this.data && this.data.deleteBlogPost) {
      this.delete = true;
    }
  }
  confirm(confirmation) {
    this.dialogRef.close(confirmation);
  }
}
