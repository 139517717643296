import { Component, OnInit } from '@angular/core';
import { GetData } from '@services/get-data.service';

@Component({
  selector: 'app-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.scss']
})
export class HelpPageComponent implements OnInit {
  helpContent: any;

  constructor(
    private getData: GetData
  ) { }

  ngOnInit() {
    this.getData.getAllContents().subscribe(res => {
      const help  = res.data.find(el => el.title === 'Help');
      this.getData.getContentById(help.id).subscribe(res => {
        this.helpContent = res.data;
      });
    });
  }
}
