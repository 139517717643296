import { WebSocketService } from '@services/websocket.service';
import { FormGroup } from '@angular/forms';
import { Agent } from './../../shared/models/agent.model';
import { environment } from './../../../environments/environment.prod';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Subject, Observable, throwError } from 'rxjs';
import { User } from './user.model';
import { MatSnackBar } from '@angular/material';
import { map } from 'rxjs/operators';


export interface AuthResponsedata {
  kind: string;
  idToken: string;
  email: string;
  refreshToken: string;
  expiresIn: string;
  localId: string;
  registered?: boolean;
  profile_image?: any;
  formData: FormData;
  affiliate_token?: string;
  data?: any;
}

export interface ProductData {
  name: string;
  description: string;
  price: number;
  gender_availability: string;
  keywords: string;
  primary_file: any;
  preview_files: any;
  data: any;
  id: any;
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  url = environment.baseUrl;
  user = new Subject<User>();
  private tokenExpirationTimer: any;
  accessToken: any;
  agentInfo: any;
  agentRole: any;
  userLoggedIn = new EventEmitter();
  listingStatus = false;
  editProfileStatus = false;
  bankAccountStatus = false;
  pageNumber: number;
  genderField: string;
  lgbt: boolean = false;
  limit: string;
  input: string;
  private unread = new Subject<any>();
  keyword: string;
  user_name: string;
  approvalStatus: string;

  constructor(
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private socket: WebSocketService
  ) { }


  setKeyword(value: string) {
    this.keyword = value;
  }
  get Keyword() {
    return this.keyword;
  }
  setUserName(value: string) {
    this.user_name = value;
  }
  get UserName() {
    return this.user_name;
  }
  setLimit(value: string) {
    this.limit = value;
  }
  get Limit() {
    return this.limit;
  }
  setPageNumber(value: number) {
    this.pageNumber = value;
  }

  get PageNumber() {
    return this.pageNumber;
  }
  setInput(value: string) {
    this.input = value;
  }

  get Input() {
    return this.input;
  }
  setGenderFiled(value: string) {
    this.genderField = value;
  }
  setLgbtFiled(value: boolean) {
    this.lgbt = value;
  }

  get GenderFiled() {
    return this.genderField;
  }

  getUnread(): Observable<any> {
    return this.unread.asObservable();
  }
  sendUnread(message: any) {
    this.unread.next({ text: message });
  }

  // SIGNUP LOGIN AND UPDATE PROFILE

  // signup client
  signup(
    email: string,
    password: string,
    password_confirmation: string,
    first_name: string,
    last_name: string,
    user_name: string,
    birth_date: string,
    gender: string,
    country_code_id: string,
    phone_number: number,
    // recaptcha: string,
    affiliate_token?: string,
  ) {
    return this.http.post<AuthResponsedata>(`${this.url}/register`, {
      email,
      password,
      password_confirmation,
      first_name,
      last_name,
      user_name,
      birth_date,
      gender,
      country_code_id,
      phone_number,
      // recaptcha,
      affiliate_token
    }).pipe(
      catchError(this.handleError)
    );
  }


  // login both agent and client

  login(
    password: string,
    user_name: string,
    email: string
  ) {
    const formData = new FormData();
    formData.append('password', password);
    if (user_name) {
      formData.append('user_name', user_name);
    }
    if (email) {
      formData.append('email', email);
    }
    return this.http.post<any>(
      `${this.url}/clients/web/user/login`, formData).pipe(
        catchError(this.handleError),
        tap(user => {
          if (user.is_client) {
            this.handleAuthentication(
              user.access_token,
              user.role_name,
              user.refresh_token,
              user.token_type,
              +user.expires_in,
            );
            this.userLoggedIn.emit('loggedIN');
            return user;
          }
        }));
  }




  // authentication verification

  private handleAuthentication(
    access_token: string,
    role_name,
    refresh_token: string,
    token_type: string,
    expires_in: number,
  ) {
    const user = new User(access_token, role_name, refresh_token, token_type, expires_in);
    this.user.next(user);
    localStorage.setItem('userData', JSON.stringify(user));
    localStorage.setItem('role', JSON.stringify(user.role_name).substr(1).slice(0, -1));
    localStorage.setItem('id_token', JSON.stringify(user.access_token).substr(1).slice(0, -1));
  }

  // logout users

  logout() {
    const chn = localStorage.getItem('ch');
    this.socket.disconnect(chn);
    this.user.next(null);
    localStorage.clear();

    // clear listing status for product route protection
    this.setListingStatus(false);
    this.setBankAccountStatus(false);
    this.setEditProfileStatus(false);

    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }
  logoutReq() {
    return this.http.delete(`${this.url}/logout`);
  }

  // token validation

  validateToken(token, response) {
    return this.http.patch(`${this.url}/register/confirm`, token, response).pipe(
      catchError(this.handleError),
      tap(resData => {
        // this.handleAuthentication(resData);
      })
    );
  }

  resendConfirmationMail(data) {
    return this.http.post(`${this.url}/email/resend?_method=PATCH`, data).pipe(
      catchError(this.handleError),
    );
  }
  changeMail(data) {
    return this.http.post(`${this.url}/email/reset?_method=PATCH`, data).pipe(
      catchError(this.handleError),
    );
  }
  validateAffiliate(token, response) {
    return this.http.patch<any>(`${this.url}/site/newaffiliate`, token, response).pipe(
      catchError(this.handleError),
      tap(resData => {
        // this.handleAuthentication(resData);
      })
    );
  }

  forgotPassword(
    email: string,
    reseturl: string
  ) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('reseturl', reseturl);
    return this.http.post(`${this.url}/password/forgot`, formData).pipe(catchError(this.handleError));

  }

  resetPassword(
    password: string,
    password_confirmation: string,
    email: string,
    token: string
  ) {
    const formData = new FormData();
    formData.append('password', password);
    formData.append('password_confirmation', password_confirmation);
    formData.append('email', email);
    formData.append('token', token);
    return this.http.post(`${this.url}/password/reset`, formData).pipe(catchError(this.handleError));
  }


  userSmsSubscription(data) {
    return this.http.post<any>(`${this.url}/users/sms-subscription`, data).pipe(catchError(this.handleError));
  }

  editPhoneNumber(
    country_code_id: string,
    phone_number: number,
    is_primary: boolean,
    id?: string,
    method?: string
  ) {
    return this.http.post<AuthResponsedata>(`${this.url}/phone-numbers${id}${method}`, {
      country_code_id,
      phone_number,
      is_primary
    }).pipe(
      catchError(this.handleError)
    );
  }

  findPhoneNumber(id) {
    return this.http.get<any>(`${this.url}/phone-numbers/${id}`).pipe(catchError(this.handleError));
  }

  deletePhoneNumber(id: string) {
    return this.http.delete(`${this.url}/phone-numbers/${id}`).pipe(catchError(this.handleError));
  }

  setAsPrimaryNumber(id: string) {
    return this.http.patch(`${this.url}/phone-numbers/${id}/primary`, {}).pipe(catchError(this.handleError));
  }

  // STRIPE PAYMENT

  // createStripeAccount(
  //   card_id: string,
  //   card_funding: string,
  //   card_last_digits: string,
  //   stripe_token: string,
  //   brand: string,
  //   country: string,
  //   exp_month: number,
  //   exp_year: number,
  //   is_primary: boolean
  // ) {
  //   return this.http.post<any>(`${this.url}/user/payments/accounts/stripe`, {
  //     card_id,
  //     card_funding,
  //     card_last_digits,
  //     stripe_token,
  //     brand,
  //     country,
  //     exp_month,
  //     exp_year,
  //     is_primary 
  //   }).pipe(
  //     catchError(this.handleError));
  // }

  // delete stripe account

  deleteStripeAccount(id) {
    return this.http.delete<any>(`${this.url}/user/payments/accounts/${id}`)
      .pipe(
        catchError(this.handleError));
  }
  deleteCreditcard(id) {
    return this.http.delete<any>(`${this.url}/credit-cards/${id}`)
      .pipe(
        catchError(this.handleError));
  }

  // create transaction


  // STRIPE

  createPaymentTransaction(
    amount: number,
    credit_card_id: string
  ) {
    return this.http.post<any>(`${this.url}/payment-gateways/transactions`, {
      amount, credit_card_id
    }).pipe(
      catchError(this.handleError));
  }


  // createPaymentTransaction(
  //   amount: number,
  //   payment_account_id: string
  // ) {
  //   return this.http.post<any>(`${this.url}/user/payments/transactions`, {
  //     amount, payment_account_id
  //   }).pipe(
  //     catchError(this.handleError));
  // }


  getAllPayoutRequests(id, processed?) {
    return this.http.get<any>(`${this.url}/payout-requests?user_id=${id}${processed}`).pipe(catchError(this.handleError));
  }
  getAllProcessedPayoutRequests(id) {
    return this.http.get<any>(`${this.url}/payout-requests?user_id=${id}&processed=1&include=user`).pipe(catchError(this.handleError));
  }


  // orders

  getAgentOrders(
    id,
    amount?,
    start_date?,
    end_date?
  ): Observable<any> {
    // tslint:disable-next-line: max-line-length
    return this.http.get(`${this.url}/product-purchases/users/${id}?${start_date ? 'start_date=' + start_date : ''}${end_date ? '&end_date=' + end_date : ''}&type=agent&include=product.agent${amount ? '&search=product.price:' + amount : ''}`).pipe(
      catchError(this.handleError)
    );
  }

  getClientOrders(
    id?,
    amount?,
    start_date?,
    end_date?
  ): Observable<any> {
    // tslint:disable-next-line: max-line-length
    return this.http.get(`${this.url}/product-purchases/users/${id}?${start_date ? 'start_date=' + start_date : ''}${end_date ? '&end_date=' + end_date : ''}&type=client&include=product.agent${amount ? '&search=product.price:' + amount : ''}`).pipe(
      catchError(this.handleError)
    );
  }




  //  FEEDBACKS

  // agent feedback

  addFeedback(
    comment: string,
    rating: any,
    agent_id: string,
  ) {
    const formData = new FormData();
    formData.append('comment', comment);
    formData.append('rating', rating);
    formData.append('agent_id', agent_id);
    return this.http.post(`${this.url}/feedbacks/agents?include=author`, formData).pipe(catchError(this.handleError));

  }

  // product feedback

  addProductFeedback(
    comment: string,
    rating: any,
    product_id: string
  ) {
    const formData = new FormData();
    formData.append('comment', comment);
    formData.append('rating', rating);
    formData.append('product_id', product_id);
    return this.http.post(`${this.url}/feedbacks/products?include=author`, formData).pipe(catchError(this.handleError));

  }

  // message

  createMessageByAgent(
    receivers: any[],
    subject: string,
    body: string
  ) {
    return this.http.post(`${this.url}/messages?include=receiver`, { receivers, subject, body }).pipe(catchError(this.handleError));
  }



  // agent profile setup

  profileSetup(
    first_name: string,
    last_name: string,
    gender: string,
    birth_date: string,
    service_title: string,
    service_description: string,
    listing_types: any[],
    tags: any,
    profile_image: Blob,
    banner_image: Blob,
    identity_card: any,
    country_code_id: any,
    phone_number: any,
    state_id: string,
    city_id: any,
    city: string,
    postal_code: any,
    addresses: any,
    audio_call_price?: any,
    video_call_price?: any,
    ssn?: any,
  ) {
    const formData = new FormData();
    formData.append('birth_date', birth_date);
    formData.append('gender', gender);
    formData.append('profile_image', profile_image);
    if (banner_image) {
      formData.append('banner_image', banner_image);
    }
    formData.append('service_title', service_title);
    formData.append('service_description', service_description);
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < listing_types.length; i++) {
      formData.append('listing_types[]', listing_types[i]);
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < tags.length; i++) {
      formData.append('tags[]', tags[i]);
    }
    formData.append('identity_card', identity_card);
    formData.append('first_name', first_name);
    formData.append('last_name', last_name);
    formData.append('country_code_id', country_code_id);
    formData.append('phone_number', phone_number);
    if (city_id) {
      formData.append('city_id', city_id);
    } else {

      formData.append('state_id', state_id);
      formData.append('city', city);
    }
    if (postal_code) {
      formData.append('postal_code', postal_code);
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < addresses.length; i++) {
      if (addresses[i]) {
        formData.append('addresses[]', addresses[i]);
      }
    }
    if (audio_call_price || audio_call_price === 0) {
      formData.append('audio_call_price', audio_call_price);
    }
    if (video_call_price || video_call_price === 0) {
      formData.append('video_call_price', video_call_price);
    }
    if (ssn) {
      formData.append('ssn', ssn);
    }
    return this.http.post<AuthResponsedata>(`${this.url}/agents/profile-setup?include=user.adultProof`, formData).pipe(
      catchError(this.handleError),
      tap(resData => {
        // console.log(resData)
      })
    );
  }


  // Check profile-setup-page

  checkFirstForm(
    gender: string,
    service_title: string,
    service_description: string,
    listing_types: any[],
    tags: any,
    profile_image: Blob,
    banner_image: Blob,
    country_code_id: any,
    phone_number: any,
    audio_call_price?: any,
    video_call_price?: any,
  ) {
    const formData = new FormData();
    formData.append('gender', gender);
    formData.append('profile_image', profile_image);
    if (banner_image) {
      formData.append('banner_image', banner_image);
    }
    formData.append('service_title', service_title);
    formData.append('service_description', service_description);
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < listing_types.length; i++) {
      formData.append('listing_types[]', listing_types[i]);
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < tags.length; i++) {
      formData.append('tags[]', tags[i]);
    }
    formData.append('country_code_id', country_code_id);
    formData.append('phone_number', phone_number);
    if (audio_call_price) {
      formData.append('audio_call_price', audio_call_price);
    }
    if (video_call_price) {
      formData.append('video_call_price', video_call_price);
    }
    return this.http.post<any>(`${this.url}/agents/profile-setup/step-one`, formData).pipe(
      catchError(this.handleError)
    );
  }
  checkSecondForm(
    first_name: string,
    last_name: string,
    birth_date: string,
    identity_card: any,
  ) {
    const formData = new FormData();
    formData.append('birth_date', birth_date);
    formData.append('identity_card', identity_card);
    formData.append('first_name', first_name);
    formData.append('last_name', last_name);
    return this.http.post<any>(`${this.url}/agents/profile-setup/step-two`, formData).pipe(
      catchError(this.handleError)
    );
  }

  // agent profile update / edit

  editAgentProfile(
    user_name: string,
    subscribe_sms: any,
    gender: string,
    profile_image: Blob,
    banner_image: Blob,
    voice_sample: File,
    service_title: string,
    service_description: string,
    listingTypes: any[],
    tags: any,
    audio_call_price?: any,
    video_call_price?: any,
  ) {
    const formData = new FormData();
    if (user_name) {
      formData.append('user_name', user_name);
    }
    formData.append('subscribe_sms', subscribe_sms);
    if (gender) {
      formData.append('gender', gender);
    }
    if (profile_image) {
      formData.append('profile_image', profile_image);
    }
    if (banner_image) {
      formData.append('banner_image', banner_image);
    }
    if (voice_sample) {
      formData.append('voice_sample', voice_sample);
    }
    if (service_title) {
      formData.append('service_title', service_title);
    }
    if (service_description) {
      formData.append('service_description', service_description);
    }
    if (listingTypes) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < listingTypes.length; i++) {
        formData.append('listingTypes[]', listingTypes[i]);
      }
    }
    if (tags) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < tags.length; i++) {
        formData.append('tags[]', tags[i]);
      }
    }

    if (audio_call_price || audio_call_price === 0) {
      formData.append('audio_call_price', audio_call_price);
    }
    if (video_call_price || video_call_price === 0) {
      formData.append('video_call_price', video_call_price);
    }
    // tslint:disable-next-line: max-line-length
    return this.http.post<any>(`${this.url}/temporary-data/agents?include=originalData.listingTypes,newData.temporaryListingTypes,newData.temporaryTags`, formData).pipe(
      catchError(this.handleError)
    );
  }


  // TEMPORARY AGENT INFO APPROVED AUTOMATICALY JUST FOR TESTING PURPOSES

  approveTemporaryData(
    temporary_data_ids: any,
  ) {
    return this.http.put(`${this.url}/temporary-data`, { temporary_data_ids }).pipe(catchError(this.handleError));
  }




  // UPDATE USER

  updateAgent(
    data: any,
    id: string
  ) {
    const formData = new FormData();
    formData.append('first_name', data.first_name);
    formData.append('last_name', data.last_name);
    formData.append('email', data.email);
    formData.append('birth_date', data.birth_date);
    formData.append('address', data.address);
    if (data.address_id) {
      formData.append('address_id', data.address_id);
    }
    if (data.postal_code !== '' && data.postal_code !== null) {
      formData.append('postal_code', data.postal_code);
    } else {
      formData.append('postal_code', ' ');
    }

    if (data.optonal_address !== '' && data.optional_address !== null) {
      formData.append('optional_address', data.optional_address);
    } else {
      formData.append('optional_address', ' ');
    }

    if (data.city) {
      formData.append('city', data.city);
      formData.append('state_id', data.state_id);
    } else {
      formData.append('city_id', data.city_id);
    }
    return this.http.post<any>(`${this.url}/users/${id}?_method=PUT`, formData).pipe(
      catchError(this.handleError));
  }

  // update client
  updateUser(
    id: string,
    email,
    first_name,
    last_name,
    user_name,
    birth_date,
    profile_image,
    gender,
    phone_numbers
  ) {
    const formData = new FormData();
    if (phone_numbers) {
      for (let i = 0; i < phone_numbers.length; i++) {
        if (phone_numbers[i].id !== undefined) {
          formData.append(`phone_numbers[${i}][id]`, phone_numbers[i].id);
        }
        formData.append(`phone_numbers[${i}][phone_number]`, phone_numbers[i].phone_number);
        formData.append(`phone_numbers[${i}][country_code_id]`, phone_numbers[i].country_code_id);
        formData.append(`phone_numbers[${i}][is_primary]`, phone_numbers[i].is_primary);
      }

    }
    if (email) {
      formData.append('email', email);
    }
    if (first_name) {
      formData.append('first_name', first_name);
    }
    if (last_name) {
      formData.append('last_name', last_name);
    }
    if (user_name) {
      formData.append('user_name', user_name);
    }
    if (birth_date) {
      formData.append('birth_date', birth_date);
    }
    if (profile_image) {
      formData.append('profile_image', profile_image);
    }
    if (gender) {
      formData.append('gender', gender);
    }


    return this.http.post<any>(`${this.url}/users/${id}?_method=PUT`, formData).pipe(
      catchError(this.handleError));
  }


  // CHANGE PASSWORD

  userChangePassword(
    password: string,
    password_confirmation: string,
    id: string
  ) {
    return this.http.patch<any>(`${this.url}/password/change/${id}`,
      {
        password,
        password_confirmation
      }).pipe(catchError(this.handleError));
  }

  // agent registration form

  becomeAgent(signupForm: FormGroup) {
    // register agent first form
    return this.http.post<Agent>(`${this.url}/register/agents?include=user`, signupForm).pipe(
      catchError(this.handleError)
    );
  }


  // create alias profile


  createAliasProfile(
    user_name: string,
    gender: string,
    profile_image: Blob,
    banner_image: Blob,
    service_title: string,
    service_description: string,
    listing_types: any[],
    tags: any,
    audio_call_price?: any,
    video_call_price?: any,
  ) {
    const formData = new FormData();
    formData.append('user_name', user_name);
    formData.append('gender', gender);
    formData.append('profile_image', profile_image);
    if (banner_image) {
      formData.append('banner_image', banner_image);
    }
    formData.append('service_title', service_title);
    formData.append('service_description', service_description);
    for (let i = 0; i < listing_types.length; i++) {
      formData.append('listing_types[]', listing_types[i]);
    }
    for (let i = 0; i < tags.length; i++) {
      formData.append('tags[]', tags[i]);
    }

    if (audio_call_price || audio_call_price === 0) {
      formData.append('audio_call_price', audio_call_price);
    }
    if (video_call_price || video_call_price === 0) {
      formData.append('video_call_price', video_call_price);
    }
    return this.http.post<AuthResponsedata>(`${this.url}/agents/alias-profile`, formData).pipe(
      catchError(this.handleError)
    );
  }


  selectAgentProfileById(id) {
    return this.http.get<any>(`${this.url}/agents/${id}/profile`).pipe(
      catchError(this.handleError)
    );
  }

  deleteAgent(id) {
    return this.http.delete<any>(`${this.url}/agents/${id}`).pipe(
      catchError(this.handleError)
    );
  }


  // getting data for users, agents, and clients

  getAuthenticatedUser(): Observable<any> {
    // tslint:disable-next-line: max-line-length
    return this.http.get(`${this.url}/user/profile?include=roles,favorites,agent.listingTypes,agent.tags,agents.listingTypes,tickets,addresses,phoneNumbers.countryCode,productPurchases,agent.temporaryData`).pipe(
      catchError(this.handleError)
    );
  }

  getLoggedInUser(): Observable<any> {
    return this.http.get(`${this.url}/user/profile?include=smsSubscribers,agent.favorites,favorites`).pipe(
      catchError(this.handleError)
    );
  }

  getUser(): Observable<any> {
    return this.http.get(`${this.url}/user/profile?include=orders.agent,agent.orders.agent`).pipe(
      catchError(this.handleError)
    );
  }

  getAgentData(): Observable<any> {
    return this.http.get(`${this.url}/user/profile?include=agent.listingTypes`).pipe(
      catchError(this.handleError)
    );
  }


  // STRIPE

  getUserPaymentAccountDetails(): Observable<any> {
    return this.http.get(`${this.url}/user/profile?include=creditCards.address`).pipe(
      catchError(this.handleError)
    );
  }


  // getUserPaymentAccountDetails(): Observable<any> {
  //   return this.http.get(`${this.url}/user/profile?include=paymentAccounts`).pipe(
  //     catchError(this.handleError)
  //   );
  // }

  getAgentInfo(id?): Observable<any> {
    // tslint:disable-next-line: max-line-length
    return this.http.get(`${this.url}/user/profile?include=agent.favorites,agent.bids,roles,agent.temporaryData,tickets,phoneNumbers,agent.listingTypes,weeklyPayoutApplication,bankAccounts`).pipe(
      catchError(this.handleError), tap(res => {
        this.agentInfo = res;
      })
    );
  }


  setBankAccountStatus(value: boolean) {
    this.bankAccountStatus = value;
  }

  setApprovalStatus(value: string) {
    this.approvalStatus = value;
  }

  get ApprovalStatus() {
    return this.approvalStatus;
  }

  // checking for product as listing status for route protection
  setListingStatus(value: boolean) {
    this.listingStatus = value;
  }
  setEditProfileStatus(value: boolean) {
    this.editProfileStatus = value;
  }

  get agentEditProfileStatus() {
    return this.editProfileStatus;
  }
  getBankAccountStatus(res) {
    const bankAccount = res.data.bankAccounts.data;
    if (bankAccount.length) {
      this.setBankAccountStatus(true);
    }
  }

  get agentListingStatus() {
    return this.listingStatus;
  }

  getProductAsListingType(res) {
    const listingTypes = res.data.agent.data.listingTypes.data;
    const product = listingTypes.find(item => item.name === 'product');
    if (product) {
      this.setListingStatus(true);
    } else {
      this.setListingStatus(false);
    }
  }
  getEditProfileStatus(res) {
    const status = res.data.agent.data.temporaryData.data.length;
    if (!status) {
      this.setEditProfileStatus(true);
    } else {
      this.setEditProfileStatus(false);
    }
  }

  checkAgentListingTypes(id?): any {
    this.http.get<any>(`
    ${this.url}/user/profile?include=agent.favorites,agent.bids,roles,tickets,phoneNumbers,agent.listingTypes,weeklyPayoutApplication`
    ).subscribe((res) => {
      return true;
    });
  }


  getListingInfo(id?): Observable<any> {
    return this.http.get(`${this.url}/user/profile?include=agent.favorites,agent.bids,roles,tickets,phoneNumbers,agent.listingTypes`).pipe(
      catchError(this.handleError), tap(res => {
        this.agentInfo = res;
      })
    );
  }

  getAllAgents() {
    let searchJoin: boolean;
    let join: string;
    if ((this.genderField && this.keyword)
      || (this.input && this.keyword)
      || (this.genderField && this.user_name)
      || (this.input && this.user_name)
      || (this.keyword && this.user_name)
    ) {
      searchJoin = true;
    } else {
      searchJoin = false;
    }

    if (this.user_name && this.genderField) {
      join = ';';
    } else {
      join = '&search=';
    }
    // tslint:disable-next-line: max-line-length
    return this.http.get<any>(`${this.url}/agents/status/approved?include=bids,listingTypes${this.genderField ? '&search=gender:' + this.genderField : ''}${this.user_name ? join + 'user_name:' + this.user_name : ''}${this.input ? '&sortBy=' + this.input : ''}${this.keyword ? '&keyword=' + this.keyword : ''}${this.lgbt ? '&lgbt=1' : ''}${searchJoin ? '&searchJoin=and' : ''}${this.pageNumber ? '&page=' + this.pageNumber : ''}${this.limit ? '&limit=' + this.limit : '&limit=50'}`).pipe(
      catchError(this.handleError), tap(res => {
        this.agentInfo = res;
      })
    );
  }


  getAllAgentsForMsg() {
    // tslint:disable-next-line: max-line-length
    return this.http.get<any>(`${this.url}/agents/status/approved?include=bids,listingTypes&sortBy=featured`).pipe(map(rsp => rsp.items));
  }


  getAllClients() {
    return this.http.get<any>(`${this.url}/clients?status=active&limit=0`).pipe(
      catchError(this.handleError), tap(res => {
        this.agentInfo = res;
      })
    );
  }

  getAllAgentsWithSort(sort) {
    return this.http.get<any>(`${this.url}/agents/status/approved?include=bids,listingTypes&sortBy=${sort}&limit=50`).pipe(
      catchError(this.handleError), tap(res => {
        this.agentInfo = res;
      })
    );
  }

  // when agent signs up verification mail is sent, if not use this call to resend mail

  resendMail(token) {
    return this.http.get(`${this.url}/register/confirm/${token}`).pipe(
      catchError(this.handleError)
    );
  }


  // VARIOUS FEATURES


  // adding new product for agents

  addProduct(
    name: string,
    description: string,
    price: any,
    // gender_availability: string,
    // tags: string,
    primary_file: any,
    preview_files: any
  ) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('price', price);
    // formData.append('gender_availability', gender_availability);
    for (let i = 0; i < primary_file.length; i++) {
      formData.append('primary_files[]', primary_file[i]);
    }
    // // tslint:disable-next-line: prefer-for-of
    // for (let i = 0; i < tags.length; i++) {
    //   formData.append('tags[]', tags[i]);
    // }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < preview_files.length; i++) {
      formData.append('preview_files[]', preview_files[i]);
    }
    return this.http.post<ProductData>(`${this.url}/products`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.handleError)
    );
  }


  // updating product, fields are optional except id of product that is been updated

  updateProduct(
    id,
    name?: string,
    description?: string,
    price?: any,
    // tags?: any,
    primary_file?: any,
    current_files?: any,
    // curent_files_id?: any,
    // new_preview_files?,
    // new_primary_files?
  ) {
    const formData = new FormData();
    formData.append('id', id);
    if (name) {
      formData.append('name', name);
    }
    if (description) {
      formData.append('description', description);
    }
    if (price) {
      formData.append('price', price);
    }
    // if (tags.length && tags[0] !== '') {
    //   // tslint:disable-next-line: prefer-for-of
    //   for (let i = 0; i < tags.length; i++) {
    //     formData.append('tags[]', tags[i]);
    //   }
    // }
    // if (primary_file) {
    //   formData.append('primary_file', primary_file);
    // }
    if (primary_file) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < primary_file.length; i++) {
        if (primary_file[i] && primary_file[i].id && primary_file[i].file) {
          formData.append(`primary_files[${i}][file]`, primary_file[i].file);
          formData.append(`primary_files[${i}][file_id]`, primary_file[i].id);
        } else if (primary_file[i] && primary_file[i].file) {
          formData.append(`primary_files[${i}][file]`, primary_file[i].file);
        }
      }
    }
    if (current_files) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < current_files.length; i++) {
        if (current_files[i] && current_files[i].id && current_files[i].file) {
          formData.append(`preview_files[${i}][file]`, current_files[i].file);
          formData.append(`preview_files[${i}][file_id]`, current_files[i].id);
        } else if (current_files[i] && current_files[i].file) {
          formData.append(`preview_files[${i}][file]`, current_files[i].file);
        }
        // formData.append('preview_files[][file]', current_files[i].file);
        // formData.append('preview_files[][file_id]', current_files[i].id);
      }
    }
    // if (new_preview_files) {
    //   // tslint:disable-next-line: prefer-for-of
    //   for (let i = 0; i < new_preview_files.length; i++) {
    //     formData.append('primary_files[]', new_preview_files[i].file);
    //   }
    // }
    // if (new_primary_files) {
    //   // tslint:disable-next-line: prefer-for-of
    //   for (let i = 0; i < new_primary_files.length; i++) {
    //     formData.append('preview_files[]', new_primary_files[i].file);
    //   }
    // }
    return this.http.post<ProductData>(`${this.url}/products/${id}?_method=PATCH&include=productFiles`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.handleError)
    );
  }

  // deleting files, products, images...

  deleteFileById(id) {
    return this.http.delete(`${this.url}/gallery-images/${id}`).pipe(
      catchError(this.handleError),
      tap(resData => { })
    );
  }

  // setting image from gallery to be profile image

  setImageAsProfilePicture(
    file_id: string
  ) {
    const formData = { file_id };
    return this.http.patch<any>(`${this.url}/agents/images`, formData).pipe(
      catchError(this.handleError)
    );
  }


  // recording view on product if user is authenticated, if it is not registered no record is necessary
  onProductView(
    product_id: string
  ) {
    const product = { product_id };
    return this.http.post<any>(`${this.url}/product-views`, product).pipe(
      catchError(this.handleError)
    );
  }


  // get address by id

  findAddressById(id) {
    return this.http.get<any>(`${this.url}/addresses/${id}?include=city.state.country`).pipe(
      catchError(this.handleError)
    );
  }

  // FAVORITE AGENT ADD AND REMOVE

  addFavouriteAgent(
    agent_id: string
  ) {
    const data = { agent_id };
    return this.http.post<any>(`${this.url}/users/favorites`, data).pipe(
      catchError(this.handleError)
    );
  }

  removeFavoriteAgent(agentId: string) {
    return this.http.delete<any>(`${this.url}/users/favorites/${agentId}`).pipe(
      catchError(this.handleError),
    );
  }


  countUnreadMessages() {
    return this.http.get<any>(`${this.url}/messages/users/count`).pipe(
      catchError(this.handleError),
      tap(res => {
        this.sendUnread(res);
      })
    );
  }


  // ERROR HANDLING

  handleError(error: HttpErrorResponse) {

    switch (error.error.status_code) {
      case 401:
      case 403:
      case 404:
      case 409:
      case 417:
        return throwError(error.error.message || 'Server error');
      case 422:
        if (error.error.errors.email) {
          return throwError(error.error.errors.email[0]);
        } else if (error.error.errors.password) {
          return throwError(error.error.errors.password[0]);
        } else if (error.error.errors.user_name) {
          return throwError(error.error.errors.user_name[0]);
        } else if (error.error.errors.first_name) {
          return throwError(error.error.errors.first_name[0]);
        } else if (error.error.errors.primary_files) {
          return throwError(error.error.errors.primary_files[0]);
        } else if (error.error.errors.preview_files) {
          return throwError(error.error.errors.preview_files[0]);
        } else if (error.error.errors.listingTypes) {
          return throwError(error.error.errors.listingTypes[0]);
        } else if (error.error.errors.last_name) {
          return throwError(error.error.errors.last_name[0]);
        } else if (error.error.errors.description) {
          return throwError(error.error.errors.description[0]);
        } else if (error.error.errors.receivers) {
          return throwError(error.error.errors.receivers[0]);
        } else if (error.error.errors['preview_files.0']) {
          return throwError('Files must be of type: audio/mpeg, image/png, image/jpeg, image/gif, video/mp4.');
        } else if (error.error.errors['primary_files.0']) {
          return throwError('Files must be of type: audio/mpeg, image/png, image/jpeg, image/gif, video/mp4.');
        } else if (error.error.errors.country_code_id) {
          return throwError(error.error.errors.country_code_id[0]);
        } else if (error.error.errors.city) {
          return throwError(error.error.errors.city[0]);
        } else if (error.error.errors.body) {
          return throwError('Message field is required');
        } else if (error.error.errors.subject) {
          return throwError(error.error.errors.subject[0]);
        } else if (error.error.errors.recaptcha) {
          return throwError(error.error.errors.recaptcha[0]);
        } else if (error.error.errors.service_title) {
          return throwError('Title field is required');
        } else if (error.error.errors.service_description) {
          return throwError('Bio field is required');
        } else if (error.error.errors.tags) {
          return throwError(error.error.errors.tags[0]);
        } else if (error.error.errors.city_id) {
          return throwError(error.error.errors.city_id[0]);
        } else if (error.error.errors.postal_code) {
          return throwError(error.error.errors.postal_code[0]);
        } else if (error.error.errors.primary_file) {
          return throwError(error.error.errors.primary_file[0]);
        } else if (error.error.errors.gender) {
          return throwError(error.error.errors.gender[0]);
        } else if (error.error.errors.listing_types) {
          return throwError(error.error.errors.listing_types[0]);
        } else if (error.error.errors.phone_numbers) {
          return throwError(error.error.errors.phone_numbers[0]);
        } else if (error.error.errors.phone_number) {
          return throwError(error.error.errors.phone_number[0]);
        } else if (error.error.errors.id) {
          return throwError(error.error.errors.id[0]);
        } else if (error.error.errors.profile_image) {
          return throwError(error.error.errors.profile_image[0]);
        } else if (error.error.errors.audio_call_price) {
          return throwError(error.error.errors.audio_call_price[0]);
        } else if (error.error.errors.video_call_price) {
          return throwError(error.error.errors.video_call_price[0]);
        } else if (error.error.errors.banner_image) {
          return throwError(error.error.errors.banner_image[0]);
        } else if (error.error.errors.identity_card) {
          return throwError('Government ID must be attached as a png or jpeg file');
        } else if (error.error.errors.birth_date) {
          return throwError(error.error.errors.birth_date[0]);
        } else if (error.error.errors.rating) {
          return throwError(error.error.errors.rating[0]);
        } else if (error.error.errors.address) {
          return throwError(error.error.errors.address[0]);
        } else if (error.error.errors['tags.0']) {
          return throwError('Specialities field is required');
        } else if (error.error.errors.comment) {
          return throwError(error.error.errors.comment[0]);
        } else if (error.error.errors['addresses.0.address']) {
          return throwError('One address is required');
        } else if (error.error.errors.amount) {
          return throwError(error.error.errors.amount[0]);
        } else if (error.error.errors.gender_availability) {
          return throwError('Gender field is required!');
        } else if (error.error.errors.name) {
          return throwError(error.error.errors.name[0]);
        } else if (error.error.errors.keywords) {
          return throwError(error.error.errors.keywords[0]);
        } else if (error.error.errors.price) {
          return throwError(error.error.errors.price[0]);
        } else if (error.error.message) {
          return throwError(error.error.message);
        } else if (error.error.errors.primary_file) {
          return throwError('Product field is required!');
        } else if (error.error && !error.error.errors) {
          return throwError(error.error.message);
        }
        return throwError(error.error || error.error.message);
      case 429:
        return throwError(error.error || error.message);
      case 500:
        return throwError('Internal server error, please try again later');
      case 422:
        if (error.error.message) {
          return throwError(error.error.message);
        }
    }
    switch (error.status) {
      case 400:
      case 401:
      case 403:
      case 404:
      case 422:
        return throwError(error.error.message);
    }
  }


  // show message to user when error occures
  showSnackbar(message, action, duration) {
    const actionButtonLabel = 'Close';
    action = true;
    duration = 1000000;
    this.snackbar.open(message, action ? actionButtonLabel : undefined,
      {
        duration,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['custom-snackbar']
      });
  }
}
