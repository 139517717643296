import { DataService } from '@services/data.service';
import { GetData } from '@services/get-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './../../../auth/auth.service';
import { Component, OnInit, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TipsPopupComponent } from './tips-popup/tips-popup.component';
import { MatDialog } from '@angular/material';
import { ImageModalComponent } from '@layout/image-modal/image-modal.component';
import { SendSingleMessageComponent } from '@layout/send-single-message/send-single-message.component';
import { Meta } from '@angular/platform-browser';
import { UserCardComponent } from '@pages/home/user-card/user-card.component';
import { TwilioService } from '@services/twilio.service';
import { Subscription } from 'rxjs';
import { CommonService } from '@services/common.service';
import { Overlay } from '@angular/cdk/overlay';

@Component({
  selector: 'app-users-detail',
  templateUrl: './users-detail.component.html',
  styleUrls: ['./users-detail.component.scss'],
  providers: [UserCardComponent]
})

export class UsersDetailComponent implements OnInit, AfterViewInit {
  @Input() agent;
  @Input() tags;
  @Input() agentInfo;
  @Input() images;
  // @Input() feedbacks;


  FormGroup: FormGroup;
  id: string;
  user: any;
  message = 'Feedback successfully added.';
  sort: any;
  toogle: boolean;
  previous: any;
  pagination: any;
  next: any;
  authUser;
  feedbacks: any[] = [];
  pagesSubscription: any;
  orders: any;
  canAddFeedback = false;
  approvedProducts: any;
  products: any;
  imageObject;
  cam: any;
  phone: any;
  product;
  subscribed = false;
  isFavorite = false;
  isAuth: boolean;
  favorite: any;
  ownProfile: boolean;
  subscription: Subscription;
  subscription2: Subscription;
  dialogRef: any;
  constructor(
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private data: GetData,
    private dataService: DataService,
    public dialog: MatDialog,
    private metaService: Meta,
    private userCard: UserCardComponent,
    private twilio: TwilioService,
    private commonService: CommonService,
    private router: Router,
    private overlay: Overlay
  ) { }

  ngOnInit() {

    this.adjustImages();
    const role = localStorage.getItem('role');
    if (role) { this.isAuth = true; }
    // get authenticated user
    if (role) {
      this.authService.getUser().subscribe(res => {
        this.user = res.data.id;
        if (role === 'client') {
          this.orders = res.data.orders.data;
        } else if (role === 'agent') {
          this.orders = res.data.agent.data.orders.data;
        }
        this.checkIfUserCanAddFeedback();
      });
    }


    // agent detail id
    this.id = this.route.snapshot.params.agentId;

    // get user id for bid charge
    this.getUserId(role);
    // fetching feedbacks from BE
    this.getFeedbacks();
    this.getAgent();

    // listen for availability status change for each agent card
    this.subscription = this.twilio.currentMessage.subscribe(message => {
      if (message) {
        const busyAgent = message.agentId;
        const status = message.status;
        if (this.agent && busyAgent === this.agent.id) {
          this.agent.availability_status = status;
          this.iconAvailabilityCheck(this.agent);
        }
      }
    });
    this.subscription2 = this.commonService.getMessage().subscribe(res => {
      const agent = this.agent;
      if (agent) {
        this.iconAvailabilityCheck(agent, res.text);
      }
    });
    // create feedback form
    this.FormGroup = this._formBuilder.group({
      comment: ['', Validators.required],
      rating: ['', Validators.required],
      // agent_id: [this.id]
    });

    // send product list to product cards
    this.products = this.agent.products.data.filter(product => product.status === 'approved');
    if (this.agent) {

      this.addMetaData();
    }
    this.getUserInfo(role);


    this.route.queryParams.subscribe(param => {
      // const agentToCallRemote = param.id;
      const actionType = param.action;
      if (actionType === 'tip') {
        this.openDialog()
      } else if (actionType === 'click2mail') {
        this.openMailDialog(this.agent);
      }
    });
  }
  iconAvailabilityCheck(agent, event?) {
    let cam;
    event ? cam = event.listingTypes.filter(t => t === 'cam') : cam = agent.listingTypes.data.filter(t => t.name === 'cam');
    if (cam.length > 0 && (agent.availability_status === 'cam' || agent.availability_status === 'online')) {
      agent.cam = true;
    } else {
      agent.cam = false;
    }
    let phone;
    event ? phone = event.listingTypes.filter(t => t === 'phone') : phone = agent.listingTypes.data.filter(t => t.name === 'phone');
    if (phone.length > 0 && (agent.availability_status === 'phone' || agent.availability_status === 'online')) {
      agent.phone = true;
    } else {
      agent.phone = false;
    }
    if (agent.availability_status === 'busy' || agent.availability_status === 'offline') {
      agent.cam = false;
      agent.phone = false;
    }
  }
  // get agent by id thru query param

  getAgent() {
    this.route.paramMap.subscribe(params => {
      this.data.getAgent(params.get('agentId')).subscribe(res => {
        this.agent = res.data;
        this.getProductListing(this.agent);
        this.listingTypesAvailability();
        this.videoIconAvailable();
        this.audioIconAvailable();
      });
    });
  }
  getUserInfo(role) {
    this.authUser = localStorage.getItem('id_token');
    const currentAgent = this.route.snapshot.paramMap.get('agentId');
    if (this.authUser) {
      this.authService.getLoggedInUser().subscribe(res => {
        const userSmsSubscribtions = res.data.smsSubscribers.data;
        const match = userSmsSubscribtions.find(item => item.id === currentAgent);
        if (match) {
          this.subscribed = true;
        }
        if (role === 'agent') {
          this.getFavoriteAgent(res.data.agent.data.favorites.data, currentAgent);
        } else if (role === 'client') {
          this.getFavoriteAgent(res.data.favorites.data, currentAgent);
        }
        this.findIfOwnProfile(res, currentAgent);
      });
    }
  }
  getFavoriteAgent(favorites, currentAgentId) {
    favorites.find(agent => {
      if (agent.id === currentAgentId) {
        this.favorite = agent;
        this.isFavorite = true;
      }
    });
  }
  findIfOwnProfile(res, user) {
    if (res.data.agent) {
      if (res.data.agent.data.id === user) {
        this.ownProfile = true;
      } else {
        this.ownProfile = false;
      }
    }
  }
  favouriteAgent(id, event) {
    event.stopPropagation();
    event.preventDefault();
    if (!this.isFavorite) {
      this.authService.addFavouriteAgent(id).subscribe(res => {
        if (res) {
          this.isFavorite = true;
        }
      }, error => {
        this.authService.showSnackbar(error, null, 3000);
      });
    } else {
      this.authService.removeFavoriteAgent(id).subscribe(res => {
        this.isFavorite = false;
      }, error => {
        this.authService.showSnackbar(error, null, 3000);
      });
    }
  }
  // subscribe to receive sms notification when agent is online
  subscribeSMS(id) {
    const data = { agent_id: id };
    this.authService.userSmsSubscription(data).subscribe(res => {
      this.authService.showSnackbar(res.message, null, 3000);
      this.subscribed = !this.subscribed;
    }, err => this.authService.showSnackbar(err, null, 3000));
  }

  // find if agent has products in listings
  getProductListing(agent) {
    const data = agent.listingTypes.data;
    data.map(item => {
      this.product = item.name === 'product';
    });
  }


  // forced solution due to late change in design
  callAgent(id, user_name, call_price, event: Event) {
    this.userCard.callAgent(id, user_name, call_price, event);
  }

  callAgentAudio(id, user_name, call_price, event: Event) {
    this.userCard.callAgentAudio(id, user_name, call_price, event);
  }

  // handle status cases to show to users
  availabilityStatus() {
    let status = this.agent.availability_status;
    switch (status) {
      case 'cam':
        status = 'Online';
        break;
      case 'phone':
        status = 'Online';
        break;
      case 'busy':
        status = 'Busy';
        break;
      case 'offline':
        status = 'Offline';
        break;
      case 'online':
        status = 'Online';
        break;
    }
    return status;
  }
  listingTypesAvailability() {
    const listingTypes = this.agent.listingTypes.data;
    this.cam = listingTypes.find(type => type.name === 'cam');
    this.phone = listingTypes.find(type => type.name === 'phone');
    this.product = listingTypes.find(type => type.name === 'product');
  }
  // icons for calls availability check
  videoIconAvailable() {
    const cam = this.agent.listingTypes.data.filter(t => t.name === 'cam');
    if (cam.length > 0 && (this.agent.availability_status === 'cam' || this.agent.availability_status === 'online')) {
      this.agent.cam = true;
    } else {
      this.agent.cam = false;
    }
  }
  audioIconAvailable() {
    const phone = this.agent.listingTypes.data.filter(t => t.name === 'phone');
    if (phone.length > 0 && (this.agent.availability_status === 'phone' || this.agent.availability_status === 'online')) {
      this.agent.phone = true;
    } else {
      this.agent.phone = false;
    }
  }


  goToProducts(el: HTMLElement) {
    el.scrollIntoView();

  }
  addMetaData() {
    this.metaService.addTags([
      { name: 'keywords', content: 'Sexy, Girls, Chat, Cam, Video, Phone, Sex' },
      { name: 'content', content: this.agent.description }
    ]);
  }
  adjustImages() {
    this.imageObject = this.images.map((e) => {
      return {
        image: e.path,
        thumbImage: e.path,
        title: e.name
      };
    });
  }


  getUserId(role) {
    if (role === 'client') {
      this.authUser = localStorage.getItem('id');
    } else if (role === 'agent') {
      this.authUser = localStorage.getItem('userId');
    }
    this.checkAgentBidStatus();
  }


  checkIfUserCanAddFeedback() {
    // console.log(this.orders)
    const interaction = this.orders.find(order => order.agent && order.agent.data.id === this.id);
    if (interaction) {
      this.canAddFeedback = true;
    }
  }


  checkAgentBidStatus() {
    const bids = this.agentInfo.data.bids.data;
    const active = bids.find(bid => bid.status === 'active');
    if (active) {
      this.createBidCharge();
    }
  }

  createBidCharge() {
    this.dataService.createBidCharge(this.id, this.authUser).subscribe(res => {
      //   console.log('bid created', res);
    }, error => {
      console.error(error);
    });
  }


  ngAfterViewInit(): void {
    // this.cdr.detectChanges();
    // setTimeout(() => {
    //   this.checkAgentBidStatus();
    // }, 1000);
  }

  // give tips dialog open
  openDialog() {
    const dialogRef = this.dialog.open(TipsPopupComponent, { 
      data: this.id, 
      panelClass: ['no-padding', 'no-scrolls'], 
      scrollStrategy: this.overlay.scrollStrategies.noop()
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.canAddFeedback = true;
      }
    });
  }

  openMailDialog(user): void {
    this.authService.getAuthenticatedUser().subscribe(res => {
      this.dialogRef = this.dialog.open(
        SendSingleMessageComponent,
        {
          data: { user },
          panelClass: ['no-padding', 'no-scrolls'],
          scrollStrategy: this.overlay.scrollStrategies.noop()
        }
      );

      // localStorage.removeItem('agToCall');
    }, () => {
      localStorage.setItem('agToCall', user.id)
      this.router.navigate(['auth/login'], { queryParams: { wantsToSendMessage: true } });
      this.twilio.showSnackbar('Please login or signup to send message', null, 3000);
    });
  }

  viewImage(src) {
    this.dialog.open(ImageModalComponent, { data: src, panelClass: 'image-modal' });
  }

  // get feedbacks function
  getFeedbacks(limit?) {
    const id = this.route.snapshot.params.agentId;
    const feedbackable_type = 'agent';
    const include = '&include=author';
    this.data.getAllFeedbacksByFeedbackableModel(id, feedbackable_type, include, limit).subscribe(res => {
      const feedbacks = res.data;
      feedbacks.forEach(element => {
        element.agentView = true;
      });
      this.feedbacks = feedbacks;
      this.pagination = res.meta.pagination;
    });
  }

  // sorting feedbacks
  onToogle() {
    this.toogle = !this.toogle;
    this.sort = this.toogle ? 'asc' : 'desc';
    this.getFeedbacks();
  }

  // getting pages from pagination links
  getPages($event) {
    this.pagesSubscription = this.dataService.getData($event).subscribe(res => {
      this.feedbacks = res.data;
      this.pagination = res.meta.pagination;
    });
  }

  
  loadWithLimit(limit) {
    this.getFeedbacks(limit);
    window.scroll(0, 0);
  }


  // pagination next
  onNext() {
    this.data.getFeedbacksFromUrl(this.next).subscribe(res => {
      this.feedbacks = res.data;
      this.previous = res.meta.pagination.links.previous;
      this.pagination = res.meta.pagination;
    });
  }

  // pagination previous
  onPrevious() {
    this.dataService.getProductViewsFromUrl(this.previous).subscribe(res => {
      this.feedbacks = res.data;
      this.pagination = res.meta.pagination;
    });
  }


  onSubmit() {
    const comment = this.FormGroup.value.comment;
    const rating = this.FormGroup.value.rating;
    const agent_id = this.id;

    this.authService.addFeedback(
      comment,
      rating,
      agent_id,
    ).subscribe(
      resData => {
        this.FormGroup.reset();
        this.authService.showSnackbar(this.message, null, 3000);
        this.getFeedbacks();
      }, error => {
        this.authService.showSnackbar(error, null, 3000);
      }
    );
  }
  ngOnDestroy(): void {
    if (this.pagesSubscription) {
      this.pagesSubscription.unsubscribe();
    }
  }
}
