import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService { 
  private subject = new Subject<any>();
  private subject2 = new Subject<any>();
  private page = new Subject<boolean>();

  constructor(
    private date: DatePipe
  ) { }


  sendMessage(message: any) {
    this.subject.next({ text: message });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
 
  sendMessage2(message: any) {
    this.subject2.next({ text: message });
  }

  getMessage2(): Observable<any> {
    return this.subject2.asObservable();
  }

  getDirtyValues(cg: FormGroup) {
    const dirtyValues = {} as any;
    Object.keys(cg.controls).forEach(c => {
      const currentControl = cg.get(c);

      if (currentControl.dirty) {
        dirtyValues[c] = currentControl.value;
      }
    });
    if (dirtyValues.birth_date) {
      const date = cg.value.birth_date;
      dirtyValues.birth_date = this.date.transform(date, 'y-MM-dd')
    }
    return dirtyValues;
  }

  ssnCorrector(ssn, elementId) {
    const patt = new RegExp("\d{3}[\-]\d{2}[\-]\d{4}");
    ssn = document.getElementById(elementId);
    const res = patt.test(ssn.value);
    if (!res) {
      ssn.value = ssn.value
        .match(/\d*/g).join('')
        .match(/(\d{0,3})(\d{0,2})(\d{0,4})/).slice(1).join('-')
        .replace(/-*$/g, '');
    }
  }

  getIsMobile(): boolean {
    const w = window.innerWidth;
    const breakpoint = 600;
    if (w > breakpoint) {
      return true;
    } else {
      return false;
    }
  }

  setPageStatus(status: boolean) {
    this.page.next(status);
  }

  getPageStatus(): Observable<boolean> {
    return this.page.asObservable();
  }
}
