import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';


@Injectable()
export class ClientGuard implements CanActivate {
  constructor(private router: Router) {

  }


  canActivate(): any {


    const role = localStorage.getItem('role');
    if (role === 'client') {
      return true;
    } else {
      this.router.navigate(['auth/login'])
      return false;
    }
  }
}
