import { AuthService } from '@pages/auth/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';


@Injectable()
export class AgentGuard implements CanActivate {
  constructor(private authService: AuthService,
    private router: Router) {

  }


  canActivate(): any {


    const role = localStorage.getItem('role');
    if (role === 'agent') {
      return true;
    } else {
      this.router.navigate(['auth/login'])
      return false;
    }
  }
}
